import { Four04 } from '@moved/product';

import { VendorSearch } from './components/VendorSearch';
import { VendorDetails } from './components/VendorDetails';

const routes = [
  {
    path: '/vendors',
    name: 'VendorWrapper',
    children: [
      {
        path: '/',
        name: 'VendorSearch',
        component: VendorSearch,
        children: [
          {
            path: '/:vendorId(\\d+)',
            name: 'VendorDetails',
            component: VendorDetails,
            exact: true,
          },
        ]
      },
      {
        path: '*',
        name: 'Page not found',
        component: Four04,
      },
    ]
  },
  {
    path: '*',
    name: 'Page not found',
    component: Four04,
  },
];

export default routes;
