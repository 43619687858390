import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { get, noop } from 'lodash';

import { useNotify, s3, format, useUser } from '@moved/services';

import { createInsurancePolicy, getS3UploadUrl } from '../actions';
import { useInsuranceSettings } from '../actions/selectors';

import { PolicyDetails } from './PolicyDetails';
import { PolicyUpload } from './PolicyUpload';
import { PolicyCoverage } from './PolicyCoverage';

export const PolicyForm = ({ task, tenantEvent, submitting, setSubmitting=noop, onSuccess=noop }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { user } = useUser();

  const [formData, setFormData] = useState({
    covered_tenants: [{
      tenant_id: tenantEvent.tenant.id,
      is_primary_policyholder: true,
    }],
  });
  // convenience function to extend the data instead of replace it
  const updateFormData = (data) => setFormData(previous => ({...previous,...data}));

  // file upload management
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState();

  // configuration data
  const settings = useInsuranceSettings(tenantEvent.id,task.building_task_id) || {};
  const tenants = get(settings,'tenants',[]);

  // submission handler
  const submit = async () => {
    // validate the file upload
    if(!files || !files.length) return setFileError('required')
    if(submitting) return;
    setSubmitting(true);

    // upload the image to s3 and get the url
    const file_urls = await Promise.all(files.map(file => {
      return dispatch(getS3UploadUrl(get(tenantEvent,'building.id'),{
        filename: s3.getUniqueFilename(file.name, { id: get(tenantEvent, 'user_id') || user.id }),
        http_content_type: file.type,
      }))
        .then(({ signed_request, url }) => s3.putFile(file,signed_request).then(() => url))
        .catch(error => { notify.error(format.error(error)) });
    }));

    if(!file_urls || !file_urls.length) return;

    // add file_urls and remove policy_term from submission data
    const { policy_term, ...submissionData } = { ...formData, file_urls };

    dispatch(createInsurancePolicy(tenantEvent.id, task.building_task_id, submissionData))
      .then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        notify.error(format.error(err));
      });

  };

  return (
    <>
      <PolicyDetails
        data={formData}
        updateData={updateFormData}
        onSubmit={submit}
        settings={settings}
      />

      <PolicyUpload
        setFiles={setFiles}
        fileError={fileError}
        setFileError={setFileError}
      />

      { tenants.length > 1 && (
        <PolicyCoverage
          tenants={tenants}
          tenantEvent={tenantEvent}
          data={formData}
          updateData={updateFormData}
        />
      )}

    </>
  );
};
