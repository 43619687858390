import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useHover } from 'react-use';

import { useUser, useNotify } from '@moved/services';
import { NavigationMenu } from '@moved/product';

import { ProfileLink } from '../../../common/components/ProfileLink';
import { ReactComponent as MovedIcon } from '../images/mvd-icon.svg';

import CSS from './styles/CollapsibleSidebar.module.scss';

export const CollapsibleSidebar = ({ className, ...props }) => {
  const sidebar = (isHovering) => (
    <div className={classNames(CSS.sidebar, className, {[CSS.expanded]:isHovering})}>
      <Sidebar {...props} isHovering={isHovering} />
    </div>
  );
  const [sidebarHover] = useHover(sidebar);
  return sidebarHover;
};

const Sidebar = ({ isHovering }) => {
  const { isAuthenticated, hasDomain, logout } = useUser();
  const history = useHistory();
  const notify = useNotify();

  const signOut = () => {
    logout().then(() => {
      history.push('/signin');
      notify.default('Someone successfully logged out, but we forgot who it was.');
    }); // resets the redux store to initial state
  };

  const navItems = [
    {
      items: [
        {
          urlPath: `/cx/moves`,
          label: 'Moves',
          icon: {
            symbol: 'Star',
            library: 'general',
          },
          ability: 'ReadMoves',
        },
        {
          urlPath: `/cx/vendors`,
          label: 'Vendors',
          icon: {
            symbol: 'Truck',
            library: 'home',
          },
          ability: 'ReadVendors',
        },
        hasDomain('abode') && {
          urlPath: '/admin/properties',
          label: 'Admin Abode',
          icon: {
            symbol: 'Building',
            library: 'home',
          },
        },
      ].filter(v=>v),
    }
  ];
  return (
    <>
      <div className={CSS.logo_section}>
        <Link to={`/cx/moves`}>
          <MovedIcon className={CSS.mvd_icon} />
        </Link>
      </div>
      { isAuthenticated && (
        <NavigationMenu sections={navItems} className={CSS.menu} isCollapsed={!isHovering} />
      )}
      { isAuthenticated && (
        <div className={CSS.bottom_section}>
          <ProfileLink signOut={signOut}/>
        </div>
      )}
    </>
  );
};
