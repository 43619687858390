import React from 'react';

import { useModal, useUser } from '@moved/services';

import { Action } from './Action';
import { UpdateRateModal } from '../modals/UpdateRateModal';
import { isOrderRateEditable } from '../../helpers';

export const UpdateRate = ({ order, ...props }) => {
  const modal = useModal();
  const { hasAbilities } = useUser();
  // early exit
  if(!isOrderRateEditable(order) || !hasAbilities('UpdateServiceOrders')) return null;
  // create the action button
  const openModal = () => modal.openLegacy(<UpdateRateModal order={order} />);
  const icon = {
    library: 'shopping',
    symbol: 'Price#1',
  }
  return <Action onClick={openModal} icon={icon} {...props}>Update Rate</Action>;
};
