import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { createExternalUser } from '../../actions';
import { useCreateExternalUserPending } from '../../actions/selectors';

import CSS from '../../styles/CreateExternalUserModal.module.scss';

export const CreateExternalUserModal = () => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = useCreateExternalUserPending();

  const fields = [
    {
      label: 'First name',
      type: 'text',
      name: 'firstname',
      value: '',
      half: true,
      required: true,
    },
    {
      label: 'Last name',
      type: 'text',
      name: 'lastname',
      value: '',
      half: true,
      required: true,
    },
    {
      label: 'Email address',
      type: 'email',
      name: 'email',
      value: '',
      required: true,
    },
    {
      label: 'Source',
      type: 'select',
      name: 'source',
      value: 'email',
      options: [
        { label: 'Email', value: 'email' },
        { label: 'Other', value: 'other' },
      ],
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    if(pending) return;
    return dispatch(createExternalUser(data))
      .then((response) => {
        notify.default(`New user created!`);
        modal.close(response);
      })
      .catch(error => {
        const msg = get(error,'response.status') === 409 ? 'This email is already in use by another user.' : format.error(error);
        notify.error(msg)
      });
  };

  return (
    <>
      <h3 className={CSS.title}>Create new user</h3>
      <DynamicForm
        id='external-user-form'
        formStyle='underline'
        onSubmit={handleSubmit}
        fields={fields}
      />
      <section className={classNames(CSS.actions)}>
        <button className={classNames('btn-primary',{loading:pending})} type='submit' form='external-user-form'>Create</button>
      </section>
    </>
  );

}
