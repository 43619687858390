import React from 'react';
import classNames from 'classnames';

import { Icon, DynamicForm } from '@moved/ui';

import CSS from './AuthenticationForm.module.scss';

export const AuthenticationForm = ({ fields, authenticate, pending }) => {
  return (
    <DynamicForm id="authentication-form"
      formStyle={'underline'}
      fields={fields}
      onSubmit={authenticate}
      disabled={pending}
    />
  );
};

export const AuthenticationFormWrapper = ({ display, title, subtitle, cta, pending, children }) => {
  return (
    <>
      <div className={CSS.content}>
        <div className={CSS.flex_wrapper}>
          { title && <h1 className={CSS.title}>{title}</h1> }
          { subtitle && <h3 className={CSS.subtitle}>{subtitle}</h3> }
          { children }
        </div>
      </div>
      <div className={CSS.footer}>
        { display === 'signup' && (
        <p className={CSS.disclaimer}>By clicking "{cta || 'Sign up'}" I agree to Moved&rsquo;s&nbsp;
          <a href={`${process.env.REACT_APP_MOVED_WEB_URL}/terms-of-use`} target="_blank" rel="noopener noreferrer">Terms of Service</a>
        </p>
        )}
        <label
          htmlFor="authentication-form-submit"
          role="button"
          className={classNames(CSS.button, {[CSS.loading]: pending})}
          tabIndex="0"
          disabled={pending}
        >
          { cta || (display === 'signup' ? 'Sign up' : 'Sign in') }
          <Icon symbol={'Arrow-right'} library={'navigation'} size={'24px'} color={'white'} className={CSS.arrow} />
        </label>
      </div>
    </>
  );
};
