import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { SearchScreen } from '@moved/product';
import { LoaderOverlay } from '@moved/ui'
import { useNotify } from '@moved/services';

import { getApprovals, getTaskTitles } from '../actions/';
import { useTaskTitles, useTaskTitlesPending } from '../actions/selectors';
import { getBuildings } from '../../moves/actions/'
import { useBuildings, useBuildingsPending } from '../../moves/actions/selectors';
import { useApprovals, useApprovalsPending, } from '../actions/selectors';
import { Approval } from './';

import columnsCSS from './styles/columns.module.scss';

export const ApprovalSearchWrapper = () => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const buildings = useBuildings();
  const buildingsPending = useBuildingsPending();
  const taskTitlesPending = useTaskTitlesPending();
  const pending = buildingsPending || taskTitlesPending;

  const taskTitles = useTaskTitles();
  const taskTitlesMapped = taskTitles.map(title => ({ name: title, id: title, }))

  // Fetch partner buildings and task titles on mount
  useEffect(() => {
    dispatch(getBuildings({limit: 500}))
      .catch(error => notify.error());

    dispatch(getTaskTitles())
      .catch(error => notify.error());
  // eslint-disable-next-line
  },[]);

  // Filters to be generated
  const filters = [
    {
      param: ['lease_date_start','lease_date_end'],
      filter: {
        label: 'Lease date',
        type: 'dateRangeSelect',
        props: { title: "Lease date", },
      },
    },

    {
      param: 'building_ids',
      multi: true,
      intValues: true,
      filter: {
        label: 'Property',
        type: 'multiSelect',
        list: buildings,
        props: { title: "Property name", },
      },
    },

    {
      param: 'building_task_titles',
      multi: true,
      filter: {
        label: 'Task',
        type: 'multiSelect',
        list: taskTitlesMapped,
        props: { title: "Task titles", },
      },
    },

    {
      param: 'statuses',
      multi: true,
      filter: {
        label: 'Status',
        type: 'multiSelect',
        list: [
          {
            name: "Pending",
            id: 'pending-approval',
          },
          {
            name: "Approved",
            id: 'approved',
          },
          {
            name: "Rejected",
            id: 'rejected',
          },
        ],
        props: { title: "Statuses", },
      },
    },

  ];

  // Sortable fields
  const sortables = [
    { value: 'icon' },
    { value:'building_task_title', label: 'Task'},
    { value:'name', label: 'Resident name'},
    { value:'building_name', label: 'Property'},
    { value:'unit', label: 'Unit'},
    { value:'submitted_at', label: 'Submitted'},
    { value:'lease_date', label: 'Lease date'},
    { value:'status', label: 'Status'},
  ];

  return (
    <>
      <Helmet>
        <title>Resident Approvals : Moved</title>
      </Helmet>

      { pending && (<LoaderOverlay />) }

      <SearchScreen
        title={'Approvals'}
        getSearchItems={getApprovals}
        useSearchItems={useApprovals}
        useSearchItemsPending={useApprovalsPending}
        filters={filters}
        sortables={sortables}
        sortableCSS={columnsCSS}
        Result={Approval}
      />
    </>
  )
};
