import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { Button, SlideToggle, Popover } from '@moved/ui'

import {
  getBuildingPendingRequests,
  getBuildingRepliedRequests,
  getBuildingCalendarWeek
} from '../actions';
import {
  useBuildingPendingRequests,
  useBuildingRepliedRequests,
} from '../actions/selectors';

import { RequestDetails } from './RequestDetails';

import CSS from './styles/BuildingNotifications.module.scss';

export const BuildingNotifications = ({ buildingId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [visible, setVisible] = useState(false);

  const pendingRequests = useBuildingPendingRequests(buildingId);

  useEffect(() => {
    if(!buildingId) return;
    Promise.all([
      dispatch(getBuildingPendingRequests(buildingId)),
      dispatch(getBuildingRepliedRequests(buildingId)),
    ])
      .catch(error => notify.error(format.error(error)));
  },[buildingId, dispatch, notify]);

  return (
    <Popover offset={[0,8]} placement='bottom'>
      <Popover.Trigger>
        <div className={CSS.wrapper}>
          <Button
            size='small'
            color={visible ? 'secondary' : 'tertiary'}
            icon={{ symbol: 'Notifications#1', library: 'general' }}
          />
          { pendingRequests.length > 0 && (
            <div className={CSS.notificationBadge}>{pendingRequests.length}</div>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content className='stackVertical gap-20' showArrow={false}>
        <Popover.Title>Requests</Popover.Title>
        <NotificationsMenu
          setVisible={setVisible}
          buildingId={buildingId}
        />
      </Popover.Content>
    </Popover>
  );
};

const NotificationsMenu = ({ buildingId, setVisible }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState('new');

  const pendingRequests = useBuildingPendingRequests(buildingId);
  const repliedRequests = useBuildingRepliedRequests(buildingId);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  },[setVisible]);

  const refreshCalendar = (request) => {
    dispatch(getBuildingCalendarWeek(
      request.building_calendar_id,
      moment(request.start_time).startOf('week').format('YYYY-MM-DD')
    ));
  };

  return (
    <div className={classNames('stackVertical gap-20',CSS.popover)}>
      <SlideToggle
        name='requestFilter'
        value={view}
        options={[
          { label: 'New', value: 'new' },
          { label: 'Replied', value: 'replied' },
        ]}
        onChange={({requestFilter}) => setView(requestFilter)}
      />

      <div className={CSS.content}>
        { view === 'new' && (
          <>
            { pendingRequests.length > 0 && pendingRequests.map(request => (
              <div className={CSS.request} key={request.id}>
                <div className={CSS.request_wrapper}>

                  <RequestDetails
                    request={request}
                    building={{id: buildingId}}
                    refreshCalendar={() => refreshCalendar(request)}
                  />

                </div>
              </div>
            ))}
            { pendingRequests.length <= 0 && (
              <p className={CSS.no_results}>There are currently no requests pending approval.</p>
            )}
          </>
        )}

        { view === 'replied' && (
          <>
            { repliedRequests.length > 0 && repliedRequests.map(request => (
              <div className={CSS.request} key={request.id}>
                <div className={CSS.request_wrapper}>

                  <RequestDetails
                    request={request}
                    building={{id: buildingId}}
                    refreshCalendar={() => refreshCalendar(request)}
                  />

                </div>
              </div>
            ))}
            { repliedRequests.length <= 0 && (
              <p className={CSS.no_results}>There are currently no upcoming requests.</p>
            )}
          </>
        )}

      </div>
    </div>
  );
};
