import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_GET_INSURANCE_SETTINGS',
  request: (tenantEventId, buildingTaskId) => request.get(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/renters-insurance-settings`)
    .then(r => get(r,'data.data.renters_insurance_settings')),
  selector: (state, ...params) => get(state,`insuranceSettings.${generate.uniqueKey(...params)}`),
  reducers: {
    success: (state, { response, params }) => ({
      ...state,
      insuranceSettings: {
        ...state.insuranceSettings,
        [generate.uniqueKey(...params)]: response,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useInsuranceSettings,
  usePending: useInsuranceSettingsPending,
} = generate.redux(creator);

export const initialState = {
  insuranceSettings: {},
};
