import React from 'react';
import classNames from 'classnames';

import { useUser, format } from '@moved/services';
import { Icon, Tooltip, ClipboardText, ClipboardIcon } from '@moved/ui';

import CSS from '../styles/MoveDetails.module.scss';

import { MoveDetailsStatus } from './MoveDetailsStatus';

export const MoveDetailsSummary = ({ move }) => {
  // HOOKS
  const { proxyAs, Can } = useUser();

  return (
    <div className={classNames(CSS.section,CSS.header)}>

      <div className={CSS.title}>
        <h1>{format.fullname(move?.user)}</h1>

        <div className={CSS.subtitle}>
          <h3>
            <ClipboardText text={move?.user?.email ?? ''} className={CSS.clipboard}/>
          </h3>

          <div className={CSS.id_row}>
            <div className={CSS.id_item}>
              <span className={CSS.label}>User ID</span>
              <ClipboardText text={String(move?.user?.id)} className={CSS.clipboard}/>
            </div>
            <div className={CSS.id_item}>
              <span className={CSS.label}>Move ID</span>
              <ClipboardText text={String(move?.id)} className={CSS.clipboard}/>
            </div>
          </div>
        </div>
      </div>

      <div className={CSS.activities}>
        <div className={CSS.actions}>
          <Can I={'ProxyAsUsers'}>
            <div className={CSS.action_item}>
              <Tooltip
                placement={"bottom"}
                tooltip={`Proxy as ${move?.user?.firstname}`}
              >
                <div className={classNames(CSS.oval, CSS.action)} onClick={e => proxyAs(move?.user?.id)}>
                  <Icon library='design' symbol='Disguise' />
                </div>
              </Tooltip>
            </div>
          </Can>
          <div className={CSS.action_item}>
            <Tooltip
              placement={"bottom"}
              tooltip={<>Copy link</>}
            >
              <ClipboardIcon
                className={classNames(CSS.action,CSS.oval)}
                text={`${process.env.REACT_APP_BASE_URL}/cx/moves/${move.id}?direct=${encodeURIComponent(move?.user?.email)}`}
              />
            </Tooltip>
          </div>
        </div>
        <MoveDetailsStatus move={move}/>
      </div>

    </div>
  );
};
