import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, AtomSpinner } from '@moved/ui';

import { getMovedDiscounts } from '../../actions';
import { useMovedDiscounts, useMovedDiscountsPending } from '../../actions/selectors';

import CSS from '../styles/AddCouponModal.module.scss';

const validation = Yup.object().shape({
  coupon_id: Yup.number().integer() // discounts are applied as coupons
    .required('Must choose a discount from the list'),
});

export const AddDiscountModal = ({ addHandler, addPendingSelector }) => {

  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const discounts = useMovedDiscounts() || [];
  const discountsPending = useMovedDiscountsPending();
  const addPending = addPendingSelector();

  useEffect(() => {
    dispatch(getMovedDiscounts())
      .catch(error => notify.error(format.error(error)));
  },[]); // eslint-disable-line

  if(discountsPending) return <AtomSpinner/>;

  const fields = [{
    type: 'select',
    name: 'coupon_id', // discounts are applied as coupons
    placeholder: 'Choose Discount',
    value: '',
    options: discounts.map(discount => ({
      label: `${discount.type === 'cents' ? format.currency(discount.cents) : format.percent(discount.percent)} Off`,
      value: discount.id
    })),
  }];

  const handleSubmit = (data) => {
    addHandler(data) // allows parent to pass in action (add to order or add to move)
      .then(() => {
        notify.default(`Discount Added!`);
        modal.close();
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Add Discount</h3>
    { discounts.length > 0 ? (
      <>
        <section className={classNames(CSS.section)}>
          <DynamicForm
            id='discount-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
            validation={validation}
          />
        </section>
        <section className={classNames(CSS.actions)}>
          <button className={classNames('btn-primary',{loading:addPending})} type='submit' form='discount-form'>Add</button>
        </section>
      </>
    ) : (
      <section className={classNames(CSS.section,CSS.none)}>
        No discounts configured yet.<br/>
        Call JoeBot.
      </section>
    )}
  </>);

}
