import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { Icon, ConfirmationModal } from '@moved/ui';
import { useModal, useUser } from '@moved/services';

import { cancelAppointment } from '../../actions/cancelAppointment';
import CSS from './styles/CalendarBlock.module.scss';

export const CalendarBlock = ({ block, refreshCalendar }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const { Can } = useUser();

  // Times for placement
  const midnight = moment.utc(block.start_time).set({hour:0,minute:0,second:0,millisecond:0});
  const slotStart = moment.utc(block.start_time);
  const slotEnd = moment.utc(block.end_time);
  const slotDuration = slotEnd.diff(slotStart,'minutes');

  const cancelBlock = () => {
    dispatch(cancelAppointment(block.appointment_type.id, block.id)).then(refreshCalendar);
  };

  const confirmCancel = () => modal.open(
    <ConfirmationModal
      title={'Cancel time block'}
      copy={`Are you sure wish to cancel this block?`}
      confirmText={'Proceed with cancellation'}
      cancelText={'Never mind'}
      onConfirm={cancelBlock}
    />,
  );

  return (
    <div
      className={classNames(CSS.block, { [CSS.block_small]: slotDuration < 31 })}
      style={{ top: `${slotStart.diff(midnight,'minutes')-1}px`, height: `${slotDuration+1}px`,}}
    >
      <div className={CSS.block_content}>
        <div className={CSS.block_line} />
        <div className={CSS.block_appointments}>
          <div className={CSS.block_appointment}>
            <span>{ block.title }</span>
            <Can I="CancelBuildingAppointments">
              <span className={CSS.cancel_block} onClick={confirmCancel}>
                <Icon library={'general'} symbol={'Trash'} size={'16px'}  />
              </span>
            </Can>
          </div>
        </div>
      </div>
    </div>
  );
}
