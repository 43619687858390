import React from 'react';

import { useScroller } from '@moved/services';

import CSS from '../styles/ApprovalLanding.module.scss';
import mvd_icon from '../images/mvd-icon.svg';

export const ApprovalLanding = ({ children }) => {
  const scroller = useScroller();

  return (
    <div className={CSS.background} ref={scroller.ref}>
      <div className={CSS.container}>
        <div className={CSS.content}>
          { children }
        </div>
        <div className={CSS.footer}>
          <img src={mvd_icon} alt="Moved." className={CSS.moved_logo} />
          <span className={CSS.copyright}>Copyright &copy; {new Date().getFullYear()} Moved, All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};
