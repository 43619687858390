import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useNotify, useUser, userService } from '@moved/services';
import { SigninForm, LayoutPromo } from '@moved/product';

import { usePartner } from '../../common/actions/selectors';

import CSS from '../styles/Authentication.module.scss';

export const Authentication = (props) => {
  const { partnerSlug } = useParams();
  const { user, hasDomain } = useUser();
  const partner = usePartner(partnerSlug);
  const history = useHistory();
  const notify = useNotify();

  useEffect(() => {
    if(!hasDomain('guest')) {
      notify.default(userService.getDomainBasedGreeting(user));
      userService.redirect(user,history);
    }
  },[]); // eslint-disable-line

  const onComplete = ({ login_response }) => {
    notify.default(userService.getDomainBasedGreeting(get(login_response,'user')));
    userService.redirect(get(login_response,'user'),history);
  };

  const title = partner ? `Welcome to ${get(partner,'name')}!` : 'Welcome!';

  return (
    <LayoutPromo partner={partner}>
      <Helmet>
        <title>{ get(partner,'name') ? `${partner.name} ` : '' }Client Admin : Moved</title>
      </Helmet>

      <div className={CSS.form_wrapper}>
        <SigninForm
          onComplete={onComplete}
          title={title}
          subtitle='Please login to your account.'
        />
      </div>

    </LayoutPromo>
  );
};
