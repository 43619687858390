import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_ORIENTATION_GET_FILE_UPLOAD_URL',
  request: (buildingId, data={}) => request.post(`/admins/buildings/${buildingId}/presigned-s3-urls/task-configuration/orientation`, data)
    .then(r => get(r,'data.data.presigned_s3_url')),
};

export const {
  action,
  reducer,
  usePending: useFileUploadUrlPending,
} = generate.redux(creator);
