import React from 'react';

import { format } from '@moved/services';
import { Card, FieldList, ClipboardText, Notebox } from '@moved/ui';

import { MoveDetailsSummary } from './MoveDetailsSummary';
import { MoveDetailsNotes } from './MoveDetailsNotes';

export const LegacyMoveDetails = ({ move }) => {
  const bookedTransaction = move?.booked_direct_app_move_transaction;
  const transactionFields = [
    {
      icon: { symbol: 'Info-circle', library: 'code' },
      label: 'Job Number',
      value: (<ClipboardText text={bookedTransaction?.job_number}/>),
    },
    {
      icon: { symbol: 'Date', library: 'code' },
      label: 'Move date',
      value: format.date(bookedTransaction?.move_date),
    },
    bookedTransaction?.vendor && {
      icon: { symbol: 'Truck', library: 'home' },
      label: 'Vendor',
      value: bookedTransaction?.vendor?.name,
    },
    bookedTransaction?.total && {
      icon: { symbol: 'Dollar', library: 'shopping' },
      label: 'Total Amount',
      value: format.currency(bookedTransaction?.total),
    },
    bookedTransaction?.created_at && {
      icon: { symbol: 'Date', library: 'code' },
      label: 'Created',
      value: format.date(bookedTransaction?.created_at),
    },
  ].filter(v => v?.value)

  return (
    <>

      <MoveDetailsSummary move={move} />
      <Notebox
        icon={{ symbol: 'Warning-2', library: 'code' }}
        heading='Legacy Move'
        body='This is a legacy move for display purposes only!'
        color='orange'
      />
      <MoveDetailsNotes move={move} />
      { bookedTransaction && (
        <Card>
          <div
            className='stackHorizontal gap-24 justify-between paddingBottom-16 borderSecondary'
            style={{ borderBottomWidth: '1px', borderBottomStyle: 'solid' }}
          >
            <div className='headingS contentPrimary'>
              Booked Transaction: { format.date(bookedTransaction?.move_date) }
            </div>
          </div>
          <FieldList list={transactionFields} />
        </Card>
      )}
    </>
  );
};
