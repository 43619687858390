import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { DynamicForm, Modal, Button } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import { orientationFields} from '../orientationFields'
import { createOrientationChapter } from '../../actions';
import { useCreateOrientationChapterPending } from '../../actions/selectors';

import CSS from './styles/CreateNewChapter.module.scss';

export const CreateNewChapter = ({ taskId, order = 1, onBack }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const modal = useModal();
  const notify = useNotify();

  // MOVE DETAILS STATE
  const pending = useCreateOrientationChapterPending();

  const createChapter = (data) => {
    dispatch(createOrientationChapter(taskId, data))
      .then(resp => {
        notify.default(`Chapter created!`);
        modal.close();
        return history.push(`${location.pathname}/${resp.id}`);
      })
      .catch(error => notify.error(format.error(error)));
  };

  const fields = [
    ...cloneDeep(orientationFields),
    {
      label: 'Order',
      type: 'hidden',
      name: 'order',
      value: order.toString(),
    },
  ];

  return (
    <>

      <Modal.Title>
        <span className='contentSecondary mr-10'>2/2</span>
        Add new chapter
      </Modal.Title>

      <Modal.Content className={CSS.content}>
        <DynamicForm
          id='new-chapter-form'
          formStyle='underline'
          fields={fields}
          onSubmit={createChapter}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Back'
          icon={{ library: 'navigation', symbol: 'Arrow-left' }}
          color='secondary'
          disabled={pending}
          onClick={() => !pending && onBack()}
        />
        <div className={CSS.spacer} />
        <Button
          text='Cancel'
          color='secondary'
          disabled={pending}
          onClick={() => !pending && modal.close()}
        />
        <Button
          text="Create chapter"
          color='primary'
          form="new-chapter-form"
          disabled={pending}
        />
      </Modal.Actions>

    </>
  );
};
