import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_MOVES_ADD_COUPON',
  request: (id,data={}) => request.post(`/cx/moves/${id}/claim-coupon`,data).then(r => get(r,'data.data.move')),
  reducers: {
    success: (state, { response: move }) => ({
      ...state,
      moveDetails: {
        ...state.moveDetails,
        [move.id]: move,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useAddCouponPending,
} = generate.redux(creator);

export const initialState = {};
