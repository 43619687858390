import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { useModal, format, useUser } from '@moved/services';
import { bookMovers } from '@moved/product';
import { Icon, ClipboardIcon, Tooltip, FieldList } from '@moved/ui';

import { MoveDetailsUnclaimedAdjustments } from './MoveDetailsUnclaimedAdjustments';
import { BookMoveModal } from './modals/BookMoveModal';

import CSS from '../styles/MoveDetailsPreBooking.module.scss';

const AddOrder = ({ moverBooking, move }) => {
  const modal = useModal();
  const { hasAbilities, Can } = useUser();
  const bookMoveModal = () => modal.openLegacy(<BookMoveModal move={move} moverBooking={moverBooking} />);
  const fields = bookMovers.getMoverBookingFields(moverBooking);

  // early exit
  if(!hasAbilities('ReadMoverBookings')) return null;

  return (
    <div className={CSS.section}>
      <div className={classNames(CSS.row,CSS.top)}>
        <div className={CSS.title}>
          <h3>Booking Request: { format.date(get(moverBooking,'requested_pickup_date')) }</h3>
        </div>
        <div className={CSS.actions}>
          <div className={CSS.action}>
            <MoverBookingQuoteCopyButton move={move} moverBooking={moverBooking}/>
          </div>
          <Can I='CreateServiceOrders'>
            <div className={classNames(CSS.action,CSS.action_btn)} onClick={bookMoveModal}>
              <Icon symbol={'Cart#1'} library={'shopping'} size={'16px'} />
              <span>Create Order</span>
            </div>
          </Can>
        </div>
      </div>
      <div className={CSS.row}>
        <div className={CSS.field_list}>
          <h5>Details</h5>
          <FieldList list={fields} />
        </div>
      </div>
    </div>
  );
};

const MoverBookingQuoteCopyButton = ({ moverBooking, move }) => {
  const customer = get(move,'user',{});
  const stops = get(moverBooking,'stops',[]);
  const origin = get(stops[0],'address');
  const destination = get(stops[stops.length-1],'address');
  const moveDate = get(moverBooking,'requested_pickup_date')
  const addressFormat = {street:false,unit:false,zipcode:false};
  const formattedData = bookMovers.getMoverBookingFields(moverBooking) // get list of all summary fields
    .filter(field => field.value && !['Email','Phone'].includes(field.label)) // only include ones with data and not contact information
    .map(field => `${field.label}: ${field.valueText || field.value}\n\n`) // format them
    .join(''); // join them

  const quoteRequestCopy = `${get(customer,'firstname','').charAt(0)}. ${get(customer,'lastname','')} ${format.date(moveDate,'M/D')} `+
    `Move Quote: ${format.address(origin,addressFormat)} to ${format.address(destination,addressFormat)}\n\n` +
    `Hello - Could you kindly provide me with a quote for the move below? Please let me know if you have any questions. Thanks!\n\n` +
    `${formattedData}`;

  return (
    <Tooltip tooltip='Quote Request Copy'>
      <ClipboardIcon text={quoteRequestCopy} className={classNames(CSS.action_oval)} size='16px' color='blue' />
    </Tooltip>
  );
};

const canCreateOrder = (moverBooking) => {
  const isReadyToBook = get(moverBooking, 'is_ready_to_book');
  const isBooked = get(moverBooking, 'booked_service_order') && get(moverBooking, 'booked_service_order.order_status') !== 'cancelled';
  return isReadyToBook && !isBooked;
};

export const MoveDetailsPreBooking = ({ move }) => {
  const readyToBookMoverBookings = get(move,'mover_bookings',[]).filter(canCreateOrder);
  const bookedMoverBookings = get(move,'mover_bookings',[]).filter(mb => {
    return get(mb,'booked_service_order') && get(mb,'booked_service_order.order_status') !== 'cancelled';
  });
  return (
    <>
      { readyToBookMoverBookings.map(moverBooking => (
        <AddOrder moverBooking={moverBooking} move={move} key={moverBooking.id}/>
      ))}
      { bookedMoverBookings.length === 0 && (
        <MoveDetailsUnclaimedAdjustments move={move} />
      )}
    </>
  )
};
