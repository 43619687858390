import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { noop } from 'lodash';

import { useQuery, request, useUser, useNotify, useScroller, format } from '@moved/services';
import { LoaderOverlay, Pagination, Icon, FilterPills, EmptyContent } from '@moved/ui';

import { PropertyResult } from './';
import { getPropertiesSearch } from '../actions';
import { usePropertiesSearch, usePropertiesSearchPending } from '../actions/selectors';
import { getClientSearch } from '../../clients/actions/';
import { useClientSearch, useClientSearchPending } from '../../clients/actions/selectors';

import CSS from './styles/PropertiesSearch.module.scss';

const SortColumn = ({name, sortBy, sortOrder, className, setSort = noop, children}) => {
  const match = sortBy === name;

  return (
    <div onClick={(e) => { e.preventDefault(); setSort(name)}} className={classNames(className, { [CSS.active]: match })}>
      {children}{match && (<Icon symbol={sortOrder === 'desc' ? 'Chevron-down': 'Chevron-up'} library='navigation' size='16px' />)}
    </div>
  );
}

export const PropertiesSearch = (props) => {
  // HOOKS
  const location  = useLocation();
  const scroller = useScroller();
  const notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasDomain } = useUser();

  // QUERY PARAMS: Get keywords, etc. from URL
  // Active pagination page
  let activePage = parseInt(useQuery('page'));
  if(!activePage) activePage = 1;
  // Search keywords
  let keywords = useQuery('keywords');
  if(!keywords) keywords = '';
  keywords = decodeURIComponent(keywords);
  // Filter by partner
  let partnerFilter = useQuery('partner_ids');
  partnerFilter = partnerFilter ? partnerFilter.split(',').map(id => parseInt(id)) : [];
  // Status filter
  let statusFilter = useQuery('status');
  if(!statusFilter) statusFilter = undefined;

  // Sort columns
  let sortBy = useQuery('sort_by');
  // Sort order
  let sortOrder = useQuery('sort_order');
  if(!sortOrder) sortOrder = 'asc';

  // QUERY FUNCTIONS: Functions to update query string in URL when action is taken
  const updateQuery = (page, keywords, partnerFilter, status, sortBy, sortOrder) => {
    return history.replace({
      pathname: location.pathName,
      search: `?${keywords && keywords.length > 0 ? `keywords=${encodeURIComponent(keywords)}` : ''}`
        + `${status ? '&status=' + encodeURIComponent(status) : '' }`
        + `&page=${encodeURIComponent(page)}`
        + `${partnerFilter && partnerFilter.length > 0 ? `&partner_ids=${partnerFilter}` : ''}`
        + `${sortBy ? '&sort_by='+sortBy+'&sort_order='+sortOrder : ''}`,
    });
  };
  const setActivePage = page => updateQuery(page,keywords,partnerFilter,statusFilter,sortBy,sortOrder);
  const setKeywords = keywords => updateQuery(1,keywords,partnerFilter,statusFilter,sortBy,sortOrder);
  const setPartnerFilter = selectedPartners => updateQuery(1,keywords,selectedPartners.join(','),statusFilter,sortBy,sortOrder);
  const setStatusFilter = status => updateQuery(1,keywords,partnerFilter,status,sortBy,sortOrder,);
  const setSort = newSort => {
    const newOrder = (newSort === sortBy && sortOrder === 'asc') ? 'desc' : 'asc';
    return updateQuery(1,keywords,partnerFilter,statusFilter,newSort,newOrder)
  };


  // GENERAL STATE
  const RESULTS_PER_PAGE = 10;

  // TOTAL PAGES - PAGINATION STATE
  const [totalPages, setTotalPages] = useState(1);

  // PROPERTIES STATE
  const properties = usePropertiesSearch();
  const propertiesPending = usePropertiesSearchPending();
  const clientsPending = useClientSearchPending();
  const pending = propertiesPending || clientsPending;

  // BUILDINGS REDUX
  const { activeSet: partners } = useClientSearch();

  // FILTER LIST
  const statuses = [
    {name: "Created", id: 'created'},
    {name: "Launched", id: 'launched'},
    {name: "Decommissioned", id: 'decommissioned'},
  ];

  // Function to grab moves
  const loadProperties = (cancelToken, delay) => {
    // Set request params
    const params = {
      page: activePage,
      limit: RESULTS_PER_PAGE,
      keywords: keywords.length > 0 ? keywords : null,
      partner_ids: partnerFilter.length > 0 ? partnerFilter.join(',') : null,
      status: statusFilter,
      sort_by: sortBy,
      sort_order: sortOrder,
    }

    //Scroll to top of page
    scroller.ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // If cancelToken is present, use for debounce purposes
    if(delay) return setTimeout(() => dispatch(getPropertiesSearch(params,cancelToken)).catch(noop), delay);
    return dispatch(getPropertiesSearch(params,cancelToken));
  };




  // --- USE EFFECT ---
  // Get previous value of keywords
  const prevKey = usePrevious(keywords);
  // Update search if any criteria change
  useEffect(() => {
    // Only use a timeout if keywords is what changed
    let delay = 500;
    if(typeof(prevKey) === 'undefined' || prevKey === keywords) delay = null;

    const { cancel, token } = request.CancelToken.source();

    const timeOutId = loadProperties(token, delay);

    return () => cancel("Keywords updated, query canceled") || clearTimeout(timeOutId);
  // eslint-disable-next-line
  },[keywords, activePage, partnerFilter.join(','), statusFilter, sortBy, sortOrder]);

  // Run when moves results change
  useEffect(() => {
    if(properties) {
      const newPageCount = Math.ceil(properties.totalResults / RESULTS_PER_PAGE);
      if(newPageCount !== totalPages) setTotalPages(newPageCount);
    }
  // eslint-disable-next-line
  },[properties]);

  // Fetch list of partners for search filter (if abode admin)
  useEffect(() => {
    if(!hasDomain('abode')) return;
    dispatch(getClientSearch({ limit: 999 }))
      .catch(error => notify.error(format.error(error)));
  },[]); // eslint-disable-line

  // --- RENDER ---
  return (
    <>
      <Helmet>
        <title>{`Admin Abode Property Search : Moved`}</title>
      </Helmet>

      { pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.title}>
          <h2>Properties</h2>
        </div>

        <div className={CSS.filters}>
          <FilterPills
            clearAll={() => updateQuery()}
            filters={
              [
                hasDomain('abode') && {
                  label: 'Client',
                  type: 'multiSelect',
                  list: partners,
                  active: partnerFilter,
                  props: {
                    onSelect: setPartnerFilter,
                    title: "Client",
                  },
                },
                hasDomain('abode') && {
                  label: 'Status',
                  type: 'singleSelect',
                  list: statuses,
                  active: statusFilter,
                  props: {
                    onSelect: setStatusFilter,
                    title: "Status",
                  },
                },
                hasDomain('abode') && {
                  type: 'divider',
                },
                {
                  label: 'Search',
                  type: 'keyword',
                  active: keywords,
                  props: {
                    onSelect: value => {
                      setActivePage(1);
                      return setKeywords(value);
                    },
                  },
                },
              ].filter(v=>v)
            }
          />
        </div>

        <div className={CSS.search}>
          <h3>
            Results{keywords && (<> for &ldquo;<span className={CSS.search_terms}>{keywords}</span>&rdquo;</>)}
            <span className={CSS.search_results}>{properties.totalResults}</span>
          </h3>
        </div>

        {/* Sort header */}
        <div className={CSS.sort_header}>

          <SortColumn name={'name'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.name} setSort={setSort}>Property name</SortColumn>

          <SortColumn name={'partner_name'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.partner_name} setSort={setSort}>Client</SortColumn>

          <SortColumn name={'city'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.address} setSort={setSort}>Address</SortColumn>

          <SortColumn name={'status'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.status} setSort={setSort}>Status</SortColumn>

          <SortColumn name={'status_date'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.status_date} setSort={setSort}>Status date</SortColumn>

          <SortColumn name={'upcoming_moves'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.moves} setSort={setSort}>Moves</SortColumn>

          <div className={CSS.arrow}>&nbsp;</div>

        </div>

        <div className={CSS.results}>
          { properties.activeSet.length ? properties.activeSet.map((property,idx) => {
            return (<PropertyResult property={property} key={property.id} />);
          }) : (
            <div className={CSS.no_results_wrapper}>
              <EmptyContent
                className={CSS.no_results}
                message='No properties match your search criteria'
                iconSize='50px'
              />
            </div>
          )}
        </div>

        {/* Page nav */}
        { totalPages > 1 && (
          <Pagination
            page={activePage}
            pageCount={totalPages}
            onPageChange={setActivePage}
            className={CSS.pager}
          />
        )}

      </div>
    </>
  );
};
