import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { LoaderOverlay } from '@moved/ui';
import { Four04 } from '@moved/product';

import { useBuildingCalendarSummaries } from '../actions/getBuildingCalendarSummaries';

export const BuildingCalendarRedirect = () => {
  const history = useHistory();
  const { buildingId } = useParams();
  const calendarList = useBuildingCalendarSummaries(buildingId);

  useEffect(() => {
    if(calendarList.length === 0) return;
    history.replace(`/admin/calendars/buildings/${buildingId}/calendars/${calendarList.at(0)?.id}`);
  },[calendarList, buildingId, history]);

  return calendarList.length === 0 ? <Four04 /> : <LoaderOverlay />
};
