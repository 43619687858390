import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useDrawer, useModal, useNotify, format, helpers, useUser } from '@moved/services';
import {
  Accordion,
  Button,
  Card,
  ClipboardText,
  ConfirmationModal,
  Drawer,
  Form,
  FormAddress,
  FormText,
  FormTextarea,
  FormToggle,
  Toggle,
  Icon,
} from '@moved/ui';

import { getSpecialItems, useSpecialItems } from '../actions/getSpecialItems';
import { useVendor, useVendorPending } from '../actions/getVendor';
import { updateVendor, useUpdateVendorPending } from '../actions/updateVendor';
import { InviteVendorModal } from './InviteVendorModal';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  headquarters: Yup.object().nullable().test('address','required',value => value?.google_place_id),
  commission: Yup.number().required('required'),
});

export const VendorDetailsDrawer = ({ vendorId, refreshSearch }) => {
  const drawer = useDrawer();
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { proxyAs, Can } = useUser();

  const vendor = useVendor(vendorId);
  const { data: specialItems } = useSpecialItems() ?? {};
  const vendorPending = useVendorPending();
  const updatePending = useUpdateVendorPending();

  useEffect(() => {
    dispatch(getSpecialItems())
      .catch(err => notify.error(format.error(err)));
  }, [dispatch, notify]);

  const resetStripe = () => dispatch(updateVendor(vendor.id, { stripe_account_id: null }));
  const resetStripeConfirmation = () => {
    modal.open(
      <ConfirmationModal onConfirm={resetStripe} isWarning={true} copy='This will permanently delete this Stripe connect link'/>,
      { onClose: vendor => vendor && refreshSearch?.() }
    );
  };

  const inviteVendor = () => {
    modal.open(
      <InviteVendorModal vendor={vendor} />,
      { onClose: vendor => vendor && refreshSearch?.() }
    )
  };

  const toggleVendorReady = (data) => {
    dispatch(updateVendor(vendor.id, data))
      .then(vendor => notify.default(`${vendor.name} updated.`))
      .catch(err => notify.error(format.error(err)));
  };

  const submitVendor = ({ headquarters, ...values }) => {
    const vendorData = {
      ...values,
      vendor_addresses: [{
        name: 'headquarters',
        ...headquarters,
      }],
    };
    dispatch(updateVendor(vendor.id, vendorData))
      .then(vendor => {
        notify.default(`${vendor.name} updated.`);
        refreshSearch?.();
        drawer.close();
      })
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Drawer>
      <Drawer.Header title='Vendor details' />
      <Drawer.Content>
        { (vendor && !vendorPending) && (
          <Form
            name='vendorDetailsForm'
            className='stackVertical gap-16'
            onSubmit={submitVendor}
            validation={validationSchema}
          >
            <Card className='stackVertical gap-8'>
              { vendor.user ? (
                <div className='stackHorizontal gap-16 items-center'>
                  <div className='width-40 flex justify-center'>
                    <Icon library='general' symbol='User' size='40px' />
                  </div>
                  <ClipboardText text={vendor.user.email}/>
                  <Can I={'ProxyAsUsers'}>
                    <Button
                      text='Proxy'
                      color='secondary'
                      size='small'
                      icon={{ library: 'design', symbol: 'Disguise' }}
                      onClick={() => proxyAs(vendor.user.id)}
                    />
                  </Can>
                </div>
              ) : (
                <div className='stackHorizontal'>
                  <Button
                    text='Invite admin'
                    color='secondary'
                    size='small'
                    icon={{ library: 'communication', symbol: 'Send' }}
                    onClick={inviteVendor}
                  />
                </div>
              )}
              { vendor.has_stripe && (
                <div className='stackHorizontal gap-16 items-center'>
                  <div className='width-40 flex justify-center'>
                    <Icon library='credit-cards' symbol='Stripe' size='40px' />
                  </div>
                  <div>Stripe enabled</div>
                  <Button
                    text='Reset'
                    color='tertiary'
                    showWarning={true}
                    size='small'
                    icon={{ library: 'general', symbol: 'Update' }}
                    onClick={resetStripeConfirmation}
                    style={{ display: 'none'}} // TODO: not functional yet, so hide this button
                  />
                </div>
              )}
              <Toggle
                label='Ready to accept jobs'
                name='details_complete'
                value={vendor.details_complete ?? false}
                className='marginTop-8 marginBottom-8'
                onChange={toggleVendorReady}
              />

            </Card>

            <FormText
              label='Vendor Name'
              name='name'
              value={vendor.name}
            />
            <FormAddress
              label='Headquarters'
              name='headquarters'
              value={vendor.vendor_addresses.find(a => a.name === 'headquarters')}
            />
            <FormTextarea
              label='Description'
              name='default_description'
              value={vendor.default_description}
              autoResize={true}
            />
            <FormText
              label='Commission'
              name='commission'
              value={String(vendor.commission)}
              type='number'
            />
            <FormText
              label='Review link'
              name='review_link'
              value={vendor.review_link}
            />
            <Accordion
              className='marginTop-24'
              panels={[{
                label: 'Special item capabilities',
                content: (
                  <div className='stackVertical gap-20'>
                    {(specialItems ?? []).map(item => (
                      <FormToggle
                        key={item}
                        label={format.capitalize(helpers.convertNamingConvention(item,'snake','space'))}
                        name={`item_settings.${item}_support`}
                        value={vendor?.item_settings?.[`${item}_support`]}
                      />
                    ))}
                  </div>
                ),
              }]}
            />
          </Form>
        )}
      </Drawer.Content>
      <Drawer.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => drawer.close()}
          disabled={updatePending}
        />
        <Button
          text='Save'
          color='primary'
          form='vendorDetailsForm'
          disabled={updatePending}
        />
      </Drawer.Actions>
    </Drawer>
  );
}
