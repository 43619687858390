import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { format, useUser } from '@moved/services';
import { ClipboardText, Icon } from '@moved/ui';

import CSS from './styles/LeaseDetails.module.scss';

export const LeaseDetails = ({ lease, tenant, building, stepType }) => {
  const { hasDomain, hasAbilities } = useUser();
  return (
    <div className={CSS.lease_info}>
      <div className={CSS.row}>
        <div className={CSS.block}>
          <label>Lease { stepType === 'move-out' ? 'End' : 'Start' }</label>
          <span className={CSS.value}>
            { stepType === 'move-out' ? (
              get(lease,'end_date') ? format.date(get(lease,'end_date')) : '—'
            ) : (
              get(lease,'start_date') ? format.date(get(lease,'start_date')) : '—'
            )}
          </span>
        </div>
        <div className={CSS.block}>
          <label>Property</label>
          { hasAbilities('ReadBuildings') ? (
            <Link className={classNames('stackHorizontal gap-4 items-center contentPrimary',CSS.value)} to={`/admin/properties/${building.id}`}>
              { get(building,'name') }
              <Icon symbol='Up-right' library='navigation' size='20px' color='inherit'/>
            </Link>
          ) : (
            <span className={CSS.value}>{ get(building,'name') }</span>
          )}
        </div>
        {get(lease,'address.unit') && (
          <div className={CSS.block}>
            <label>Unit</label>
            <span className={CSS.value}>{ get(lease,'address.unit') }</span>
          </div>
        )}
        {(hasDomain('cx') || hasDomain('abode')) && (
          <div className={CSS.block}>
            <label>Client</label>
            { hasAbilities('ReadPartners') ? (
              <Link className={classNames('stackHorizontal gap-4 items-center contentPrimary',CSS.value)} to={`/admin/clients/${get(building,'partner.id')}`}>
                { get(building,'partner.name') }
                <Icon symbol='Up-right' library='navigation' size='20px' color='inherit'/>
              </Link>
            ) : (
              <span className={CSS.value}>{ get(building,'partner.name') }</span>
            )}
          </div>
        )}
      </div>
      <div className={CSS.row}>
        {get(lease,'external_lease_number') && (
          <div className={CSS.block}>
            <label>Lease Number</label>
            <ClipboardText text={get(lease,'external_lease_number')} className={CSS.clipboard}/>
          </div>
        )}
        {(hasDomain('cx') || hasDomain('abode')) && get(building,'id') && (
          <div className={CSS.block}>
            <label>Building ID</label>
            <ClipboardText text={`${get(building,'id')}`} className={CSS.clipboard}/>
          </div>
        )}
        {get(tenant,'external_contact_id') && (
          <div className={CSS.block}>
            <label>Contact ID</label>
            <ClipboardText text={get(tenant,'external_contact_id')} className={CSS.clipboard}/>
          </div>
        )}
        {(hasDomain('cx') || hasDomain('abode')) && get(tenant,'id') && (
          <div className={CSS.block}>
            <label>Tenant ID</label>
            <ClipboardText text={`${get(tenant,'id')}`} className={CSS.clipboard}/>
          </div>
        )}
      </div>
    </div>
  );
};
