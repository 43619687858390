import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay, TabSlider, Icon, PopOver } from '@moved/ui';
import { useNotify, format, useQuery } from '@moved/services';

import { DefaultConfiguration } from '../../../components';
import { FileRequirementList } from './FileRequirementList';
import { getFileRequirementList } from '../actions';
import { useFileRequirementList, useFileRequirementListPending } from '../actions/selectors';

import CSS from './styles/DocumentSubmission.module.scss';

export const Configuration = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { task_id } = useParams();
  const notify = useNotify();
  const history = useHistory();
  const location = useLocation();
  const view = useQuery('view') || 'general';

  // Update query string in URL when view is selected
  const setView = (view) => {
    return history.replace({
      pathname: location.pathName,
      search: view ? `?view=${encodeURIComponent(view)}` : '',
    });
  };

  // REDUX
  const fileList = useFileRequirementList(task_id);
  const pending  = useFileRequirementListPending();

  useEffect(() => {
    dispatch(getFileRequirementList(task_id))
      .catch(error => notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);

  if(pending) return (<LoaderOverlay />);

  return (
    <>
      {pending && (<LoaderOverlay />)}

      <div className={CSS.header}>
        <div className={CSS.title}>
          <h1>{get(task,'title')}</h1>
        </div>
      </div>

      <TabSlider
        className={CSS.slider}
        list={[
          {label: "General", value: 'general'},
          {label: (
            <div className={CSS.doc_tab}>
              <span>Documents</span>
              {!fileList.id && (
                <PopOver
                  customClass={CSS.popover}
                  customArrowClass={CSS.popover_arrow}
                  hideOnScroll={true}
                  placement="top"
                  trigger={"hover"}
                  tooltip={
                    <span className={CSS.popover_text}>
                      Requires setup
                    </span>
                  }
                >
                  <Icon symbol="Warning-2-circle-small" library="code" size="16px"/>
                </PopOver>
              )}
            </div>
          ), value: 'documents'},
        ]}
        active={view}
        callback={view => setView(view)}
      />

      <div className={CSS.view}>
        {(function() {
          switch(view) {
            case 'documents':
              return(<FileRequirementList task={task} fileList={fileList} />);
            default:
              return (<DefaultConfiguration task={task} header={false} />);
          };
        })()}
      </div>
    </>
  );
};
