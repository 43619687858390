import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { get, cloneDeep } from 'lodash';

import { LoaderOverlay, Icon, DynamicForm, Uploader, ContentEditor, Button } from '@moved/ui';
import { fieldService, format, useNotify, useModal, s3, helpers } from '@moved/services';

import { useTaskDetails } from '../../../actions/selectors';
import { getOrientationContents, updateOrientationContents, getFileUploadUrl } from '../actions';
import { useOrientationContents, useOrientationContentsPending, } from '../actions/selectors';

import { ChapterPreviewModal } from './ChapterPreviewModal';
import { orientationFields} from './orientationFields'

import CSS from '../../../components/styles/TaskDetails.module.scss';
import ChapterCSS from './styles/ChapterDetails.module.scss';

const _handleReturn = (state, history, property_id, task_id) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go back to the chapter list
  else history.push(`/admin/properties/${property_id}/tasks/${task_id}/chapters`);
};

export const ChapterDetails = ({ children }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const modal = useModal();

  const { chapter_id, task_id, property_id } = useParams();

  const task = useTaskDetails(task_id);
  const contents = useOrientationContents(task_id) || [];
  const contentsPending = useOrientationContentsPending();
  const [pending, setPending] = useState();

  const chapter = contents.find(chapter => { return chapter.id === parseInt(chapter_id)});

  const [formData, setFormData] = useState(chapter);
  const [content, setContent] = useState(chapter?.content);
  const [newSideImage, setNewSideImage] = useState();
  const newImagePreview = helpers.canvas.usePreview(newSideImage);

  useEffect(() => {
    dispatch(getOrientationContents(task_id))
      .catch(error => notify.error(format.error(error)));
  },[task_id]); // eslint-disable-line

  const handleSubmit = async () => {
    if(pending) return;
    setPending(true);

    let sideImageUrlToSave = chapter?.side_column_image_url;

    // handle async side image saving before saving the screen data
    if(newSideImage) {
      const url = await dispatch(getFileUploadUrl(property_id,{
        filename: format.filename(newSideImage.name),
        http_content_type: newSideImage.type,
      }))
        .then(({ signed_request, url }) => s3.putFile(newSideImage,signed_request).then(() => url))
        .catch(error => { notify.error(format.error(error)) });
      if(!url) return;
      sideImageUrlToSave = url;
    } else if (newSideImage === false) {
      sideImageUrlToSave = null;
    }

    // assemble the chapter data to save
    const currentChapter = {
      ...formData,
      content,
      side_column_image_url: sideImageUrlToSave,
    };

    // save and exit the chapter edit screen
    return dispatch(updateOrientationContents(chapter_id, currentChapter))
      .then(() => {
        notify.default(`Orientation contents updated!`);
        _handleReturn(location.state, history, property_id, task_id);
      })
      .catch(error => notify.error(format.error(error)))
      .finally(() => setPending(false));
  };

  if(contents.length === 0) return (
    <>
      <Helmet>
        <title>{`Task Details : Moved`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  const showPreviewModal = () => {
    const currentChapter = {
      ...formData,
      content,
      side_column_image_url: newSideImage !== false ? newImagePreview ?? chapter?.side_column_image_url: null,
    };
    return modal.open(
      <ChapterPreviewModal chapter={currentChapter} title={`${currentChapter.title} Preview`}/>
    );
  };

  const handleSideImageUpdate = (files) => {
    setNewSideImage(files[0] ?? false);
  };

  const fields = [
    {
      label: 'General',
      type: 'title',
    },
    ...cloneDeep(orientationFields),
  ];

  fieldService.hydrate(fields,chapter);

  return (
    <>
      <Helmet>
        <title>{`${get(task,'title')} Task : Moved`}</title>
      </Helmet>

      { contentsPending && (<LoaderOverlay />)}

      <div className={CSS.content_top}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            <div className={CSS.back_btn} onClick={e => _handleReturn(location.state, history, property_id, task_id)}>
              <Icon symbol='Arrow-left' library='navigation' size='32px' />
            </div>
            <h1>{get(chapter,'title')}</h1>
          </div>
        </div>

        <div className={CSS.form}>

          <DynamicForm
            id='chapter-form'
            formStyle='underline'
            fields={fields}
            onChange={setFormData}
            onSubmit={handleSubmit}
          />

          <div className='stackHorizontal gap-24 marginBottom-20 marginTop-20' style={{alignItems:'center'}}>
            <div style={{flex:'1 1 auto'}}>
              <div className='labelL contentPrimary marginBottom-4'>Body</div>
              <div className='labelS contentSecondary'>
                Edit the chapter content below.  You can see how the content will appear
                for users using the "Preview content" button. Legacy cannot be edited,
                however you may move or delete it.
              </div>
            </div>
            <Button
              color='tertiary'
              size='default'
              onClick={showPreviewModal}
              text='Preview content'
            />
          </div>
          <div className={ChapterCSS.contentEditor}>
            <ContentEditor
              id={'content-editor'}
              readOnly={false}
              disabled={null}
              data={chapter.content}
              onChange={setContent}
              minHeight={100}
              autofocus={false}
              logLevel={'ERROR'}
              customConfig={{ uploadUrl: `/admins/buildings/${property_id}/presigned-s3-urls/task-configuration/orientation` }}
            />
          </div>

          <div className='labelS contentSecondary marginBottom-12'>Side column image</div>
          <Uploader
            id='side_column_image_url'
            dzOptions={{
              accept: {
                'image/jpg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
                'image/gif': ['.gif']
              },
              multiple: false,
              maxFiles: 1,
            }}
            initialFiles={chapter?.side_column_image_url ? [{
              name: chapter.side_column_image_url.split('/').pop(),
              src: chapter.side_column_image_url,
            }]: []}
            onSelect={handleSideImageUpdate}
            icon={{size:'30px'}}
          />

        </div>

      </div>

      <div className={CSS.content_bottom}>
        <div className={CSS.buttons}>
          <label
            id='chapter_form_button'
            htmlFor="chapter-form-submit"
            tabIndex="0"
            role="button"
            className={classNames('btn-primary', CSS.save, {'loading': pending})}
            disabled={pending}
          >
            Save
          </label>

          <label
            tabIndex="1"
            role="button"
            className={classNames('btn-ghost')}
            disabled={pending}
            onClick={e => {
              e.preventDefault();
              if (pending) return;
              return _handleReturn(location.state, history, property_id, task_id);
            }}
          >
            Discard changes
          </label>
        </div>
      </div>
    </>
  );
};
