import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_RESOLVE_PAYMENT_ISSUE',
  request: (id,data={}) => request.post(`/cx/service-orders/${id}/customer-payment-retries`,data).then(r => get(r,'data.data.service_order')),
  reducers: {
    success: (state, { response: order, params: [id] }) => ({
      ...state,
      orders: {
        ...state.orders,
        [id]: order,
      }
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useResolvePaymentIssuePending,
} = generate.redux(creator);

export const initialState = {};
