import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  CardSetupForm,
  Collapsible,
  Modal,
  SlideToggle,
} from '@moved/ui';

import { resolvePaymentIssue, retryPaymentAuthorization, getOrder } from '../../actions';

import CSS from './AddPaymentModal.module.scss';

const statusToActionMap = {
  payment_issue: resolvePaymentIssue,
  pre_authorization_failed: retryPaymentAuthorization,
};

export const ResolvePaymentIssueModal = ({ order={} }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();
  const [retry, setRetry] = useState(true);

  const resolutionAction = statusToActionMap[get(order,'payment_status')];

  const submitResolve = ({ setupIntent={} }) => {
    dispatch(resolutionAction(order.id, { stripe_payment_method_id: setupIntent.payment_method }))
      .then(() => {
        notify.default(`Payment successfully resolved!`);
        modal.close();
      })
      .catch(error => {
        // refresh the order to see the new payment attempt
        dispatch(getOrder(order.id)).then(() => {
          setPending(false);
          notify.error(format.error(error));
        });
      });
  };

  const handleStripeError = (error) => {
    setPending(false);
    notify.error(
      format.error(error,false,format.stripeError), // map stripe errors
      { autoHideDuration: 6000 }, // slightly longer than normal display times
    );
  };

  // retry handler
  const handleSubmit = () => {
    if(pending || !retry) return;
    setPending(true);
    submitResolve({});
  };

  return (
    <Modal>
      <Modal.Title>Resolve payment issue</Modal.Title>
      <Modal.Content className={classNames('stackVertical gap-16',CSS.content)}>
        <div className={CSS.previous_message}>
          Failed payment with {format.capitalize(get(order,'default_payment_method.card_brand'))} ending in {get(order,'default_payment_method.card_last4')}.
        </div>
        <SlideToggle
          name='retry'
          value={retry}
          options={[
            {
              label: 'Retry with card on file',
              value: true,
            },
            {
              label: 'Enter new card details',
              value: false,
            }
          ]}
          onChange={({retry}) => setRetry(retry)}
          isControlled
          size='large'
        />
        <Collapsible open={!retry}>
          <CardSetupForm
            onSubmit={() => setPending(true)}
            onSuccess={submitResolve}
            onError={handleStripeError}
            className={CSS.form}
            submitLabel={false}
            formId='stripe-card-form'
          />
        </Collapsible>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={modal.close}
        />
        { retry ? (
          <Button
            text='Retry'
            disabled={pending}
            onClick={handleSubmit}
          />
        ) : (
          <Button
            text='Update & Retry'
            disabled={pending}
            type='submit'
            form='stripe-card-form'
          />
        )}
      </Modal.Actions>
    </Modal>
  );

}
