import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { Table, Pagination, EmptyContent, StatusPill, LoaderOverlay } from '@moved/ui';
import { format, useNotify, useScroller } from '@moved/services';
import { MoveStepTypeIcon } from '@moved/product';

import { getBuildingFeedHistory } from '../actions';
import { useBuildingFeedHistory, useBuildingFeedHistoryPending } from '../actions/selectors';

import CSS from './styles/PropertyIntegrations.module.scss';

const PER_PAGE_LIMIT = 10;

export const PropertyIntegrationHistory = ({ property, activeIntegration, page, setPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const scroller = useScroller();

  const { history:feedHistory=[], pagination={} } = useBuildingFeedHistory(property.id) || {};
  const pending = useBuildingFeedHistoryPending();

  // load the feed history for any the search changes
  useEffect(() => {
    if(!activeIntegration) return;
    // Scroll to top of page
    scroller.ref.current.scrollTo({ top: 0, behavior: 'smooth' });
    // Load the history results
    dispatch(getBuildingFeedHistory(property.id, {
      move_step_type: activeIntegration,
      page,
      limit: PER_PAGE_LIMIT,
    }))
      .catch(error => notify.error(format.error(error)));
  },[activeIntegration, page, scroller.ref, dispatch, notify]); // eslint-disable-line

  // declare the columns to use in the results header row and the results item rows
  const feedColumns = [
    {
      name: 'direction',
      label: 'Direction',
      getValue: (item) => (
        <div className={CSS.directionWrapper}>
          <MoveStepTypeIcon type={get(item,'move_step_type.label')} size='24px' />
          { get(item,'move_step_type.display_name')}
        </div>
      ),
      className: CSS.direction,
    },
    {
      name: 'date',
      label: 'Date',
      getValue: (item) => (
        <div className={CSS.dateWrapper}>
          <div className='labelM contentPrimary'>{format.date(get(item,'completed_at') || get(item,'created_at'),'MMM D, YYYY - h:mm a')}</div>
          <div className='labelS contentSecondary'>{format.date(get(item,'completed_at') || get(item,'created_at'), 'relative')}</div>
        </div>
      ),
      className: CSS.timestamp,
    },
    {
      name: 'initiator',
      label: 'Initiated by',
      getValue: (item) => (
        get(item,'trigger_method') === 'automatic' ? (
          <StatusPill status='Automatic' className={CSS.pill} />
        ) : (
          format.fullname(get(item,'initiated_by_user'))
        )
      ),
      className: CSS.initiator,
    },
    {
      name: 'status',
      label: 'Status',
      getValue: (item) => (
        <StatusPill status={get(item,'completed_at')? 'complete' : 'pending' } className={CSS.pill}/>
      ),
      className: CSS.status,
    }
  ];

  return (
    <div className='mt-15 stackVertical gap-12'>
      { pending && <LoaderOverlay/> }
      { feedHistory.length > 0 ? (
        <>
          { get(pagination,'total_pages') > 1 && (
            <Pagination page={page} pageCount={get(pagination,'total_pages')} onPageChange={setPage}/>
          )}
          <Table
            columns={feedColumns}
            rows={feedHistory.map(sync => ({
              data: sync,
              onClick: () => history.push(`/admin/properties/${property.id}/syncs/${sync.id}`),
            }))}
          />
          { get(pagination,'total_pages') > 1 && (
            <Pagination page={page} pageCount={get(pagination,'total_pages')} onPageChange={setPage}/>
          )}
        </>
      ) : (
        // NO RESULTS
        <EmptyContent />
      )}
    </div>
  );
};
