import React from 'react';

import { MoveDetailsSummary } from './MoveDetailsSummary';
import { MoveDetailsNotes } from './MoveDetailsNotes';
import { MoveDetailsPreBooking } from './MoveDetailsPreBooking';
import { OrderList } from '../../orders';

export const MoveDetails = ({ move }) => (
  <>
    <MoveDetailsSummary move={move} />
    <MoveDetailsNotes move={move} />
    <OrderList move={move} />
    <MoveDetailsPreBooking move={move} />
  </>
);
