import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { format, useModal, useNotify } from '@moved/services';
import { orientation } from '@moved/product';

import { StatusHeader, DeclineReasonModal, RenderAttributes } from '../../shared/components';

import { reviewOrientationRequest } from '../actions';
import { useReviewOrientationRequestPending } from '../actions/selectors';

import CSS from '../../shared/styles/shared.module.scss';

export const ReviewContent = ({ request }) => {

  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const { token } = useParams();
  const [approvalPending, setApprovalPending] = useState();

  const status = {
    icon: { symbol: 'Info', color: 'blue' },
    title: `${get(request,'orientation_content_title')} Task Review`,
    description: `Please review the ${get(request,'orientation_content_title')} details below and indicate whether it is approved or declined.`,
  };
  const triggerApproval = () => {
    if(approvalPending) return;
    setApprovalPending(true);
    dispatch(reviewOrientationRequest(token,'approve'))
      .catch(error => {
        setApprovalPending(false);
        notify.error();
      })
  };
  const triggerReject = () => {
    const reject = (data) => dispatch(reviewOrientationRequest(token,'reject',data))
      .then(() => modal.close())
      .catch(error => notify.error());
    modal.openLegacy(<DeclineReasonModal submit={reject} usePending={useReviewOrientationRequestPending}/>);
  };
  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.tenant)}</span>
      </div>
      <RenderAttributes attributes={orientation.getRequestFields(request)} />
      <div className={classNames(CSS.actions)}>
        <button className={classNames('btn-green-dark',{loading:approvalPending})} onClick={triggerApproval}>Approve</button>
        <button className={classNames('btn-ghost-red')} onClick={triggerReject}>Decline</button>
      </div>
    </>
  );
};
