import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_VENDOR_SEARCH',
  request: (params) => request.get(`/admins/vendors`,{ params }).then(r => r?.data),
  selector: (state) => state.vendorSearch,
  reducers: {
    success: (state, { response: { data, pagination }, params }) => ({
      ...state,
      vendorSearch: {
        params,
        pagination,
        vendors: data?.vendors,
      }
    }),
  },
};

export const {
  action: vendorSearch,
  reducer,
  useResource: useVendorSearch,
  usePending: useVendorSearchPending,
} = generate.redux(creator);

export const initialState = {
  vendorSearch: {},
};
