import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_UTILITY_SETUP_GET_S3_UPLOAD_URL',
  request: (id, data={}) => request.post(`/admins/buildings/${id}/presigned-s3-urls/tenant-event-uploads/utility-setup`, data)
    .then(r => get(r,'data.data.presigned_s3_url')),
};

export const {
  action,
  reducer,
} = generate.redux(creator);
