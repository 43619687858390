import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMINS_CALENDAR_RESCHEDULE_APPOINTMENT',
  request: (typeId, id, data={}) => request.post(`/admins/appointment-types/${typeId}/appointments/${id}/reschedule`, data)
    .then(r => r?.data?.data?.appointment),
};

export const {
  action: rescheduleAppointment,
  reducer,
  usePending: useRescheduleAppointmentPending,
} = generate.redux(creator);

export const initialState = {};
