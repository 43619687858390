import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { format, useModal, useNotify } from '@moved/services';
import { docSubmission } from '@moved/product';

import { StatusHeader, DeclineReasonModal, RenderAttributes } from '../../shared/components';

import { reviewDocumentSubmission } from '../actions';
import { useReviewDocumentSubmissionPending } from '../actions/selectors';

import CSS from '../../shared/styles/shared.module.scss';

export const ReviewContent = ({ request }) => {

  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const { token } = useParams();
  const [approvalPending, setApprovalPending] = useState();

  const status = {
    icon: { symbol: 'Info', color: 'blue' },
    title: `Document Submission Review`,
    description: `Please review the document submission details below and indicate whether it is approved or declined.`,
  };
  const triggerApproval = () => {
    if(approvalPending) return;
    setApprovalPending(true);
    dispatch(reviewDocumentSubmission(token,'approve'))
      .catch(error => {
        setApprovalPending(false);
        notify.error();
      })
  };
  const triggerReject = () => {
    const reject = (data) => dispatch(reviewDocumentSubmission(token,'reject',data))
      .then(() => modal.close())
      .catch(error => notify.error());
    modal.openLegacy(<DeclineReasonModal submit={reject} usePending={useReviewDocumentSubmissionPending}/>);
  };
  return (
    <>
      <StatusHeader {...status} />
      <div className={CSS.resident_name}>
        <span className={CSS.name_label}>Resident Name</span>
        <span className={CSS.name_value}>{format.fullname(request.submitted_by)}</span>
      </div>
      <RenderAttributes attributes={docSubmission.getRequestFields(request)} />
      <div className={classNames(CSS.actions)}>
        <button className={classNames('btn-green-dark',{loading:approvalPending})} onClick={triggerApproval}>Approve</button>
        <button className={classNames('btn-ghost-red')} onClick={triggerReject}>Decline</button>
      </div>
    </>
  );
};
