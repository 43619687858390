import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_UPDATE_ORDER_DETAILS',
  request: (orderId, moverBookingId, data={}) => request.patch(`/cx/mover-bookings/${moverBookingId}`, data).then(r => get(r,'data.data.mover_booking')),
  reducers: {
    success: (state, { response: moverBooking, params: [orderId, moverBookingId] }) => ({
      ...state,
      orders: {
        ...state.orders,
        [orderId]: {
          ...state.orders[orderId],
          mover_booking: moverBooking,
        },
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateOrderDetailsPending,
} = generate.redux(creator);

export const initialState = {};
