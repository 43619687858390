import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_GET_VENDOR',
  request: (id) => request.get(`/admins/vendors/${id}`).then(r => r?.data?.data?.vendor),
  selector: (state, id) => state?.vendors?.[id],
  reducers: {
    success: (state, { response: vendor }) => ({
      ...state,
      vendors: {
        ...state.vendors,
        [vendor.id]: vendor,
      },
    }),
  },
};

export const {
  action: getVendor,
  useResource: useVendor,
  usePending: useVendorPending,
  reducer,
} = generate.redux(creator);

export const initialState = {
  vendors: {},
};
