import React, { memo, createRef, useEffect } from 'react';
import moment from 'moment';

import { CalendarDay } from './CalendarDay';
import { CalendarTimeGrid } from './CalendarTimeGrid';

import CSS from './styles/CalendarWeek.module.scss';

//If calendar data is pending, don't rerender
const shouldSkipUpdate = (_,{ weeklyCalendarPending }) => weeklyCalendarPending;

export const CalendarWeek = memo(({ weeklyCalendar, activeDate, activeCalendar, building, refreshCalendar }) => {
  // Scroll to 7am time on initial render
  const calendarElement = createRef();
  useEffect(() => {
    if(calendarElement.current) calendarElement.current.scrollTop = 420;
  },[]); // eslint-disable-line

  const {
    availability=[],
    appointments=[],
    requests=[],
    blocked_times: blockedTimes=[],
  } = weeklyCalendar ?? {};

  return (
    <div className={CSS.week_row} ref={calendarElement}>
      <div className={CSS.week_holder}>

      <CalendarTimeGrid />

      { availability.map(day => (
        <CalendarDay
          key={day.date}
          date={day.date}
          slots={day?.timeslots ?? []}
          blocks={blockedTimes.filter(block => moment(block.start_time).isSame(day.date, 'd'))}
          requests={requests.filter(request => moment(request.start_time).isSame(day.date, 'd'))}
          appointments={appointments.filter(appointment => moment(appointment.start_time).isSame(day.date, 'd'))}
          isActive={day.date === activeDate}
          calendar={activeCalendar}
          building={building}
          refreshCalendar={refreshCalendar}
        />
      ))}
      </div>
    </div>
  );
}, shouldSkipUpdate);
