import React from 'react';
import { get} from 'lodash';
import classNames from 'classnames';

import { useUser, useModal } from '@moved/services';
import { Icon, ClipboardWrapper } from '@moved/ui';

import { SendInviteModal } from './SendInviteModal';

import CSS from './styles/UnclaimedTenantEvent.module.scss';

export const UnclaimedTenantEvent = ({ tenantEvent }) => {
  const modal = useModal();
  const { Can, getDomainId } = useUser();
  const clientId = getDomainId('client');

  return (
    <div className={CSS.unregistered}>
      <Icon className={CSS.warning} symbol={'Warning-1-circle'} library={'code'} size={'48px'} />
      <h3>This tenant has not accepted their link</h3>
      <Can I="CreateTenantEventReminders">
        <div className='flex items-center'>
          <div
            className={classNames(CSS.reminder,'btn--small')}
            onClick={e => modal.openLegacy(<SendInviteModal tenantEventID={get(tenantEvent,'id')} partnerID={clientId} />, { hideCloseButton: true })}
          >
            <Icon symbol={'Send'} library={'communication'} size={'16px'} />
            <span>Send reminder</span>
          </div>
          { get(tenantEvent,'claim_token') && (<>
            <h6 className='ml-10 mr-10'>OR</h6>
            <ClipboardWrapper text={`${process.env.REACT_APP_CONSUMER_APP_URL}/signup/${get(tenantEvent,'building.slug')}/${get(tenantEvent,'claim_token')}`}>
              <div className={classNames(CSS.reminder,'btn--small')}>
                <Icon symbol={'Clipboard'} library={'files'} size={'16px'} />
                <span>Copy signup link</span>
              </div>
            </ClipboardWrapper>
          </>)}
        </div>
      </Can>
    </div>
  );
};
