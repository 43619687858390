import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get, isNil } from 'lodash';

import { StatusPill, DataBar, Button, Icon, LoaderOverlay, CodeBlock, ClipboardWrapper, EmptyContent } from '@moved/ui';
import { format, useNotify } from '@moved/services';
import { MoveStepTypeIcon } from '@moved/product';

import { getBuildingFeedSync} from '../actions';
import { useBuildingFeedSync, useBuildingFeedSyncPending } from '../actions/selectors';

import CSS from './styles/FeedSyncDetails.module.scss';

export const FeedSyncDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const { property_id, feedSyncId } = useParams();
  const feedSync = useBuildingFeedSync(property_id,feedSyncId);
  const pending = useBuildingFeedSyncPending();

  // if still pending use start time, if complete use completed time
  const isComplete = !isNil(get(feedSync,'completed_at'));
  const feedTimestamp = isComplete ? get(feedSync,'completed_at') : get(feedSync,'created_at');

  // load the feed top level data when property or integration selection changes
  useEffect(() => {
    dispatch(getBuildingFeedSync(property_id, feedSyncId))
      .catch(error => notify.error(format.error(error)));
  },[property_id, feedSyncId, dispatch, notify]);

  // declare the attributes to be displayed in the DataBar component
  const syncOverview = [
    {
      key:'date',
      label: 'Date',
      value: format.date(feedTimestamp),
    },
    {
      key:'time',
      label: 'Time',
      value: format.date(feedTimestamp,'time'),
    },
    {
      key:'initiator',
      label: 'Initiated by',
      value: (
        get(feedSync,'trigger_method') === 'automatic' ? (
          <StatusPill status='Automatic' className={CSS.pill} />
        ) : (
          format.fullname(get(feedSync,'initiated_by_user'))
        )
      ),
    },
    {
      key:'direction',
      label: 'Direction',
      value: (
        <div className={CSS.directionWrapper}>
          <MoveStepTypeIcon type={get(feedSync,'move_step_type.label')} size='24px' />
          { get(feedSync,'move_step_type.display_name')}
        </div>
      ),
    },
  ];

  const getRequestLanguage = (request) => {
    if(request.type === 'http') return 'json';
    return 'xml';
  };

  if(pending && !feedSync) return <LoaderOverlay/>;

  return (
    <div className={CSS.content}>

      <div className={CSS.header}>
        <div className={CSS.title}>
          <div className={CSS.back_btn} onClick={history.goBack}>
            <Icon symbol='Arrow-left' library='navigation' size='32px' />
          </div>
          <div className='displayS contentPrimary'>{ format.date(feedTimestamp,'MMMM Do, h:mma') }</div>
          <StatusPill status={isComplete ? 'complete' : 'pending'} className={CSS.feedStatus} />
        </div>
      </div>

      <DataBar className={CSS.dataBar} attributes={syncOverview} />

      { get(feedSync,'outbound_http_api_calls',[]).length === 0 && (
        <div className={CSS.response_wrapper}>
          <EmptyContent message='No request history available' className='mt-30'/>
        </div>
      )}

      { get(feedSync,'outbound_http_api_calls',[]).map((httpRequest,index) => (
        <div className={CSS.response_wrapper} key={index}>
          <div className={CSS.section_title}>
            <div className='labelL contentPrimary'>{ httpRequest.description }</div>
            <ClipboardWrapper text={httpRequest.response_body}>
              <Button
                text='Copy'
                size='small'
                color='tertiary'
                icon={{ library: 'general', symbol: 'Duplicate'}}
              />
            </ClipboardWrapper>
          </div>

          <CodeBlock
            code={httpRequest.response_body}
            language={getRequestLanguage(httpRequest)}
            showLineNumbers={true}
          />

        </div>
      ))}

    </div>
  );
};
