import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoaderOverlay } from '@moved/ui';
import { Four04 } from '@moved/product';

import { useBuildings } from '../../moves/actions/getBuildings';

export const BuildingRedirect = () => {
  const history = useHistory();
  const buildings = useBuildings();
  useEffect(() => {
    if(buildings.length === 0) return;
    history.replace(`/admin/calendars/buildings/${buildings.at(0)?.id}`);
  },[buildings, history]);

  return buildings.length === 0 ? <Four04 /> : <LoaderOverlay />
};
