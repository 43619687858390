import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { TabSlider, LoaderOverlay } from '@moved/ui';
import { format, useQuery, useNotify, useUser } from '@moved/services';

import { getProperty } from '../../../../properties/actions'
import { useProperty } from '../../../../properties/actions/selectors';
import { DefaultConfiguration } from '../../../components';

import { getBuildingCalendarOptions, updateKeyPickupByRoommate } from '../actions';
import { useBuildingCalendarOptions, useBuildingCalendarOptionsPending } from '../actions/selectors';

import { BuildingCalendar } from './BuildingCalendar';

import CSS from './styles/Configuration.module.scss';

export const Configuration = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { property_id } = useParams();
  const notify = useNotify();
  const history = useHistory();
  const location = useLocation();
  const view = useQuery('view') || 'general';
  const [savePending, setSavePending] = useState();

  // Update query string in URL when view is selected
  const setView = (view) => {
    return history.push({
      pathname: location.pathName,
      search: view ? `?view=${encodeURIComponent(view)}` : '',
    });
  };

  // REDUX
  const { hasAbilities } = useUser();

  const buildingCalendars = useBuildingCalendarOptions(property_id);
  const building = useProperty(property_id);
  const keyPickupByRoommateEnabled = get(building,'settings.key_pickup_by_roommate_enabled');
  const pending = useBuildingCalendarOptionsPending();

  useEffect(() => {
    dispatch(getBuildingCalendarOptions(property_id))
      .catch(err => notify.error(format.error(err)));
  },[]); // eslint-disable-line

  const _handleSubmit = data => {
    if(!(hasAbilities('ReadKeyPickupTasks') && hasAbilities('ConfigureKeyPickupTasks'))) return false;
    setSavePending(true);
    return dispatch(updateKeyPickupByRoommate(property_id, data))
      .then(() => dispatch(getProperty(property_id)))
      .catch(error => notify.error(format.error(error)))
      .finally(() => setSavePending(false));
  };

  if(!buildingCalendars) return <LoaderOverlay />;

  const getContent = (view) => {
    if (view === 'general') {
      return (
        <DefaultConfiguration
          task={task}
          header={false}
          typeFields={fields}
          typeSubmit={_handleSubmit}
          typePending={savePending}
        />
      );
    }

    return (<BuildingCalendar task={task} calendar_id={view}/>)
  }

  const buildingCalendarNames = buildingCalendars.map(
    building_calendar => ({
      label: building_calendar.name || 'Calendar',
      value: (building_calendar.id).toString()
    })
  );
  buildingCalendarNames.unshift(
    {
      label: 'General',
      value: 'general'
    }
  )

  const fields = [
    {
      label: 'Allow residents to pick up roommate\'s key',
      type: 'toggle',
      name: 'value',
      value: keyPickupByRoommateEnabled,
      disabled: !hasAbilities('ConfigureKeyPickupTasks'),
    }
  ].filter(v=>v);

  return (
    <>
      { pending && <LoaderOverlay /> }
      <div className={CSS.header}>
        <div className={CSS.title}>
          <h1>{get(task,'title')}</h1>
        </div>
      </div>

      <TabSlider
        className={CSS.slider}
        list={buildingCalendarNames}
        active={view}
        callback={view => setView(view)}
      />

      <div className={CSS.view}>
        {getContent(view)}
      </div>
    </>
  );
};
