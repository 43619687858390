import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { Card, Icon, Tooltip } from '@moved/ui';

import CSS from '../styles/MovesSearchResult.module.scss';

export const MovesSearchResult = ({ move, isActive, onClick }) => {
  return (
    <Card
      className={classNames('stackVertical gap-4', CSS.box, { [CSS.active]: isActive })}
      onClick={() => onClick(move)}
    >

      <div className='stackHorizontal gap-16 justify-between'>

          <div className={CSS.name}>{ move?.user?.fullname }</div>
          <div className={classNames(CSS.status,CSS[move?.status])}>{ move?.status }</div>

      </div>

      <div className='stackHorizontal gap-16 justify-between'>

        <div className={CSS.move_date}>
          <Tooltip
            tooltip={
              move?.move_date ? 'Requested move date' :
              move?.lease_start_date ? 'Lease start date' :
              'No dates available'
            }
          >
            <Icon library='code' symbol='Date' size='16px' className={CSS.icon}/>
          </Tooltip>
          {
            move?.move_date ? format.date(move?.move_date,'ddd M/D/YY') :
            move?.lease_start_date ? format.date(move?.lease_start_date,'ddd M/D/YY') :
            (<span className="italic">Unknown move date</span>)
          }
        </div>

        <div className={CSS.last_active_date}>
          <Tooltip tooltip='Last active at'>
            <Icon library='home' symbol='Clock' size='16px' className={CSS.icon}/>
          </Tooltip>
          { format.date(move?.last_active,'M/D/YY h:mma') }
        </div>

      </div>

    </Card>
  );
};
