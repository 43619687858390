import React from 'react';

import { useModal, useUser } from '@moved/services';
import { CancellationModal } from '../modals/CancellationModal';

import { Action } from './Action';
import { isOrderCancellable } from '../../helpers';

export const CancelOrder = ({ order, ...props }) => {
  const modal = useModal();
  const { hasAbilities } = useUser();

  // early exit
  if(!isOrderCancellable(order) || !hasAbilities('CancelServiceOrders')) return null;

  // set up confirmation modal
  const confirmCancellation = () => modal.openLegacy(<CancellationModal order={order} />);

  return <Action onClick={confirmCancellation} color='cancel' {...props}>Cancel</Action>;
};
