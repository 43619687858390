import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useModal, useUser } from '@moved/services';
import { Icon, ConfirmationModal, Popover } from '@moved/ui';
import { MoveStepTypeIcon } from '@moved/product';

import { cancelAppointment } from '../../actions/cancelAppointment';
import { RescheduleAppointment } from './RescheduleAppointment';

import CSS from './styles/CalendarAppointment.module.scss';

export const CalendarAppointment = ({ appointment, calendar, refreshCalendar }) => {
  const { Can, hasAbilities } = useUser();
  const dispatch = useDispatch();
  const modal = useModal();

  const cancel = () => dispatch(cancelAppointment(appointment.appointment_type.id, appointment.id))
    .then(refreshCalendar);
  const confirmCancel = () => modal.open(
    <ConfirmationModal
      title={'Cancel appointment'}
      copy={`Are you sure wish to cancel ${appointment.firstname} ${appointment.lastname}'s appointment on ${moment(appointment.start_time).format('MMMM DD, YYYY')}?`}
      confirmText={'Proceed with cancellation'}
      cancelText={'Never mind'}
      onConfirm={cancel}
    />,
  );

  const showAppointmentTypeIcon = (calendar.appointment_types ?? []).filter(type => !type.is_blocking).length > 1;
  const appointmentType = calendar.appointment_types.find(type => type.id === appointment.appointment_type.id);

  return (
    <div className={CSS.slot_appointment} key={appointment.id}>
      { hasAbilities('RescheduleBuildingAppointments') ? (
        <Popover placement='left'>
          <Popover.Trigger className={CSS.trigger}>
            <div className='stackHorizontal gap-4 items-center flexTruncateFix'>
              { showAppointmentTypeIcon && (
                <Icon {...appointmentType.icon} color={appointmentType.color} size='16px' />
              )}
              <span className='textTruncate'>{appointment.lastname}</span>
            </div>
            <span className={CSS.slot_unit}>
              <MoveStepTypeIcon type={appointment?.move_step_type?.label} size='16px' />
              {appointment.unit}
            </span>
          </Popover.Trigger>
          <Popover.Content mountToBody={true} className='stackVertical gap-12'>
            <Popover.Title>Reschedule Appointment</Popover.Title>
            <RescheduleAppointment
              appointment={appointment}
              calendar={calendar}
              refreshCalendar={refreshCalendar}
            />
          </Popover.Content>
        </Popover>
      ) : (
        <div className={CSS.trigger}>
          <div className='stackHorizontal gap-4 items-center flexTruncateFix'>
            { showAppointmentTypeIcon && (
              <Icon {...appointmentType.icon} color={appointmentType.color} size='16px' />
            )}
            <span className='textTruncate'>{appointment.lastname}</span>
          </div>
          <span className={CSS.slot_unit}>
            <MoveStepTypeIcon type={appointment?.move_step_type?.label} size='16px' />
            {appointment.unit}
          </span>
        </div>
      )}
      <Can I='CancelBuildingAppointments'>
        <span className={CSS.cancel_appointment} onClick={confirmCancel}>
          <Icon library='general' symbol='Trash' size='16px'  />
        </span>
      </Can>
    </div>
  );
};
