import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get, cloneDeep, noop } from 'lodash';

import { DynamicForm } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

import { TaskHeader} from './TaskHeader';

import { updateTaskDetails, } from '../actions';
import { useUpdateTaskDetailsPending } from '../actions/selectors';

import CSS from './styles/DefaultConfiguration.module.scss';

const _handleReturn = (state, history, property_id) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push(`/admin/properties/${property_id}`);
};

export const DefaultConfiguration = ({ task, typeFields = [], typeSubmit = noop, typePending = false, header = true, navigation, children }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const { hasAbilities, Can } = useUser();

 // Get id from URL
  const { task_id, property_id } = useParams();

  // REDUX
  const updatePending = useUpdateTaskDetailsPending();
  const pending = updatePending || typePending;

  const handleSubmit = ({ custom: typeData, ...data }) => {
    if(pending) return;
    let submitData = cloneDeep(data);
    delete submitData.custom;
    if(submitData.icon) submitData.icon = get(submitData.icon,'symbol');
    dispatch(updateTaskDetails(task_id, submitData))
      .then(() => typeSubmit(typeData))
      .then(() => { notify.default(`Task updated!`);})
      .catch(error => notify.error(format.error(error)));
  };

  const isReadOnly = !hasAbilities('UpdateBuildingTasks');

  let fields = [
    {
      label: 'Task details',
      type: 'title',
    },
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      value: task.title,
      required: true,
      readOnly: isReadOnly,
    },
    {
      label: 'Subtitle',
      type: 'text',
      name: 'subtitle',
      value: task.subtitle,
      required: true,
      readOnly: isReadOnly,
    },
    {
      label: 'Task icon',
      type: 'icon',
      name: 'icon',
      value: { symbol: get(task,'icon_name'), },
      required: true,
      readOnly: isReadOnly,
    },
    (
      [
        'coi',
        'document-submission',
        'insurance',
        'keys',
        'move-out-inspection',
        'orientation',
        'pay-move-in-costs',
        'pet-registration',
        'reserve',
        'utility-setup',
        'vehicle-registration',
      ].includes(task.task_type_name)
    ) && {
      label: 'Requires approval',
      type: 'toggle',
      name: 'requires_approval',
      value: task.requires_approval,
      disabled: isReadOnly,
    }
  ].filter(v=>v);

  let customFields = cloneDeep(typeFields);

  customFields.forEach(field => {
    field.name = ('custom.').concat(field.name)
  });

  fields = fields.concat(customFields);

  return (
    <>
      <div className={CSS.content_top}>

        { header && <TaskHeader task={task}/>}

        { navigation }

        <div className={CSS.form}>
          <DynamicForm
            id='task-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
          />
        </div>

        {children}

      </div>

      <div className={CSS.content_bottom}>
        <Can I="UpdateBuildingTasks">
          <div className={CSS.buttons}>
            <label
              id='task_form_button'
              htmlFor="task-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', CSS.save, {'loading': pending})}
              disabled={pending}
            >
              Save
            </label>

            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-ghost')}
              disabled={pending}
              onClick={e => {
                e.preventDefault();
                if (pending) return;
                return _handleReturn(location.state, history, property_id);
              }}
            >
              Discard changes
            </label>
          </div>
        </Can>
      </div>
    </>
  );
};
