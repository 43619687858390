import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_PENDING_REQUESTS',
  request: (buildingId) => request.get(`/admins/buildings/${buildingId}/pending-calendar-requests`)
    .then(r => r.data.data.building_calendar_requests),
  selector: (state, buildingId) => state?.buildingPendingRequests?.[buildingId] ?? [],
  reducers: {
    success: (state, { response, params: [buildingId] }) => ({
      ...state,
      buildingPendingRequests: {
        ...state.buildingPendingRequests,
        [buildingId]: response,
      },
    }),
  }
};

export const {
  action: getBuildingPendingRequests,
  reducer,
  useResource: useBuildingPendingRequests,
  usePending: useBuildingPendingRequestsPending,
} = generate.redux(creator);

export const initialState = {
  buildingPendingRequests: {},
};
