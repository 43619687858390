import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, Notebox } from '@moved/ui';

import { updateRate } from '../../actions';
import { useUpdateRatePending } from '../../actions/selectors';

import CSS from './UpdateRateModal.module.scss';

const validation = Yup.object().shape({
  rate_type: Yup.string()
    .required(),
  rate: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
  minimum: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number().required('Required'),
    }),
  crew_size: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(2, 'Minimum of 2')
        .max(8, 'Maximum of 8')
        .required('Required'),
    }),
  estimated_price: Yup.number().integer()
    .when('rate_type', {
      is: 'flat',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
});

export const UpdateRateModal = ({ order={} }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = useUpdateRatePending();
  // statefully keep track of price data to restore hidden values
  const [pricing, setPricing] = useState({
    rate_type: order.rate_type || 'hourly',
    rate: order.rate || 0,
    minimum: order.minimum || 0,
    crew_size: order.crew_size || 3,
    estimated_price: order.estimated_price || 0,
  });

  // When rate_type changes and hidden fields are shown again we need to
  // restore their previous values
  const updatePrices = ({ rate_type, ...data }, form) => {
    const newPrices = {
      rate_type: rate_type,
      rate: rate_type === 'hourly' ? data.rate : pricing.rate,
      minimum: rate_type === 'hourly' ? data.minimum : pricing.minimum,
      crew_size: rate_type === 'hourly' ? data.crew_size : pricing.crew_size,
      estimated_price: rate_type === 'flat' ? data.estimated_price : pricing.estimated_price,
    };
    setPricing(newPrices); // update the current state value
    if(rate_type !== pricing.rate_type) form.setValues(newPrices);
  };

  const fields = [
    {
      type: 'slideToggle',
      name: 'rate_type',
      value: pricing.rate_type,
      options: [
        {
          label: 'Hourly',
          value: 'hourly',
        },
        {
          label: 'Flat',
          value: 'flat',
        }
      ],
    },
    {
      label: 'Hourly rate ($)',
      type: 'currency',
      name: 'rate',
      value: pricing.rate,
      half: true,
      custom: {
        hidden: {
          compare: { field:'rate_type' },
          boolean: 'eq',
          value: 'flat',
        }
      }
    },
    {
      label: 'Minimum cost ($)',
      type: 'currency',
      name: 'minimum',
      value: pricing.minimum,
      half: true,
      custom: {
        hidden: {
          compare: { field:'rate_type' },
          boolean: 'eq',
          value: 'flat',
        }
      }
    },
    {
      label: 'Crew size',
      type: 'integer',
      name: 'crew_size',
      value: pricing.crew_size,
      half: true,
      custom: {
        hidden: {
          compare: { field:'rate_type' },
          boolean: 'eq',
          value: 'flat',
        }
      }
    },
    {
      label: 'Estimated Price',
      type: 'currency',
      name: 'estimated_price',
      value: pricing.estimated_price,
      half: true,
      custom: {
        hidden: {
          compare: { field:'rate_type' },
          boolean: 'eq',
          value: 'hourly',
        }
      }
    },
  ];

  const handleSubmit = ({ rate_type, ...prices }) => {
    const updatedData = { rate_type };
    if(rate_type === 'hourly') {
      updatedData.rate = prices.rate;
      updatedData.minimum = prices.minimum;
      updatedData.crew_size = prices.crew_size;
    }
    if(rate_type === 'flat') {
      updatedData.estimated_price = prices.estimated_price;
    }

    dispatch(updateRate(order.id, updatedData))
      .then(() => {
        notify.default(`Rate updated and notifications sent to ${get(order,'customer.contact_name')} and ${get(order,'vendor.name')}`);
        modal.close();
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Update Rate</h3>
    <Notebox
      heading='Note:'
      body={(<>
        <p className={'mb-5'}>
          This action assumes both customer and vendor have already agreed to
          updated pricing. Upon submission, a confirmation email will be
          immediately sent to both parties.
        </p>
        { order.payment_status === 'authorized' && (
          <p>
            The hold has already been placed on this customer's card. Updating the rate
            will not change the amount that has been pre-authorized.
          </p>
        )}
      </>)}
      color='blue'
      className={CSS.note_box}
    />
    <DynamicForm
      id='rate-form'
      formStyle='underline'
      className={CSS.form}
      onSubmit={handleSubmit}
      onChange={updatePrices}
      validation={validation}
      fields={fields}
    />
    <section className={classNames(CSS.actions)}>
      <span className={'btn-gray mr-15'} onClick={modal.close}>Cancel</span>
      <button className={classNames('btn-primary',{loading:pending})} type='submit' form='rate-form'>Update</button>
    </section>
  </>);

}
