import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  Form,
  FormText,
  Modal,
} from '@moved/ui';

import { inviteVendor, useInviteVendorPending } from '../actions/inviteVendor';

import CSS from './styles/CreateVendorModal.module.scss';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('required'),
  lastname: Yup.string().required('required'),
  email: Yup.string().email('invalid email').required('required'),
});

export const InviteVendorModal = ({ vendor }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const pending = useInviteVendorPending();

  const submit = (data) => {
    dispatch(inviteVendor(vendor.id, { ...data, send_invite: true }))
      .then(() => notify.default('Invitation sent.'))
      .then(updatedVendor => modal.close(updatedVendor))
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Modal>
      <Modal.Title>Invite an admin for {vendor.name}</Modal.Title>
      <Modal.Content className={CSS.content}>
        <Form
          name='inviteVendorForm'
          onSubmit={submit}
          validation={validationSchema}
          className='stackVertical gap-16'
        >
          <div className='stackHorizontal gap-24'>
            <FormText
              label='First name'
              name='firstname'
              className='width-half'
            />
            <FormText
              label='Last name'
              name='lastname'
              className='width-half'
            />
          </div>
          <FormText
            label='Email'
            name='email'
            type='email'
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
          disabled={pending}
        />
        <Button
          text='Invite'
          color='primary'
          type='submit'
          form='inviteVendorForm'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
};
