import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';

import { useNotify, useModal, format } from '@moved/services';
import { Form, FormTextarea, Table, Button, AtomSpinner } from '@moved/ui';

import { getOrientationContents, createOrientationProgressOverride } from '../actions';
import { useOrientationContents, useOrientationContentsPending } from '../actions/selectors';

import { ChapterPreviewModal } from './ChapterPreviewModal';

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const orientationContents = useOrientationContents(task.building_task_id) || [];
  const pending = useOrientationContentsPending();

  const previewChapter = (chapter) => {
    return modal.open(
      <ChapterPreviewModal chapter={chapter} title={`${chapter.title} Preview`}/>
    );
  };

  useEffect(() => {
    dispatch(getOrientationContents(task.building_task_id))
      .catch(err => notify.error(format.error(err)));
  },[task,dispatch,notify]);

  const submit = async (data) => {
    if(submitting) return;
    setSubmitting(true);
    dispatch(createOrientationProgressOverride(tenantEvent.id, task.building_task_id, data))
      .then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        notify.error(format.error(err));
      });
  };

  return (
    <Form
      name="artifact-form"
      onSubmit={submit}
      className='stackVertical gap-40'
    >
      <section className='stackVertical gap-12'>
        <div className='labelL contentPrimary'>
          Chapter list
        </div>
        { pending ? (
          <AtomSpinner/>
        ) : (
          <Table
            columns={[
              {
                name: 'chapter',
                getValue: (row) => (<div className='labelM contentPrimary'>{row.title}</div>),
                className: 'w-100',
              },
              {
                name: 'preview',
                getValue: (row) => (<Button text='Preview' size='small' color='tertiary' onClick={() => previewChapter(row)} />),
              },
            ]}
            rows={orientationContents.map(chapter => ({ data: chapter }))}
          />
        )}
      </section>
      <section className='stackVertical gap-12'>
        <FormTextarea
          name='notes'
          value=''
          label='Notes'
        />
      </section>
    </Form>
  );
};
