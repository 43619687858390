import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_CALENDAR_SUMMARIES',
  request: (id) => request.get(`/admins/buildings/${id}/building-calendar-summaries`)
    .then(r => r.data.data.building_calendar_summaries),
  selector: (state, id) => state?.buildingCalendarSummaries?.[id] ?? [],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      buildingCalendarSummaries: {
        ...state.buildingCalendarSummaries,
        [id]: response,
      },
    }),
  },
};

export const {
  action: getBuildingCalendarSummaries,
  reducer,
  useResource: useBuildingCalendarSummaries,
  usePending: useBuildingCalendarSummariesPending,
} = generate.redux(creator);

export const initialState = {
  buildingCalendarSummaries: {},
};
