import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_PROPERTIES_CREATE_TASK',
  request: (id,data={}) => request.post(`/admins/building-task-lists/${id}/building-tasks`,data).then(r => get(r,'data.data.building_task')),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          [response.id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateTaskPending,
} = generate.redux(creator);

export const initialState = {};
