import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format, useUser } from '@moved/services';
import { Button, AnimatedCheckmark, usePopover } from '@moved/ui';
import { MoveStepTypeIcon } from '@moved/product';

import { updateAppointmentRequest, useUpdateAppointmentRequestPending } from '../actions/updateAppointmentRequest';
import { getBuildingPendingRequests, getBuildingRepliedRequests } from '../actions';

import CSS from './styles/RequestDetails.module.scss';

export const RequestDetails = ({ request, building, refreshCalendar }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { Can } = useUser();
  const popover = usePopover(); // if used in a popover (most or all cases)

  const [submitted, setSubmitted] = useState(false);

  const pending = useUpdateAppointmentRequestPending();

  const updateRequest = ({ id, appointment_type }, action) => {
    dispatch(updateAppointmentRequest(appointment_type?.id, id, action))
      .then(() => {
        setSubmitted(true);
        refreshCalendar?.();
        dispatch(getBuildingPendingRequests(building.id));
        dispatch(getBuildingRepliedRequests(building.id));
        setTimeout(() => popover?.hide(), 650);
      })
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <div className={classNames('stackVertical gap-20',CSS.wrapper)}>
      { submitted && (
        <div className={CSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
        </div>
      )}

      <div className='stackVertical gap-4'>
        <div className='stackVertical'>
          <div className='stackHorizontal gap-12 justify-between items-center'>
            <div className='headingS contentPrimary'>{ format.fullname(request) }</div>
            <div className='stackHorizontal gap-4 items-center'>
              <MoveStepTypeIcon type={request?.move_step_type?.label} size='20px' />
              <span className='headingS contentPrimary'>{request.unit}</span>
            </div>
          </div>
          <div className='labelS contentSecondary'>
            { request?.appointment_type?.display_name }
          </div>
        </div>
        <div className='stackVertical'>
          <div className='labelM contentPrimary'>
            {[
              format.date(request.start_time,'MMM D, YYYY [at] h:mm'),
              format.date(request.end_time,'h:mma'),
            ].filter(Boolean).join(' - ')}
          </div>
          { request?.building_calendar_name && (
            <div className='labelS contentSecondary'>
              { request.building_calendar_name } Calendar
            </div>
          )}
        </div>
      </div>

      <div className='stackHorizontal gap-12 justify-end'>
        { request.status === 'pending-approval' && (
          <>
            <Can I='RejectBuildingAppointmentRequests'>
              <Button
                className='flex-auto'
                color='secondary'
                text='Decline'
                onClick={() => updateRequest(request,'reject')}
                disabled={pending}
              />
            </Can>
            <Can I='ApproveBuildingAppointmentRequests'>
              <Button
                className='flex-auto'
                color='primary'
                text='Accept'
                onClick={() => updateRequest(request,'approve')}
                disabled={pending}
              />
            </Can>
          </>
        )}
        { (request.status === 'rejected' || request.status === 'rejection-acknowledged') && (
          <Button
            size='small'
            text='Declined'
            icon={{ symbol: 'Close', library: 'navigation' }}
            color='secondary'
            disabled
          />
        )}
        { request.status === 'approved' && (
          <Button
            size='small'
            text='Accepted'
            icon={{ symbol: 'Done', library: 'code' }}
            color='primary'
            disabled
          />
        )}
      </div>
    </div>
  );
}
