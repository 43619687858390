import React from 'react';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';

import { ie11Support } from '@moved/services';

import { Root } from './core';

const darkModeActive = Cookies.get('darkMode') === 'true';
if(darkModeActive) document.body.classList.add('darkMode');

const root = createRoot(document.getElementById('app'));
root.render(<Root />);

ie11Support.svg();
