import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';
import { Four04 } from '@moved/product';

import { getBuildingCalendarSummaries, useBuildingCalendarSummaries } from '../actions/getBuildingCalendarSummaries';

export const Building = ({ children }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { buildingId } = useParams();
  const [initialized, setInitialized] = useState();
  const calendarList = useBuildingCalendarSummaries(buildingId) ?? [];

  useEffect(() => {
    dispatch(getBuildingCalendarSummaries(buildingId))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setInitialized(true));
  },[buildingId, dispatch, notify]);

  if(!initialized) return <LoaderOverlay />
  return calendarList?.length > 0 ? children : <Four04 />;

};
