import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useModal, useNotify, format, useUser } from '@moved/services';
import {
  AnimatedCheckmark,
  ConfirmationModal,
  Button,
  Form,
  FormSelect,
  FormTypeAhead,
  usePopover,
} from '@moved/ui';

import { createAppointment, useCreateAppointmentPending } from '../../actions/createAppointment';
import { rescheduleAppointment } from '../../actions/rescheduleAppointment';
import { getTenantEvents } from '../../actions/getTenantEvents';

import CSS from './styles/CreateAppointment.module.scss';

const validationSchema = Yup.object().shape({
  appointment_type_id: Yup.string().required('Required'),
  tenant_event_id: Yup.string().required('Required'),
});

export const CreateAppointment = ({ calendar, slot, date, refreshCalendar }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const popover = usePopover();
  const { hasAbilities } = useUser();

  const [submitted, setSubmitted] = useState(false);

  const pending = useCreateAppointmentPending();

  const [tenantEvents, setTenantEvents] = useState([]);
  const searchTenantEvents = useCallback(
    (keywords='') => dispatch(getTenantEvents(calendar.id, { keywords, filter: 'recent_and_upcoming'}))
      .then(tenantEvents => setTenantEvents(tenantEvents.map(tenantEvent => ({
        value: tenantEvent.id,
        label: `${format.fullname(tenantEvent.tenant)} - ${tenantEvent.move_step_type?.display_name} | ${tenantEvent.lease?.address?.unit}`,
      })))),
    [calendar, dispatch],
  );

  useEffect(() => {
    searchTenantEvents();
  }, [searchTenantEvents]);

  const appointmentTypes = (calendar?.appointment_types ?? []).filter(type => !type.is_blocking);
  const defaultAppointmentType = appointmentTypes.at(0);

  const handleSubmit = ({ appointment_type_id=defaultAppointmentType.id, tenant_event_id }) => {
    const data = {
      tenant_event_id,
      start_time: `${date}T${slot.start}`,
      building_calendar_id: calendar.id,
    };
    dispatch(createAppointment(appointment_type_id, data))
      .then(() => {
        setSubmitted(true);
        setTimeout(refreshCalendar, 650);
      })
      /* This large error handler ensures that a conflict response is handled
       * as a special edge case, offering the admin the option to reschedule
       * the conflicting appointment. */
      .catch(error => {
        const errors = format.error(error);
        if(error?.response?.status === 409) {
          const conflictingAppointmentId = error?.response?.data?.errors?.at?.(0)?.details?.id;
          return modal.open(
            hasAbilities('RescheduleBuildingAppointments') ? (
              <ConfirmationModal
                title={'This resident already has an appointment'}
                copy={`${errors.length > 0 && errors[0]}. If you proceed, the resident's other appointment will be canceled. Are you sure you wish to reschedule?`}
                cancelText={'Never mind'}
                confirmText={'Reschedule'}
                onConfirm={
                  () => dispatch(rescheduleAppointment(appointment_type_id, conflictingAppointmentId, { start_time: `${date}T${slot.start}` }))
                    .then(() => {
                      setSubmitted(true);
                      setTimeout(refreshCalendar, 650);
                    })
                }
              />
            ) : (
              <ConfirmationModal
                title={'This resident already has an appointment'}
                cancelText={false}
                copy={`${errors.length > 0 && errors[0]}. You may not reschedule their appointment.`}
              />
            ),
          );
        } else {
          return notify.error(errors);
        }
      });
  };


  return (
    <div className={classNames('stackVertical gap-20',CSS.wrapper)}>
      { submitted && (
        <div className={CSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
        </div>
      )}

      <Form
        name='appointment-form'
        onSubmit={handleSubmit}
        validation={validationSchema}
        className='stackVertical gap-12'
        initialValues={{ appointment_type_id: defaultAppointmentType?.id }}
      >
        { appointmentTypes.length > 1 && (
          <FormSelect
            name='appointment_type_id'
            label='Appointment type'
            value={defaultAppointmentType?.id}
            options={appointmentTypes.map(type => ({
              value: type.id,
              label: type.display_name
            }))}
          />
        )}
        <FormTypeAhead
          name='tenant_event_id'
          label='Full name'
          onSearch={searchTenantEvents}
          value=''
          options={tenantEvents}
        />
      </Form>

      <div className='stackHorizontal gap-12'>
        <Button
          className='flex-auto'
          color='secondary'
          text='Cancel'
          onClick={() => popover.hide?.()}
          disabled={pending}
        />
        <Button
          className='flex-auto'
          color='primary'
          text='Create'
          type='submit'
          form='appointment-form'
          disabled={pending}
        />
      </div>
    </div>
  );
}
