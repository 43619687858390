import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useModal, useNotify, format } from '@moved/services';
import { Button, Modal, Notebox } from '@moved/ui';
import { MovingStopList } from '@moved/product';

import { updateOrderDetails } from '../../actions';
import { useUpdateOrderDetailsPending } from '../../actions/selectors';

import CSS from './UpdateOrderDetailsModal.module.scss';

export const UpdateOrderDetailsModal = ({ order={} }) => {

  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = useUpdateOrderDetailsPending();

  const [stops, setStops] = useState(order?.mover_booking?.stops ?? []);

  const submit = () => {
    if(pending) return;
    dispatch(updateOrderDetails(order?.id, order?.mover_booking?.id, { stops }))
      .then(() => {
        const notificationList = [order?.customer?.contact_name, order?.vendor?.name].filter(Boolean).join(' and ');
        notify.default(`Details updated and notifications sent to ${notificationList}`);
        modal.close();
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Modal className={CSS.container}>
      <Modal.Title>Update Stops</Modal.Title>
      <Modal.Content>
        <Notebox
          heading='Note:'
          body={(
            <p className={'mb-5'}>
              This action assumes both customer and vendor have already agreed to
              updated addresses. Upon submission, a confirmation email will be
              immediately sent to both parties (if applicable).
            </p>
          )}
          color='blue'
          className={CSS.note_box}
        />

        <MovingStopList
          initialStops={stops}
          onChange={setStops}
        />

      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={modal.close}
          disabled={pending}
        />
        <Button
          text='Update'
          onClick={submit}
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
}
