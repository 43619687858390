import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_RESCHEDULE_RESERVATION',
  request: (id, calendar_id, data={}) => request.post(`/partners/building-reservations/${id}/reschedule`, data)
    .then(r => r.data.data.appointment),
  reducers: {
    success: (state, { response: appointment, params: [id, calendar_id] }) => {
      return {
        ...state,
        buildingCalendarWeeks: {
          ...state.buildingCalendarWeeks,
          [calendar_id]: {
            ...state.buildingCalendarWeeks[calendar_id],
            appointments: [
              ...(state.buildingCalendarWeeks[calendar_id]?.appointments ?? [])
                .filter(appointment => appointment.id !== id),
              appointment,
            ],
          },
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useRescheduleReservationPending,
} = generate.redux(creator);

export const initialState = {};
