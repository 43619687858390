import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Icon, LoaderOverlay } from '@moved/ui';

import { getOrder } from '../actions';
import { SendJobOffer, CancelOrder, AddPayment, ResolvePaymentIssue } from './buttons';
import { useOrder, useOrderPending } from '../actions/selectors';
import { useMoveDetails } from '../../moves/actions/selectors';

import { OrderSummaryBar } from './OrderSummaryBar';
import { OrderStatusPills } from './OrderStatusPills';

import {
  DetailsSection,
  QuoteSection,
  VendorSection,
  CouponSection,
  DiscountSection,
  PaymentSection,
} from './sections';


import CSS from '../styles/OrderDetails.module.scss';

export const OrderDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderId, moveId } = useParams();
  const move = useMoveDetails(moveId);
  const order = useOrder(orderId);
  const pending = useOrderPending();

  useEffect(() => {
    dispatch(getOrder(orderId));
  },[orderId, dispatch]);

  if(pending) return <LoaderOverlay/>;
  if(!order) return null;

  return (
    <div className={CSS.wrapper}>

      <div
        onClick={() => history.push({
          pathname: `/cx/moves/${moveId}`,
          search: history.location.search,
        })}
        className={CSS.back_link}
      >
        <Icon symbol='Arrow-left' library='navigation' size='18px' />
        <span>Back to move</span>
      </div>

      <div className={CSS.content}>

        <div className={CSS.title}>
          <div className={CSS.title_top}>
            <div className={CSS.user_info}>
              <span className={CSS.user_name}>{ get(order, 'customer.contact_name') }</span>
            </div>
          </div>

          <div className={CSS.title_bottom}>
            <div className={CSS.order_date}>
              <h1>{format.date(order.service_date,'date')}</h1>
              <OrderStatusPills order={order} />
            </div>
            <div className={CSS.title_actions}>

              <SendJobOffer order={order} color='gray' />
              <AddPayment order={order} color='gray' />
              <ResolvePaymentIssue order={order} color='gray' />
              <CancelOrder order={order} />

            </div>
          </div>
        </div>

        <div className={CSS.summary}>
          <OrderSummaryBar summary={order.summary}/>
        </div>

        <div className={CSS.sections}>

          <DetailsSection order={order} key={'details-section'} />
          <QuoteSection order={order} key={'quote-section'} />
          <VendorSection order={order} key={'vendor-section'} />
          <CouponSection order={order} move={move} key={'coupon-section'} />
          <DiscountSection order={order} key={'discount-section'} />
          <PaymentSection order={order} key={'payment-section'} />

        </div>

      </div>

    </div>
  );
};
