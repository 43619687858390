import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get, forEach, cloneDeep } from 'lodash';
import classNames from 'classnames';


import { Icon, LoaderOverlay, DragDropList, PopOver } from '@moved/ui';
import { useNotify, format, useUser } from '@moved/services';

import { getProperty, reorderPropertyTasks } from '../actions';
import { useProperty, useReorderPropertyTasksPending, } from '../actions/selectors';
import { updateTaskDetails } from '../../tasks/actions/';
import { useUpdateTaskDetailsPending } from '../../tasks/actions/selectors';
import { CreateTaskButton } from './CreateTaskButton';

import CSS from './styles/PropertyTasks.module.scss';


const PropertyOptionsMenu = ({ task }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { property_id } = useParams();

  const pathname = location.pathname.split('/');

  const pending = useUpdateTaskDetailsPending();

  const _toggleHide = task => {
    if(pending) return false;
    return dispatch(updateTaskDetails(task.id, { create_on_signup: !task.create_on_signup }))
      .then(() => dispatch(getProperty(property_id)))
      .catch(error => notify.error(format.error(error)));
  }

  return (
    <div id={'popover_more_options'}>
      <div
        className={CSS.popover_action}
        onClick={e => {
          e.preventDefault();
          return history.push(`${pathname.slice(0, 4).join('/')}/tasks/${task.id}`);
        }}
      >
        <Icon symbol={'Edit'} library={'design'} size={'20px'} className={CSS.popover_icon} />
        <span className={CSS.popover_link}>Edit</span>
      </div>
      <div
        className={CSS.popover_action}
        onClick={e => {
          e.preventDefault();
          return _toggleHide(task);
        }}
      >
        {task.create_on_signup
          ? (
            <>
              <Icon symbol={'Hidden'} library={'general'} size={'20px'} className={CSS.popover_icon} />
              <span className={CSS.popover_link}>Hide</span>
            </>
          ) : (
          <>
            <Icon symbol={'Visible'} library={'general'} size={'20px'} className={classNames(CSS.popover_icon, CSS.visible)} />
            <span className={CSS.popover_link}>Show</span>
          </>
          )
        }
      </div>
    </div>
  );
};

export const PropertyTasks = () => {
  // HOOKS
  const dispatch = useDispatch();
  const notify = useNotify();
  const history = useHistory();
  const location = useLocation();
  const { hasAbilities, Can } = useUser();

  const pathname = location.pathname.split('/');

  // Get move id from URL
  const { property_id, taskListID } = useParams();

  // STATE
  const property = useProperty(property_id);

  // REDUX
  const reorderPending = useReorderPropertyTasksPending();

  const tasks = property.building_task_lists.find(list => list.id === parseInt(taskListID)).building_tasks;
  const [currentTasks, setCurrentTasks] = useState(cloneDeep(tasks));

  // Reorder tasks after drag
  const _onChange = result => {
    if(!result) return null;

    const updatedTasks = cloneDeep(result);
    forEach(updatedTasks, (values, bucket) => {
      updatedTasks[bucket] = updatedTasks[bucket].list
    });
    // set currentTasks which will hit the server off the useEffect
    return setCurrentTasks(updatedTasks);
  };

  useEffect(() => {
    setCurrentTasks(property.building_task_lists.find(list => list.id === parseInt(taskListID)).building_tasks);
  },[JSON.stringify(property.building_task_lists), taskListID]); // eslint-disable-line

  useEffect(() => {
    // Don't hit server if currentTasks is the same as tasks
    if (
      JSON.stringify(tasks.required.map(task => task.id)) !== JSON.stringify(currentTasks.required.map(task => task.id)) ||
      JSON.stringify(tasks.recommended.map(task => task.id)) !== JSON.stringify(currentTasks.recommended.map(task => task.id))
    ) {
      const submitTasks = {
        required_task_ids: currentTasks.required.map(task => task.id),
        recommended_task_ids: currentTasks.recommended.map(task => task.id),
      };

      dispatch(reorderPropertyTasks(taskListID, submitTasks))
        .catch(error => {
          setCurrentTasks(tasks);
          return notify.error(format.error(error));
        });
    }
  },[currentTasks]); // eslint-disable-line

  const _renderItemTitle = task => (
    <div
      className={classNames(CSS.title,{[CSS.link]:hasAbilities('UpdateBuildingTasks')})}
      onClick={() => hasAbilities('UpdateBuildingTasks') && history.push(`${pathname.slice(0, 4).join('/')}/tasks/${task.id}`)}
    >
      <Icon symbol={task.icon_name} size='24px' />
      <h4>{ task.title }</h4>
    </div>
  );
  const _renderItemIcons = task => (
    <div className={CSS.right}>
      {!task.create_on_signup && (
      <div className={CSS.pill}>
        Hidden
      </div>
      )}
      <div className={CSS.icons}>
       { hasAbilities('UpdateBuildingTasks') && (
          <PopOver
            id={`more_${task.id}`}
            customClass={CSS.task_popover}
            className={classNames(CSS.icon,CSS.icon_more)}
            hideArrow="true"
            placement="bottom-end"
            trigger="click"
            delayHide={0}
            delayShow={0}
            tooltip={(
              <PropertyOptionsMenu task={task} />
            )}
            stopPropagation
          >
            {(tooltipShown) => (
              <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
            )}
          </PopOver>
        )}
      </div>
    </div>
  );

  if(!property.building_task_lists) return (<LoaderOverlay />);

  return (
    <>
      {reorderPending && (<LoaderOverlay />)}

      {(currentTasks.required.length > 0 || currentTasks.recommended.length > 0) ? (
        <div className={CSS.main}>
          <DragDropList
            lists={{
              required: {
                title: (
                  <h3 className={CSS.section_title}>
                    <span>Required tasks</span>
                    <span className={CSS.task_count}>{get(currentTasks,'required.length')}</span>
                  </h3>
                ),
                actions: (
                  <Can I='CreateBuildingTasks'>
                    <CreateTaskButton taskListID={taskListID} />
                  </Can>
                ),
                list: currentTasks.required,
                contentLeft: _renderItemTitle,
                contentRight: _renderItemIcons,
                className: (item) => item.create_on_signup ? null : CSS.hidden,
              },
              recommended: {
                title: (
                  <h3 className={CSS.section_title}>
                    <span>Recommended tasks</span>
                    <span className={CSS.task_count}>{get(currentTasks,'recommended.length')}</span>
                  </h3>
                ),
                list: currentTasks.recommended,
                contentLeft: _renderItemTitle,
                contentRight: _renderItemIcons,
                className: (item) => item.create_on_signup ? null : CSS.hidden,
              }
            }}
            onChange={_onChange}
            isDisabled={!hasAbilities('UpdateBuildingTaskLists')}
          />
        </div>
      ) : (
        <div className={CSS.no_task}>
          <Icon className={CSS.warning} symbol={'Warning-1-circle'} library={'code'} size={'48px'} />
          <h3>No tasks configured for this move step!</h3>
        </div>
      )}

    </>
  );
};
