import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_PROPERTIES_UPDATE_PROPERTIES',
  request: (id,data={}) => request.patch(`/admins/buildings/${id}`,data).then(r => get(r,'data.data.building')),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        properties: {
          ...state.properties,
          [response.id]: response,
        },
      };
    }
  }
};


export const {
  action,
  reducer,
  usePending: useUpdatePropertyPending,
} = generate.redux(creator);
