import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'MOVES_CREATE_EXTERNAL_USER',
  request: (data={}) => request.post(`/cx/users`, data).then(r => get(r,'data.data.user')),
};

export const {
  action,
  usePending: useCreateExternalUserPending,
} = generate.redux(creator);

export const initialState = {};
