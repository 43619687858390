import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_CLIENTS_UPDATE_CLIENT',
  request: (id,data={}) => request.patch(`/admins/partners/${id}`,data).then(r => get(r,'data.data.partner')),
  reducers: {
    success: (state, { response }) => ({
      ...state,
      clients: {
        ...state.clients,
        [response.id]: response,
      },
    }),
  }
};


export const {
  action,
  reducer,
  usePending: useUpdateClientPending,
} = generate.redux(creator);
