import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';
import { get } from 'lodash';

import { useModal, useNotify, format } from '@moved/services';
import { StyledForm, DynamicField, RenderFields, AtomSpinner, CardSetupField } from '@moved/ui';

import { bookMove } from '../../actions';
import { getVendorList } from '../../../orders/actions';
import { useVendorList, useVendorListPending } from '../../../orders/actions/selectors';

import CSS from '../../styles/BookMoveModal.module.scss';

const validation = Yup.object().shape({
  vendor_id: Yup.number().integer()
    .required('Must choose a vendor from the list'),
  rate_type: Yup.string()
    .required(),
  rate: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
  minimum: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number().required('Required'),
    }),
  crew_size: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(2, 'Minimum of 2')
        .max(8, 'Maximum of 8')
        .required('Required'),
    }),
  estimated_price: Yup.number().integer()
    .when('rate_type', {
      is: 'flat',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
  contact_phone: Yup.string().required('Required'),
  nameOnCard: Yup.string().required('Required'),
});

export const BookMoveModal = ({ move={}, moverBooking={} }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { all: vendorList } = useVendorList();
  const vendorListPending = useVendorListPending();
  const [pending, setPending] = useState(false);
  const stripeCard = useRef(); // to be assigned to CardSetupField component

  const selectedRate = get(moverBooking, 'selected_partner_rate', {}) || {};

  const [order, setOrder] = useState({
    vendor_id: '',
    rate_type: 'hourly',
    rate: selectedRate.hourly_rate || 0,
    minimum: selectedRate.minimum || 0,
    crew_size: selectedRate.crew_size || 3,
    estimated_price: 0,
    contact_phone: get(moverBooking,'contact_phone') || '',
    nameOnCard: '',
  });

  useEffect(() => {
    dispatch(getVendorList()).catch(error => notify.error(format.error(error)));
  },[]); // eslint-disable-line

  if(!vendorList || vendorListPending) return <AtomSpinner/>;

  const vendorField = {
    type: 'select',
    name: 'vendor_id',
    label: 'Choose Vendor',
    placeholder: 'Start typing...',
    value: order.vendor_id,
    options: vendorList.map(vendor => ({label: vendor.name, value: vendor.id})),
  };

  const fields = [
    {
      type: 'slideToggle',
      name: 'rate_type',
      value: order.rate_type,
      options: [
        {
          label: 'Hourly',
          value: 'hourly',
        },
        {
          label: 'Flat',
          value: 'flat',
        }
      ],
    },
    order.rate_type === 'hourly' && {
      label: 'Hourly rate ($)',
      type: 'currency',
      name: 'rate',
      value: order.rate,
      half: true,
    },
    order.rate_type === 'hourly' && {
      label: 'Minimum cost ($)',
      type: 'currency',
      name: 'minimum',
      value: order.minimum,
      half: true,
    },
    order.rate_type === 'hourly' && {
      label: 'Crew size',
      type: 'integer',
      name: 'crew_size',
      value: order.crew_size,
      half: true,
    },
    order.rate_type === 'flat' && {
      label: 'Estimated Price',
      type: 'currency',
      name: 'estimated_price',
      value: order.estimated_price,
      half: true,
    },
    {
      label: 'Phone Number',
      type:'tel',
      name:'contact_phone',
      value: order.contact_phone,
    },
  ].filter(v => v);

  const handleSubmit = ({ vendor_id, rate_type, nameOnCard, contact_phone, ...prices }) => {
    // first do stripe
    if(pending || !stripeCard.current) return;
    setPending(true);

    const data = { vendor_id, rate_type, contact_phone };
    if(rate_type === 'hourly') {
      data.rate = prices.rate;
      data.minimum = prices.minimum;
      data.crew_size = prices.crew_size;
    }
    if(rate_type === 'flat') {
      data.estimated_price = prices.estimated_price;
    }

    stripeCard.current.confirmCard()
      .then(({setupIntent}) => {
        if(!setupIntent) return setPending(false); // stripe input card validation error
        data.stripe_payment_method_id = setupIntent.payment_method;
        return dispatch(bookMove(moverBooking.id, data))
          .then(() => {
            notify.default(`Order created and job offer sent to ${vendorList.find(vendor => vendor.id === vendor_id).name}`);
            modal.close();
          })
          .catch(error => {
            setPending(false);
            notify.error(format.error(error))
          });
      })
      .catch(error => {
        setPending(false);
        notify.error(
          format.error(error,false,format.stripeError), // map stripe errors
          { autoHideDuration:6000 }, // slightly longer than normal display times
        );
      });


  };

  return (<>
    <h3 className={CSS.title}>Book a move</h3>
    <StyledForm
      id='order-form'
      formStyle='underline'
      onSubmit={handleSubmit}
      onChange={setOrder}
      initialValues={order}
      validation={validation}
    >
      {(formik) => (
      <>
        <section className={classNames(CSS.section)}>
          <DynamicField input={vendorField} form={formik} formStyle='underline' />
        </section>

        <section className={classNames(CSS.section)}>
          <RenderFields fields={fields} form={formik} />
          <CardSetupField form={formik} ref={stripeCard} />
        </section>

        <section className={classNames(CSS.actions)}>
          <span className={'btn-gray mr-15'} onClick={modal.close}>Cancel</span>
          <button className={classNames('btn-primary',{loading:pending})} type='submit' form='order-form'>Send</button>
        </section>
      </>
      )}
    </StyledForm>
  </>);

}
