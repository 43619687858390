import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames'


import { AtomSpinner, Icon, PopOver } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { getEligibleTaskTypes, createTask } from '../actions';
import { useEligibleTaskTypes, useEligibleTaskTypesPending } from '../actions/selectors';

import CSS from './styles/CreateTaskButton.module.scss';


export const CreateTaskButton = ({ taskListID }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const eligible = useEligibleTaskTypes(taskListID);
  const eligiblePending  = useEligibleTaskTypesPending();

  useEffect(() => {
    dispatch(getEligibleTaskTypes(taskListID))
      .catch(error => notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);

  const createBuildingTask = (taskListID,taskTypeID) => {
    dispatch(createTask(taskListID, { 'task_type_id': taskTypeID }))
      .then(task => history.push(`${pathname.slice(0, 4).join('/')}/tasks/${task.id}`))
      .catch(error => notify.error(format.error(error)));
  };

  if (eligible && eligible.length === 0) return null;

  return (
    <PopOver
      id={`create_task`}
      customClass={CSS.create_popover}
      className={CSS.create}
      hideArrow="true"
      placement="bottom-end"
      trigger="click"
      delayHide={300}
      tooltip={(
        <div id={'popover_create_task'}>
          {eligiblePending
            ? <AtomSpinner size={28} />
            : eligible.map(taskType => (
            <div
              key={`create_task_taskType.id`}
              className={classNames(CSS.popover_action, CSS.document)}
              onClick={e => createBuildingTask(taskListID,taskType.id)}
            >
              <Icon symbol={taskType.icon} size={'20px'} className={CSS.popover_icon} />
              <span className={CSS.popover_link}>{taskType.display_name}</span>
            </div>
          ))}
        </div>
      )}
      stopPropagation
    >
      {(tooltipShown) => (
        <>
          <Icon symbol="Plus" library="code" size="20px"/>
          Create task
        </>
      )}
    </PopOver>
  );
}
