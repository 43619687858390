import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, useUser, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';
import { Four04 } from '@moved/product';

import { useClient } from '../../../../common/actions/selectors';
import { getBuildings, useBuildings } from '../../moves/actions/getBuildings';

export const BuildingSummaries = ({ children }) => {
  const { getDomainId } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState();
  const client = useClient(getDomainId('client'));
  const buildings = useBuildings();

  useEffect(() => {
    if(client) dispatch(getBuildings({ limit: 500 }))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setInitialized(true));
  },[client]); // eslint-disable-line

  if(!initialized) return <LoaderOverlay />
  return buildings ? children : <Four04 />;

};

export const withBuildingSumaries = (Wrapped) => (props) => (<BuildingSummaries><Wrapped {...props}/></BuildingSummaries>);
