import { useEffect, useCallback, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';

import { useScroller, useQuery, format, useModal, useUser } from '@moved/services';
import { Pagination, Icon, KeywordPill, EmptyContent, Card, Button, LoaderOverlay } from '@moved/ui';

import { vendorSearch, useVendorSearch, useVendorSearchPending } from '../actions/vendorSearch';

import { CreateVendorModal } from './CreateVendorModal';

import CSS from './styles/VendorSearch.module.scss';

// Give the search a context provider so that children can refresh the search
// when a vendor details is updated.
const VendorSearchContext = createContext();
export const useVendorSearchContext = () => useContext(VendorSearchContext);

const RESULTS_PER_PAGE = 20;

export const VendorSearch = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const scroller = useScroller();
  const modal = useModal();
  const { Can, proxyAs } = useUser();
  const page = parseInt(useQuery('page') ?? 1);
  const search = useQuery('search') ?? '';
  const pending = useVendorSearchPending();

  const {
    vendors,
    pagination,
  } = useVendorSearch();

  const runVendorSearch = useCallback((page, search) => {
    dispatch(vendorSearch({ page, name:search, limit:RESULTS_PER_PAGE }));
  },[dispatch]);

  useEffect(() => {
    runVendorSearch(page, search);
  },[page, search, runVendorSearch]);

  const updateQuery = ({ newPage=page, newSearch=search }) => {
    scroller.ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return history.replace({
      pathname: history.location.pathName,
      search: [
        `page=${newPage}`,
        newSearch?.length > 0 && `search=${encodeURIComponent(newSearch)}`,
      ].filter(Boolean).join('&'),
    });
  };

  const updateSearchDebounced = useDebouncedCallback(
    (value) => updateQuery({ newPage: 1, newSearch: value }),
    500,
  );

  const addVendorModal = () => modal.open(
    <CreateVendorModal />,
    {
      onClose: (vendor) => {
        vendor && history.push({
          pathname: `/cx/vendors/${vendor.id}`,
          search: `page=1&search=${vendor?.name}`,
        });
      },
    }
  );

  const proxyAsVendor = (id) => proxyAs(id,`${process.env.REACT_APP_VENDOR_APP_URL}/calendar`);

  return (
    <div className={classNames('stackVertical gap-20',CSS.content)} ref={scroller.ref}>
      <Helmet>
        <title>{`Vendor Admin : Moved`}</title>
      </Helmet>

      { pending && (<LoaderOverlay />)}

      <div className='headingL contentPrimary'>Vendors</div>

      <div className='dividerTertiary' />

      <div className='stackHorizontal gap-12 justify-between'>
        <KeywordPill
          label='Search'
          active={search}
          onSelect={(value,isReset) => isReset ? updateQuery({ newPage: 1, newSearch: value }) : updateSearchDebounced(value)}
        />
      </div>

      <div className='dividerTertiary' />

      <div className='stackHorizontal justify-between'>
        <div className='headingS contentPrimary stackHorizontal gap-4'>
          <span>Results</span>
          { search && (<span>for &ldquo;<span className='contentSecondary'>{search}</span>&rdquo;</span>)}
          <span className='contentAccent marginLeft-8'>{pagination?.total_items}</span>
        </div>
        <Button
          text='Add vendor'
          icon={{ library: 'navigation', symbol: 'Plus' }}
          size='small'
          color='secondary'
          onClick={() => addVendorModal()}
        />
      </div>

      <div className='stackVertical gap-16'>

        <div className='stackHorizontal gap-20 paddingLeft-24 paddingRight-24 labelS contentSecondary'>
          <div className={CSS.name}>Vendor name</div>
          <div className={CSS.commission}>Commission</div>
          <div className={CSS.account}>Moved account</div>
          <div className={CSS.stripe}>Stripe enabled</div>
          <div className={CSS.createdAt}>Added on</div>
          <div className={CSS.actions}>&nbsp;</div>
          <div className={CSS.arrow}>&nbsp;</div>
        </div>

        { vendors?.length ? vendors.map(vendor => (
          <Card
            key={vendor.id}
            onClick={() => history.push({
              pathname:`/cx/vendors/${vendor.id}`,
              search:history.location.search
            })}
            className={classNames('stackHorizontal gap-20 items-center',CSS.vendorRow)}
          >
            <div className={CSS.name}>{vendor.name}</div>
            <div className={CSS.commission}>{String(vendor.commission)}</div>
            <div className={CSS.account}>
              { vendor.user ? (
                <Icon library='code' symbol='Done-circle' size='24px' color='green' />
              ): (
                <Icon library='navigation' symbol='Minus' size='24px' color='gray' />
              )}
            </div>
            <div className={CSS.stripe}>
              { vendor.has_stripe ? (
                <Icon library='code' symbol='Done-circle' size='24px' color='green' />
              ): (
                <Icon library='navigation' symbol='Minus' size='24px' color='gray' />
              )}
            </div>
            <div className={CSS.createdAt}>{format.date(vendor.created_at)}</div>
            <div className={CSS.actions}>
              { vendor.user && (
                <Can I={'ProxyAsUsers'}>
                  <Button
                    text='Calendar'
                    size='small'
                    color='tertiary'
                    onClick={(e) => {
                      e.stopPropagation();
                      proxyAsVendor(vendor.user.id);
                    }}
                    icon={{ library: 'code', symbol: 'Date' }}
                  />
                </Can>
              )}
            </div>
            <div className={CSS.arrow}>
              <Icon symbol='Chevron-right' library='navigation' />
            </div>
          </Card>
        )) : (
          <EmptyContent
            className={'padding-64'}
            message='No vendors match your search criteria!'
            iconSize='50px'
          />
        )}
      </div>

      {/* Page nav */}
      { pagination?.total_pages > 1 && (
        <Pagination
          page={page}
          pageCount={pagination?.total_pages}
          onPageChange={(newPage) => updateQuery({ newPage })}
        />
      )}

      {/* used to load the component that handles the drawer state for vendor details */}
      <VendorSearchContext.Provider value={{ refresh: () => runVendorSearch(page, search) }}>
        { children }
      </VendorSearchContext.Provider>

    </div>
  );

};
