import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { format, useUser } from '@moved/services';

import { OrderStatusPills } from './OrderStatusPills';
import { OrderSummaryBar } from './OrderSummaryBar';

import CSS from '../styles/OrderList.module.scss';

const OrderPreview = ({ summary, move }) => {
  const location = useLocation();
  const { hasAbilities } = useUser();

  // early exit
  if(!hasAbilities('ReadServiceOrders')) return null;

  return (
    <div className={CSS.order}>

      <div className={classNames(CSS.row,CSS.top)}>
        <div className={CSS.title}>
          <h3>
            <span className={CSS.order_date}>{format.date(summary.service_date,'date')}</span>
            <OrderStatusPills order={summary}/>
          </h3>
        </div>
        <div className={CSS.actions}>
          <Link to={{
            pathname:`/cx/moves/${move.id}/orders/${summary.id}`,
            search: location.search,
          }}>
            <div className={CSS.view_btn}>
              <Icon symbol={'Expand-arrows'} library={'general'} size={'16px'} />
              <span>Expand View</span>
            </div>
          </Link>
        </div>
      </div>

      <div className={classNames(CSS.row,CSS.bottom)}>
        <OrderSummaryBar summary={summary} />
      </div>

    </div>
  );
}

export const OrderList = ({ move }) => {
  const { service_order_summaries = [] } = move;
  if(!service_order_summaries.length) return null;
  return (
    <div className={CSS.orders}>
      { service_order_summaries.map(summary => (
        <OrderPreview summary={summary} move={move} key={summary.id}/>
      ))}
    </div>
  );
};
