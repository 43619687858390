import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useNotify, format } from '@moved/services';
import {
  Button,
  AnimatedCheckmark,
  Form,
  FormText,
  FormDateSelect,
  FormSelect,
  usePopover,
} from '@moved/ui';

import {
  getBuildingCalendarWeek,
  useBuildingCalendarWeek,
  useBuildingCalendarWeekPending
} from '../../actions/getBuildingCalendarWeek';
import {
  rescheduleAppointment,
  useRescheduleAppointmentPending
} from '../../actions/rescheduleAppointment';

import CSS from './styles/RescheduleAppointment.module.scss';

const validationSchema = Yup.object().shape({
  start_time: Yup.string().required('Required'),
});

export const RescheduleAppointment = ({ appointment, calendar, refreshCalendar }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const popover = usePopover();
  const pending = useRescheduleAppointmentPending();
  const buildingCalendarWeekPending = useBuildingCalendarWeekPending();
  const [activeDate, setActiveDate] = useState(
    moment.max(
      moment(appointment.start_time).startOf('day'),
      moment().startOf('day')
    ).format('YYYY-MM-DD')
  );
  const startOfWeek = moment(activeDate).startOf('week').format('YYYY-MM-DD');

  const [submitted, setSubmitted] = useState(false);

  const { availability=[] } = useBuildingCalendarWeek(calendar.id, startOfWeek) ?? {};
  const calendarDay = availability.find(day => moment(day.date).isSame(activeDate,'day'));
  const timeslotOptions = calendarDay?.timeslots
    .filter(timeslot => timeslot.is_available)
    .map(timeslot => ({
      label: [
        moment(`${activeDate}T${timeslot.start}`).format('h:mma'),
        moment(`${activeDate}T${timeslot.end}`).format('h:mma'),
      ].join(' - '),
      value: `${activeDate}T${timeslot.start}`,
    })) ?? [];

  const handleSubmit = ({ start_time }) => {
    return dispatch(rescheduleAppointment(appointment.appointment_type.id, appointment.id, { start_time }))
      .then(() => {
        setSubmitted(true);
        return refreshCalendar();
      })
      .catch(() => notify.error('Failed to reschedule the appointment'));
  };

  useEffect(() => {
    dispatch(getBuildingCalendarWeek(calendar.id, startOfWeek))
      .catch(err => notify.error(format.error(err)));
  },[startOfWeek, calendar.id, dispatch, notify]);

  return (
    <div className={classNames('stackVertical gap-20',CSS.wrapper)}>
      { submitted && (
        <div className={CSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
        </div>
      )}

      <Form
        name='reschedule-form'
        onSubmit={handleSubmit}
        validation={validationSchema}
        className='stackVertical gap-12'
      >
        <div className='stackHorizontal gap-20'>
          <FormText
            name='tenant_name'
            label='Full name'
            value={format.fullname(appointment)}
            readOnly={true}
            className='flex-auto'
          />
          <FormText
            name='unit_number'
            label='Unit number'
            value={appointment?.unit}
            readOnly={true}
            className='flex-auto'
          />
        </div>
        <FormDateSelect
          name='date'
          label='Date'
          minDate={moment().format('YYYY-MM-DD')}
          value={activeDate}
          onChange={({date}) => setActiveDate(date)}
        />
        <FormSelect
          name='start_time'
          label={timeslotOptions.length > 0 ? 'Time slot' : 'No available times'}
          value={''}
          options={timeslotOptions}
          disabled={timeslotOptions.length === 0 || buildingCalendarWeekPending}
        />
      </Form>

      <div className='stackHorizontal gap-12'>
        <Button
          className='flex-auto'
          color='secondary'
          text='Cancel'
          onClick={() => popover.hide?.()}
          disabled={pending}
        />
        <Button
          className='flex-auto'
          color='primary'
          text='Update'
          type='submit'
          form='reschedule-form'
          disabled={pending || timeslotOptions.length === 0}
        />
      </div>
    </div>
  );
}
