import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_GET_SPECIAL_ITEMS',
  request: () => request.get('/inventory-items/special-items').then(r => r?.data),
  selector: (state) => state?.specialItems,
  cache: true,
  reducers: {
    success: (state, { response: specialItems }) => ({
      ...state,
      specialItems,
    }),
  }
};

export const {
  action: getSpecialItems,
  reducer,
  useResource: useSpecialItems,
  usePending: useSpecialItemsPending,
} = generate.redux(creator);

export const initialState = {
  specialItems: {},
};
