import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CX_ORDERS_RESCHEDULE',
  /* pattern here is to update data via vendor job endpoint, but then refresh the order data which was updated as a consequence */
  request: (id,jobNumber,data={}) => request.post(`/cx/vendor-jobs/${jobNumber}/reschedule`,data) // post the update to vendor-job
    .then(() => request.get(`/cx/service-orders/${id}`)) // refresh the order data
    .then(r => get(r,'data.data.service_order')), // get the service_order data from the response
  reducers: {
    success: (state, { response: order, params: [id] }) => ({
      ...state,
      orders: {
        ...state.orders,
        [id]: order,
      }
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useReschedulePending,
} = generate.redux(creator);

export const initialState = {};
