import React from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { createFileRequirement } from '../actions';
import { useCreateFileRequirementPending } from '../actions/selectors';

import CSS from './styles/CreateFileReqModal.module.scss';

export const CreateFileReqModal = ({ list, task }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const notify = useNotify();
  // redux
  const pending = useCreateFileRequirementPending();


  const handleSubmit = (data) => {
    dispatch(createFileRequirement(list.id, task.id, data))
      .then(() => {
        return close(false);
      })
      .catch(error => notify.error(format.error(error)));
  };

  const fields = [
    {
      label: 'Name',
      type: 'text',
      name: 'display_name',
      required: true,
    },
  ];

  return (
    <div className={CSS.creation}>
      <div className={CSS.title}>
        <h3>Add document</h3>
      </div>
      <div className={CSS.form}>
        <DynamicForm
          fields={fields}
          formStyle={'underline'}
          id="create-file-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.controls}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="create-file-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Create
        </label>
      </div>
    </div>
  );
}
