import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_CREATE_PETSCREENING_VERIFICATION_INSTRUCTIONS',
  request: (taskId,data={}) => request.post(`/admins/building-tasks/${taskId}/petscreening-verification-instructions`,data).then(r => r?.data?.data?.instruction_content),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      petscreeningVerificationInstructions: {
        ...state.petscreeningVerificationInstructions,
        [id]: response,
      }
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useCreatePetscreeningVerificationInstructionsPending,
} = generate.redux(creator);

export const initialState = {};
