import React, { Fragment } from 'react';

import { CompleteCard } from '@moved/ui';
import { useModal } from '@moved/services';

import CSS from './styles/CloneChaptersModal.module.scss';

export const SuccessScreen = ({ id, success, dataCollection, progress, setProgress }) => {
  // HOOKS
  const modal = useModal();

  const { content_editor_contents: chapters } = success;

  return (
      <>
        <div className={CSS.content}>
          <CompleteCard status={'approved'} title={'Successfully cloned!'} detailsTitle={null}>
            <div className={CSS.message}>
              {chapters.map((chapter, idx) => {
                const lastItem = idx+1 >= chapters.length;
                return (
                  <Fragment key={chapter.id}>
                    {(lastItem && chapters.length > 1) && `& `}
                    &ldquo;<span className={CSS.cloned_title}>{chapter.title}</span>&rdquo;
                    {!lastItem && `, `}
                  </Fragment>
                )
              })} {chapters.length > 1 ? 'have' : 'has'} been succesfully cloned to {dataCollection.building_ids.length}
              &nbsp;{dataCollection.building_ids.length > 1 ? 'properties' : 'property'}.
            </div>
          </CompleteCard>
        </div>

        <div className={CSS.complete}>
          <label
            id='property_form_button'
            tabIndex="0"
            role="button"
            className={'btn-primary'}
            onClick={e => {
              e.preventDefault();
              return modal.close();
            }}
          >
            Done
          </label>
        </div>
      </>
  );
}
