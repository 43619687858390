import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_UPDATE_VENDOR',
  request: (id,data={}) => request.patch(`/admins/vendors/${id}`, data).then(r => r?.data?.data?.vendor),
  reducers: {
    success: (state, { response: vendor }) => ({
      ...state,
      vendors: {
        ...state.vendors,
        [vendor.id]: vendor,
      },
    }),
  },
};

export const {
  action: updateVendor,
  usePending: useUpdateVendorPending,
  reducer,
} = generate.redux(creator);

export const initialState = {};
