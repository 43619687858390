import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { Modal, Button } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services';

import { addChapterTemplates } from '../../actions';
import { useAddChapterTemplatesPending } from '../../actions/selectors';

import CSS from './styles/ConfirmTemplates.module.scss';

// TODO: filter chips as UI component

export const ConfirmTemplates = ({ data={}, taskId, onBack }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const pending = useAddChapterTemplatesPending();

  const selections = get(data,'selected',[]).map(chapter => JSON.parse(chapter));

  const submit = () => {
    dispatch(addChapterTemplates(taskId, { content_gallery_template_ids: selections.map(template => template.id) }))
      .then(() => {
        notify.default('Template successfully added to this task.');
        modal.close(true);
      })
      .catch(error => notify.error(format.error(error)));;
  }

  return (
    <>
      <Modal.Title>
        <span className='contentSecondary mr-10'>3/3</span>
        Confirm selection
      </Modal.Title>

      <Modal.Content className={CSS.content}>
        <div className='labelM contentPrimary mb-15 mt-10'>
          Selected templates
          <span className='contentSecondary ml-10'>{ selections.length }</span>
        </div>
        <div className='stackHorizontal gap-8 mb-20'>
          { selections.map(template => (
            <div key={template.id} className={classNames('labelM contentPrimary', CSS.filter_chip)}>{ template.title }</div>
          ))}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Back'
          icon={{ library: 'navigation', symbol: 'Arrow-left' }}
          color='secondary'
          disabled={pending}
          onClick={() => !pending && onBack()}
        />
        <div className={CSS.spacer} />
        <Button
          text='Cancel'
          color='secondary'
          disabled={pending}
          onClick={() => !pending && modal.close()}
        />
        <Button
          text="Confirm & Add templates"
          color='primary'
          onClick={() => !pending && submit()}
          disabled={pending}
        />
      </Modal.Actions>
    </>
  );
};
