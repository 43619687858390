import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import classNames from 'classnames';
import { get, noop } from 'lodash';

import { useScroller, useQuery, request } from '@moved/services';
import { LoaderOverlay, Pagination, Icon, FilterPills, EmptyContent } from '@moved/ui';

import { TenantEventSummary } from './TenantEventSummary';
import CSS from './styles/TenantEventSearch.module.scss';

const SortColumn = ({name, sortBy, sortOrder, className, setSort = noop, children}) => {
  const match = sortBy === name;

  return (
    <div onClick={(e) => { e.preventDefault(); setSort(name)}} className={classNames(className, { [CSS.active]: match })}>
      {children}{match && (<Icon symbol={sortOrder === 'desc' ? 'Chevron-down': 'Chevron-up'} library='navigation' size='16px' />)}
    </div>
  );
}

// FUTURE: This component is massive, complex, and confusing. Needs to be refactored.
export const TenantEventsSearch = ({
  clients,
  getBuildings,
  getTenantEvents,
  partner,
  useTenantEvents,
  useTenantEventsPending,
}) => {
  // HOOKS
  const location  = useLocation();
  const scroller = useScroller();

  const history = useHistory();
  const dispatch = useDispatch();

  // QUERY PARAMS: Get filter, keywords, etc. from URL
  // Past / Upcoming filter
  let filter = useQuery('filter');
  if(!filter) filter = undefined;
  // Move step type filter
  let stepTypeFilter = useQuery('move_step_type');
  if(!stepTypeFilter) stepTypeFilter = undefined;
  // Lease date filter
  let leaseDate = useQuery('lease_date');
  if(!leaseDate) leaseDate = undefined;
  // Active pagination page
  let activePage = parseInt(useQuery('page'));
  if(!activePage) activePage = 1;
  // Search keywords
  let keywords = useQuery('keywords');
  if(!keywords) keywords = '';
  keywords = decodeURIComponent(keywords);
  // Filter by building
  let buildingParams = useQuery('building_ids');
  const buildingFilter = buildingParams ? buildingParams.split(',').map(id => parseInt(id)) : [];
  // Filter by client
  let clientParams = useQuery('partner_ids');
  const clientFilter = clientParams ? clientParams.split(',').map(id => parseInt(id)) : [];
  // Sort columns
  let sortBy = useQuery('sort_by');
  // Sort order
  let sortOrder = useQuery('sort_order');
  if(!sortOrder) sortOrder = 'asc';

  // QUERY FUNCTIONS: Functions to update query string in URL when action is taken
  const updateQuery = (page, filter, keywords, buildingFilter, sortBy, sortOrder, stepType, leaseDate, clientFilter) => {
    return history.replace({
      pathname: location.pathName,
      search: `?${filter ? 'filter=' + encodeURIComponent(filter) : '' }`+
        `${stepType ? '&move_step_type=' + encodeURIComponent(stepType) : '' }`+
        `${leaseDate ? '&lease_date=' + encodeURIComponent(leaseDate) : '' }`+
        `${keywords && keywords.length > 0 ? `&keywords=${encodeURIComponent(keywords)}` : ''}`+
        `${page ? '&page='+encodeURIComponent(page) : ''}`+
        `${buildingFilter && buildingFilter.length > 0 ? `&building_ids=${buildingFilter}` : ''}`+
        `${sortBy ? '&sort_by='+sortBy+'&sort_order='+sortOrder : ''}`+
        `${clientFilter && clientFilter.length > 0 ? '&partner_ids=' + clientFilter : '' }`,
    });
  };
  const setActivePage = page => updateQuery(page,filter,keywords,buildingFilter,sortBy,sortOrder,stepTypeFilter,leaseDate,clientFilter);
  const setFilter = filter => updateQuery(1,filter,keywords,buildingFilter,sortBy,sortOrder,stepTypeFilter,leaseDate,clientFilter);
  const setStepTypeFilter = stepType => updateQuery(1,filter,keywords,buildingFilter,sortBy,sortOrder,stepType,leaseDate,clientFilter);
  const setLeaseDate = date => updateQuery(1,filter,keywords,buildingFilter,sortBy,sortOrder,stepTypeFilter,date,clientFilter);
  const setKeywords = keywords => updateQuery(1,filter,keywords,buildingFilter,sortBy,sortOrder,stepTypeFilter,leaseDate,clientFilter);
  const setBuildingFilter = buildingList => updateQuery(1,filter,keywords,buildingList,sortBy,sortOrder,stepTypeFilter,leaseDate,clientFilter);
  const setClientFilter = clientList => updateQuery(1,filter,keywords,buildingFilter,sortBy,sortOrder,stepTypeFilter,leaseDate,clientList);
  const setSort = newSort => {
    const newOrder = (newSort === sortBy && sortOrder === 'asc') ? 'desc' : 'asc';
    return updateQuery(1,filter,keywords,buildingFilter,newSort,newOrder,stepTypeFilter,leaseDate,clientFilter)
  };


  // GENERAL STATE
  const RESULTS_PER_PAGE = 10;

  // TOTAL PAGES - PAGINATION STATE
  const [totalPages, setTotalPages] = useState(1);

  // ACTIVE BUILDINGS STATE
  const [activeBuildings, setActiveBuildings] = useState([]);

  // MOVES STATE
  const tenantEvents = useTenantEvents();
  const pending = useTenantEventsPending();

  // BUILDINGS REDUX
  const buildings = useSelector(state => state.buildings);

  // FILTER LIST
  const filters = [
    {name: "Upcoming moves", id: 'upcoming'},
    {name: "Past moves", id: 'past'},
  ];

  const stepTypes = [
    {name: "Move ins", id: 'move-in'},
    {name: "Move outs", id: 'move-out'},
  ];

  // Function to grab moves
  const loadMoves = (cancelToken, delay) => {
    // Set request params
    const params = {
      page: activePage,
      limit: RESULTS_PER_PAGE,
      filter: filter,
      keywords: keywords.length > 0 ? keywords : null,
      building_ids: buildingFilter.length > 0 ? buildingFilter.join(',') : null,
      sort_by: sortBy,
      sort_order: sortOrder,
      move_step_type: stepTypeFilter,
      lease_date: leaseDate,
      partner_ids: clientFilter.length > 0 ? clientFilter.join(',') : null,
    }

    //Scroll to top of page
    scroller.ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // If delay is present, use for debounce purposes
    if(delay) return setTimeout(() => dispatch(getTenantEvents(params,cancelToken)).catch(noop), delay);
    return dispatch(getTenantEvents(params,cancelToken));
  };

  // --- USE EFFECT ---
  // Get previous value of keywords
  const prevKey = usePrevious(keywords);
  // Update search if any criteria change
  useEffect(() => {
    // Only use a timeout if keywords is what changed
    let delay = 500;
    if(typeof(prevKey) === 'undefined' || prevKey === keywords) delay = null;

    const { cancel, token } = request.CancelToken.source();

    const timeOutId = loadMoves(token, delay);

    return () => cancel("Keywords updated, query canceled") || clearTimeout(timeOutId);
  // eslint-disable-next-line
  },[keywords, filter, activePage, buildingFilter.join(','), sortBy, sortOrder, stepTypeFilter, leaseDate, clientFilter.join(',')]);

  // Run when moves results change
  useEffect(() => {
    const newPageCount = Math.ceil(tenantEvents.totalResults / RESULTS_PER_PAGE);
    if(newPageCount !== totalPages) setTotalPages(newPageCount);
  // eslint-disable-next-line
  },[tenantEvents]);

  // Fetch partner buildings on mount
  useEffect(() => {
    if(partner) { // this flag is true for partners, false for admins
      dispatch(getBuildings({limit: 500}))
        .then(resp => {
          setActiveBuildings(resp);
        });
      }
  // eslint-disable-next-line
  },[]);

  // Fetch all buildings params change in Moon tools
  useEffect(() => {
    if(!partner) { // this flag is true for partners, false for admins
      if(buildingParams) {
        dispatch(getBuildings({building_ids: buildingParams}))
          .then(resp => {
            setActiveBuildings(resp);
          });
      } else {
        setActiveBuildings([]);
      }
    }
  // eslint-disable-next-line
  },[buildingParams]);

  // --- RENDER ---
  return (
    <>
      { pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.title}>
          <h2>Moves</h2>
        </div>

        <div className={CSS.filters}>
          <FilterPills
            clearAll={() => updateQuery()}
            filters={
              [
                {
                  label: 'Past & upcoming',
                  type: 'singleSelect',
                  list: filters,
                  active: filter,
                  props: {
                    onSelect: setFilter,
                    title: "Past & upcoming moves",
                  },
                },
                !partner && {
                  label: 'Client',
                  type: 'multiSelect',
                  list: clients,
                  active: clientFilter,
                  props: {
                    onSelect: setClientFilter,
                    title: "Client",
                  },
                },
                {
                  label: 'Property',
                  type: partner ? 'multiSelect' : 'typeAhead',
                  list: buildings,
                  active: buildingFilter,
                  props: {
                    activeItems: activeBuildings,
                    onSelect: setBuildingFilter,
                    title: "Property name",
                    searchAction: partner ? null : (params, cancelToken) => {
                      if(clientFilter.length) params.partner_ids = clientFilter.join(',');
                      return dispatch(getBuildings(params, cancelToken));
                    },
                  },
                },
                (!partner || get(partner,'settings.admin_move_out_enabled')) && {
                  label: 'Direction',
                  type: 'singleSelect',
                  list: stepTypes,
                  active: stepTypeFilter,
                  props: {
                    onSelect: setStepTypeFilter,
                    title: "Move direction",
                  },
                },
                {
                  label: 'Lease date',
                  type: 'dateSelect',
                  active: leaseDate,
                  props: {
                    onSelect: setLeaseDate,
                    title: "Lease date",
                  },
                },
                {
                  type: 'divider',
                },
                {
                  label: 'Search',
                  type: 'keyword',
                  active: keywords,
                  props: {
                    onSelect: value => {
                      setActivePage(1);
                      return setKeywords(value);
                    },
                  },
                },
              ].filter(v=>v)
            }
          />
        </div>

        <div className={CSS.search}>
          <h3>
            Results{keywords && (<> for &ldquo;<span className={CSS.search_terms}>{keywords}</span>&rdquo;</>)}
            <span className={CSS.search_results}>{tenantEvents.totalResults}</span>
          </h3>
        </div>

        {/* Sort header */}
        <div className={CSS.sort_header}>

          <SortColumn name={'move_step_type'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.direction} setSort={setSort}>Direction</SortColumn>

          <SortColumn name={'unit'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.unit} setSort={setSort}>Unit</SortColumn>

          <SortColumn name={'name'} sortBy={sortBy} sortOrder={sortOrder} className={CSS.name} setSort={setSort}>Resident name</SortColumn>

          <SortColumn name={'lease_date'} sortBy={sortBy} sortOrder={sortOrder} setSort={setSort}>Lease date</SortColumn>

          <SortColumn name={'building'} sortBy={sortBy} sortOrder={sortOrder} setSort={setSort}>Property Name</SortColumn>

          <SortColumn name={'completion'} sortBy={sortBy} sortOrder={sortOrder} setSort={setSort}>Status</SortColumn>

          <div className={CSS.arrow}>&nbsp;</div>

        </div>

        <div className={CSS.results}>
          { tenantEvents.activeSet.length ? tenantEvents.activeSet.map((tenantEvent,idx) => {
            return (<TenantEventSummary tenantEvent={tenantEvent} key={tenantEvent.id} partner={partner} onClick={e => { e.preventDefault(); history.push(`/admin/moves/${tenantEvent.id}`); }} />);
          }) : (
            <div className={CSS.no_results_wrapper}>
              <EmptyContent
                className={CSS.no_results}
                message='No moves match your search criteria!'
                iconSize='50px'
              />
            </div>
          )}
        </div>

        {/* Page nav */}
        { totalPages > 1 && (
          <Pagination
            page={activePage}
            pageCount={totalPages}
            onPageChange={setActivePage}
          />
        )}

      </div>
    </>
  );
};
