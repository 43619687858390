import { Four04 } from '@moved/product';

import adminsRoutes from './admins/routes';
import clientsRoutes from './clients/routes';
import propertiesRoutes from './properties/routes';

const routes = [
  ...adminsRoutes,
  ...clientsRoutes,
  ...propertiesRoutes,
    {
      path: '*',
      name: 'Page not found',
      component: Four04,
      allowGuest: true,
    },

];


export default routes;
