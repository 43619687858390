import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { FormDateSelect, FormSelect, AtomSpinner } from '@moved/ui';

import { getTenantKeyPickupCalendar } from '../actions';
import { useTenantKeyPickupCalendar } from '../actions/selectors';

const getEarliestAvailableDate = (calendar) => {
  const availability = get(calendar,'availability',[]);
  const firstAvailability = availability[0];
  return moment.max(
    moment(get(calendar,'earliest_key_pickup_datetime')),
    moment(get(firstAvailability,'date')),
  ).format('YYYY-MM-DD');
};

const getLatestAvailableDate = (calendar) => {
  const availability = get(calendar,'availability',[]);
  const lastAvailability = availability[availability.length - 1];
  return get(lastAvailability,'date');
};

export const AppointmentSchedule = ({ calendarId, task, tenantEvent }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const [pending, setPending] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const calendar = useTenantKeyPickupCalendar(tenantEvent.id,task.building_task_id,calendarId);
  const availability = get(calendar,'availability',[]);
  const selectedAvailability = availability.find(day => day.date === selectedDate);
  const earliestAvailableDate = getEarliestAvailableDate(calendar);
  const lastAvailableDate = getLatestAvailableDate(calendar);

  const updateDate = (newDate) => {
    if(newDate === selectedDate) return;
    setFieldValue('pickup_time',null);
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if(!calendarId) return;
    setPending(true);
    dispatch(getTenantKeyPickupCalendar(tenantEvent.id,task.building_task_id,calendarId))
      .then(calendar => setSelectedDate(getEarliestAvailableDate(calendar)))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  },[tenantEvent, task, calendarId, dispatch, notify])

  // can't initialize the form until the calendar is loaded
  if(pending || !calendar) return <AtomSpinner/>;

  const timeOptions = get(selectedAvailability,'timeslots',[])
    .filter(slot => slot.is_available)
    .map(slot => ({
      label: `${format.date(`${selectedDate}T${slot.start}`,'h:mma')} - ${format.date(`${selectedDate}T${slot.end}`,'h:mma')}`,
      value: format.date(`${selectedDate}T${slot.start}`,'h:mmA'),
    }));

  return (
    <>
      {/* TODO: disable dates with no availability */}
      <FormDateSelect
        name='pickup_date'
        label='Pickup date'
        minDate={earliestAvailableDate}
        maxDate={lastAvailableDate}
        value={selectedDate}
        onChange={({pickup_date}) => updateDate(pickup_date)}
      />
      { get(calendar,'booking_type') === 'appointment-slot' && (
        <FormSelect
          name='pickup_time'
          label='Pickup time'
          options={timeOptions}
        />
      )}
    </>
  );
};
