import { Four04 } from '@moved/product';

import { ApprovalLanding } from './shared/components';

import { CoiSubmissionLanding } from './coi/components';
import { DocumentSubmissionLanding } from './docSubmission/components';
import { KeyReviewLanding } from './key/components';
import { MoveOutInspectionRequestLanding } from './moveOutInspection/components';
import { PayMoveInCostsLanding } from './payMoveInCosts/components';
import { PetRegistrationLanding } from './petRegistration/components';
import { RentersInsurancePolicyLanding } from './rentersInsurance/components';
import { ReservationRequestLanding } from './reservation/components';
import { OrientationReviewLanding } from './orientation/components';
import { UtilitySetupReviewLanding } from './utilitySetup/components';
import { VehicleRegistrationLanding } from './vehicleRegistration/components';

const routes = [{
  path: '/approvals',
  name: 'Approvals',
  component: ApprovalLanding,
  allowGuest: true,
  children: [
    {
      path: '/coi-submissions/:token',
      name: 'CoiSubmissionLanding',
      component: CoiSubmissionLanding,
      allowGuest: true,
    },
    {
      path: '/document-submissions/:token',
      name: 'DocumentSubmissionLanding',
      component: DocumentSubmissionLanding,
      allowGuest: true,
    },
    {
      path: '/key-pickup-appointment-requests/:token',
      name: 'KeyReviewLanding',
      component: KeyReviewLanding,
      allowGuest: true,
    },
    {
      path: '/move-out-inspection-requests/:token',
      name: 'MoveOutInspectionRequestReview',
      component: MoveOutInspectionRequestLanding,
      allowGuest: true,
    },
    {
      path: '/pay-move-in-costs/:token',
      name: 'PayMoveInCostsLanding',
      component: PayMoveInCostsLanding,
      allowGuest: true,
    },
    {
      path: '/pet-registrations/:token',
      name: 'PetRegistrationLanding',
      component: PetRegistrationLanding,
      allowGuest: true,
    },
    {
      path: '/renters-insurance-policies/:token',
      name: 'RentersInsurancePolicyReview',
      component: RentersInsurancePolicyLanding,
      allowGuest: true,
    },
    {
      path: '/reservation-requests/:token',
      name: 'ReservationRequestReview',
      component: ReservationRequestLanding,
      allowGuest: true,
    },
    {
      path: '/user-orientation-requests/:token',
      name: 'OrientationReviewLanding',
      component: OrientationReviewLanding,
      allowGuest: true,
    },
    {
      path: '/utility-setup-requests/:token',
      name: 'OrientationReviewLanding',
      component: UtilitySetupReviewLanding,
      allowGuest: true,
    },
    {
      path: '/vehicle-registrations/:token',
      name: 'VehicleRegistrationLanding',
      component: VehicleRegistrationLanding,
      allowGuest: true,
    },
    {
      path: '*',
      name: 'Page not found',
      component: Four04,
      allowGuest: true,
    },
  ]
}];

export default routes;
