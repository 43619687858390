import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, debounce, isNil } from 'lodash';
import classNames from 'classnames';

import { Modal, Button, FilterPills, Pagination, EmptyContent, OptionList, BouncingDots } from '@moved/ui';
import { useModal, useNotify, format, request } from '@moved/services';

import { getChapterTemplateCategories, getChapterTemplates } from '../../actions';
import {
  useChapterTemplateCategories, useChapterTemplateCategoriesPending,
  useChapterTemplates, useChapterTemplatesPending,
} from '../../actions/selectors';

import { ChapterPreviewModal } from '../ChapterPreviewModal';

import CSS from './styles/TemplateGallery.module.scss';

export const TemplateGallery = ({ data={}, updateData, onNext, onBack }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const categories = useChapterTemplateCategories();
  const { pagination={}, results: templates=[] } = useChapterTemplates();

  const chaptersPending = useChapterTemplatesPending();
  const categoriesPending = useChapterTemplateCategoriesPending();
  const pending = chaptersPending || categoriesPending;

  const [selected, setSelected] = useState(data.selected || []);
  const [activeCategory, setActiveCategory] = useState();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const RESULTS_PER_PAGE = 5;

  // load the list of categories on mount
  useEffect(() => {
    dispatch(getChapterTemplateCategories())
      .catch(error => notify.error(format.error(error)));;
  },[]); // eslint-disable-line

  // refresh the list of templates whenever any param is updated
  useEffect(() => {
    const { cancel, cancelToken } = request.CancelToken.source();
    const params = {
      keywords: search,
      content_editor_content_gallery_category_id: activeCategory,
      page,
      limit: RESULTS_PER_PAGE,
    };
    dispatch(getChapterTemplates(params, cancelToken))
      .catch(error => notify.error(format.error(error)));
    // if values updated before the query returns, avoid a recurrsion loop by cancelling the open request
    return () => cancel('new query');
  },[activeCategory, search, page, notify, dispatch]);

  const showPreviewModal = (e, template) => {
    e.stopPropagation();
    return modal.open(
      <ChapterPreviewModal chapter={template.content_editor_content} title={`${template.title} Preview`}/>,
    );
  };

  const saveTemplateSelections = () => {
    if(!selected.length) return;
    updateData({selected});
    onNext();
  }

  const resetFilters = () => {
    setActiveCategory();
    setSearch('');
    setPage(1);
  };

  const filters = [
    {
      label: 'Category',
      type: 'singleSelect',
      list: categories.map(({id,display_name}) => ({ id, name: display_name })),
      active: activeCategory,
      props: {
        onSelect: (category) => {
          setActiveCategory(category);
          setPage(1);
        },
        title: "Categories",
      },
    },
    {
      type: 'divider',
    },
    {
      label: 'Search',
      type: 'keyword',
      active: search,
      props: {
        onSelect: debounce((keywords) => {
          if(keywords === search) return; // since this is debounced avoid unnessary updates
          setSearch(keywords);
          setPage(1);
        }, 300),
      },
    },
  ];

  // transform the template search results into selectList options
  const templateOptions = templates.map(template => ({
    label: (
      <div className={CSS.row}>
        <div className={CSS.logo} style={{
          backgroundImage: `url('${template.logo_url}')`
        }}/>
        <div className={CSS.label}>
          <div className='labelM contentPrimary'>{template.title}</div>
          <div className='labelS contentSecondary'>{get(template,'categories[0].display_name','Uncategorized')}</div>
        </div>
        <Button
          text='Preview'
          color='tertiary'
          size='small'
          className={CSS.preview}
          onClick={e => showPreviewModal(e, template)}
        />
      </div>
    ),
    value: JSON.stringify({
      id: template.id,
      title: template.title,
    }),
  }))

  return (
    <>
      <Modal.Title>
        <span className='contentSecondary mr-10'>2/3</span>
        Choose from gallery
      </Modal.Title>

      <Modal.Content className={CSS.content}>

        <div className={CSS.filters}>
          <FilterPills
            filters={filters}
            clearAll={( !isNil(activeCategory) || search ) && resetFilters }/>
        </div>

        <div className={classNames('mb-15',CSS.results_summary)}>
          <div className='labelM contentPrimary'>
            Available templates
            { pending ? <BouncingDots className='ml-5' /> : (
              <span className='contentSecondary ml-5'>{ pagination.total_items }</span>
            )}
          </div>
          { selected.length > 0 && (
            <div className='labelM '>
              <span className='success'>{ selected.length }</span>
              <span className='success ml-5'>selected</span>
            </div>
          )}
        </div>

        {/* select list of available templates */}
        <OptionList
          name='selectedTemplates'
          allowMultiple={true}
          size='small'
          className='mb-15'
          onChange={({selectedTemplates}) => setSelected(selectedTemplates)}
          options={templateOptions}
        />

        {/* no results view */}
        { templates.length === 0 && (<EmptyContent message='No matching templates' />)}

        <div className={CSS.spacer} />

        {/* pagination */}
        { pagination.total_pages > 1 && (
          <Pagination page={page} pageCount={pagination.total_pages} onPageChange={setPage} />
        )}

      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Back'
          icon={{ library: 'navigation', symbol: 'Arrow-left' }}
          color='secondary'
          onClick={() => onBack()}
        />
        <div className={CSS.spacer} />
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
        />
        <Button
          text="Next"
          color='primary'
          disabled={!selected.length}
          onClick={saveTemplateSelections}
        />
      </Modal.Actions>
    </>
  );
};
