import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_MOVES_GET_MOVE_DETAILS',
  request: (id, params={}) => request.get(`/cx/moves/${id}`, { params }).then(r => r?.data?.data?.move),
  selector: (state, id) => state?.moveDetails?.[id],
  reducers: {
    success: (state, { response: move }) => {
      return {
        ...state,
        moveDetails: {
          ...state.moveDetails,
          [move.id]: move,
        },
      };

    }
  }
};

export const {
  action,
  reducer,
  useResource: useMoveDetails,
  usePending: useMoveDetailsPending,
} = generate.redux(creator);

export const initialState = {
  moveDetails: {},
};
