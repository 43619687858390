import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_CALENDAR_WEEK',
  request: (id, weekStart) => request.get(`/admins/building-calendars/${id}/week/${weekStart}`)
    .then(r => r?.data?.data?.building_calendar),
  selector: (state, ...params) => state.buildingCalendarWeeks[generate.uniqueKey(...params)],
  reducers: {
    success: (state, { response, params }) => ({
      ...state,
      buildingCalendarWeeks: {
        ...state.buildingCalendarWeeks,
        [generate.uniqueKey(...params)]: response,
      },
    }),
    failure: (state, { params }) => ({
      ...state,
      buildingCalendarWeeks: {
        ...state.buildingCalendarWeeks,
        [generate.uniqueKey(...params)]: null,
      },
    }),
  }
};

export const {
  action: getBuildingCalendarWeek,
  reducer,
  useResource: useBuildingCalendarWeek,
  usePending: useBuildingCalendarWeekPending,
} = generate.redux(creator);

export const initialState = {
  buildingCalendarWeeks: {},
};
