import React, { useState } from 'react';

import { Modal } from '@moved/ui';

import { SelectType } from './SelectType';
import { CreateNewChapter } from './CreateNewChapter';
import { TemplateGallery } from './TemplateGallery';
import { ConfirmTemplates } from './ConfirmTemplates';

export const CreateChapterModal = ({ taskId, order = 1 }) => {
  // STATE
  const [progress, setProgress] = useState(0);
  const [chapterData, setChapterData] = useState({});

  // convenience function to allow merging new data with existing data
  const updateChapterData = (newData) => setChapterData(prevData => ({
    ...prevData,
    ...newData,
  }));

  const flow = [
    SelectType,
    chapterData.type === 'new' && CreateNewChapter,
    chapterData.type === 'template' && TemplateGallery,
    chapterData.type === 'template' && ConfirmTemplates,
  ].filter(v=>v);

  const CurrentStep = flow[progress];

  return (
    <Modal>
      <CurrentStep
        taskId={taskId}
        order={order}
        data={chapterData}
        updateData={updateChapterData}
        currentStep={progress}
        maxSteps={flow.length}
        onNext={() => setProgress(progress+1)}
        onBack={() => setProgress(progress-1)}
      />
    </Modal>
  );
};
