import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  Form,
  FormText,
  FormAddress,
  FormTextarea,
  Modal,
} from '@moved/ui';

import { createVendor, useCreateVendorPending } from '../actions/createVendor';

import CSS from './styles/CreateVendorModal.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  headquarters: Yup.object().nullable().test('address','required',value => value?.google_place_id),
  commission: Yup.number().required('required'),
});

export const CreateVendorModal = () => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();

  const pending = useCreateVendorPending();

  const submit = ({ headquarters, ...values }) => {
    const vendorData = {
      ...values,
      vendor_addresses: [{...headquarters,name:'headquarters'}],
    };
    dispatch(createVendor(vendorData))
      .then((vendor) => modal.close(vendor))
      .catch(err => notify.error(format.error(err)));
  };
  return (
    <Modal>
      <Modal.Title>Add a new vendor</Modal.Title>
      <Modal.Content className={CSS.content}>
        <Form
          name='createVendorForm'
          onSubmit={submit}
          validation={validationSchema}
          className='stackVertical gap-16'
        >
          <FormText
            label='Vendor Name'
            name='name'
          />
          <FormAddress
            label='Headquarters'
            name='headquarters'
          />
          <FormTextarea
            label='Description'
            name='default_description'
            autoResize={true}
          />
          <FormText
            label='Commission'
            name='commission'
            value='0.15'
            type='number'
          />
          <FormText
            label='Review link'
            name='review_link'
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
          disabled={pending}
        />
        <Button
          text='Create'
          color='primary'
          type='submit'
          form='createVendorForm'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
};
