import { Four04 } from '@moved/product';

import { CXLayout } from './common/components';

import movesRoutes from './moves/routes';
import vendorRoutes from './vendors/routes';

const routes = [
  {
    path: '/cx',
    name: 'Layout',
    component: CXLayout,
    children: [
      ...movesRoutes,
      ...vendorRoutes,
      {
        path: '*',
        name: 'Page not found',
        component: Four04,
      },
    ],
  },
];

export default routes;
