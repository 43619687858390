import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, FilterSearch, SlideToggle } from '@moved/ui';

import { BuildingNotifications } from './BuildingNotifications';
import { useBuildingCalendarSummaries } from '../actions/getBuildingCalendarSummaries';
import { useBuildings } from '../../moves/actions/getBuildings';

import CSS from './styles/BuildingCalendarNavigation.module.scss';

const getCalendarLabel = (calendar) => {
  const defaultCalendarNames = { reserve: 'Elevator', keys: 'Keys' };
  return calendar.name ?? defaultCalendarNames[calendar.task_type_name] ?? 'Calendar';
};

export const BuildingCalendarNavigation = ({ activeBuilding, buildingCalendarId }) => {
  const history = useHistory();

  const buildings = useBuildings();

  const calendarList = useBuildingCalendarSummaries(activeBuilding.id);

  const goToBuilding = useCallback(
    (id) => history.push(`/admin/calendars/buildings/${id}`),
    [history]
  );
  const goToCalendar = useCallback(
    (id) => history.push(`/admin/calendars/buildings/${activeBuilding.id}/calendars/${id}`),
    [activeBuilding.id, history]
  );

  return (
    <div className='stackHorizontal items-center justify-between flex-wrap'>

      <FilterSearch
        list={buildings}
        active={[activeBuilding.id]}
        title="Select building"
        popOverClass={CSS.popover}
        onSelect={goToBuilding}
        single={true}
        type='multiSelect'
      >
        <div className={classNames('stackHorizontal gap-8 items-center', CSS.buildingSelector)}>
          <span className='displayS textTruncate'>{ activeBuilding?.name ?? 'Please select a building' }</span>
          <Icon symbol='Chevron-down' library='navigation' size='24px' />
        </div>
      </FilterSearch>

      <div className='stackHorizontal gap-12 flex-grow flex-shrink-n justify-end items-center'>

        <BuildingNotifications buildingId={activeBuilding.id} />

        <SlideToggle
          name='calendar'
          value={buildingCalendarId}
          isControlled={true}
          options={calendarList.map(calendar => ({ value: calendar.id, label: getCalendarLabel(calendar) }))}
          onChange={({calendar}) => goToCalendar(calendar)}
          size='large'
        />

      </div>
    </div>
  );
};
