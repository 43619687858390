import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMINS_CALENDAR_CREATE_BLOCK',
  request: (id, data={}) => request.post(`/partners/building-calendars/${id}/appointments`, data)
    .then(r => r.data.data.appointment),
  reducers: {
    success: (state, { response: block, params: [id] }) => {
      return {
        ...state,
        buildingCalendarWeeks: {
          ...state.buildingCalendarWeeks,
          [id]: {
            ...state.buildingCalendarWeeks[id],
            appointments: [
              ...(state.buildingCalendarWeeks[id].appointments ?? []),
              block,
            ],
          }
        },
      };
    },
  }
};

export const {
  action: createBlock,
  reducer,
  usePending: useCreateBlockPending,
} = generate.redux(creator);

export const initialState = {};
