import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import * as Yup from 'yup';

import {
  AnimatedCheckmark,
  Button,
  ConfirmationModal,
  Form,
  FormText,
  FormSelect,
  FormDateSelect,
  FormTimeSelect,
  Modal,
} from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { createAppointment, useCreateAppointmentPending } from '../actions/createAppointment';

import CSS from './styles/BlockTime.module.scss';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  date: Yup.string().required('Required'),
  start: Yup.string().required('Required'),
  end: Yup.string().required('Required'),
});

export const BlockTime = ({ buildingCalendar, refreshCalendar }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  // state
  const [showSuccess, setShowSuccess] = useState(false);
  const [endTimeMinimum, setEndTimeMinimum] = useState();
  // redux
  const pending = useCreateAppointmentPending();

  const blockedAppointmentTypes = buildingCalendar?.appointment_types?.filter(type => type.is_blocking);

  const handleSubmit = ({ appointment_type_id, date, start, end, title }) => {
    dispatch(createAppointment(appointment_type_id, {
      building_calendar_id: buildingCalendar.id,
      start_time: `${date}T${start}`,
      end_time: `${date}T${end}`,
      title: title,
    })).then(() => {
      refreshCalendar();
      setShowSuccess(true);
      setTimeout(modal.close, 650);
    })
    .catch(error => {
      const errors = format.error(error);
      if(error?.response?.status === 409) {
        modal.close();
        return modal.open(
          <ConfirmationModal
            title={'Unable to block specified time'}
            copy={errors ?
              errors.map(err => (<p key={err}>{err}</p>)) :
              'Other appointments exist during this time. Please cancel or reschedule.'
            }
            cancelText={false}
            confirmText={'I understand'}
          />,
        );
      } else {
        return notify.error(errors);
      }
    });
  };
  const startTimeChange = (value) => {
    const endTimeMinimum = DateTime.fromFormat(value,'HH:mm').plus({minutes: 30}).toFormat('HH:mm');
    setEndTimeMinimum(endTimeMinimum);
  };

  return (
    <Modal>
      <Modal.Title>Block time</Modal.Title>
      <Modal.Content>
        { showSuccess && (<div className={CSS.confirm}><AnimatedCheckmark isChecked={true} fast={true} /></div>) }
        <Form
          name='block-form'
          onSubmit={handleSubmit}
          validation={validationSchema}
          className='stackVertical gap-12'
        >
          { blockedAppointmentTypes.length > 1 && (
            <FormSelect
              name='appointment_type_id'
              label='Type'
              options={blockedAppointmentTypes.map(type => ({ value: type.id, label: type.display_name }))}
              value={blockedAppointmentTypes.at(0).id}
            />
          )}

          <FormText
            name='title'
            label='Title'
          />
          <FormDateSelect
            name='date'
            label='Date'
            minDate={DateTime.now().toISODate()}
            value={DateTime.now().toISODate()}
          />
          <div className='stackHorizontal gap-24'>
            <FormTimeSelect
              name='start'
              label='Start time'
              value=''
              minTime='05:00'
              maxTime='22:00'
              intervals={[0,30]}
              limitToIntervals
              onChange={({start}) => startTimeChange(start)}
            />
            <FormTimeSelect
              name='end'
              label='End time'
              value=''
              minTime={endTimeMinimum || '05:30'}
              maxTime='22:30'
              intervals={[0,30]}
              limitToIntervals
            />
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='secondary'
          disabled={pending}
          onClick={e => modal.close(false)}
          text='Cancel'
        />
        <Button
          color='primary'
          disabled={pending}
          form='block-form'
          text='Block'
        />
      </Modal.Actions>
    </Modal>
  );
}
