import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_TASKS_CREATE_BUILDING_RESERVATION_OVERRIDE',
  request: (tenantEventId, buildingTaskId, data={}) => request.post(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/building-reservation-task-overrides`, data)
    .then(r => get(r,'data.data.building_reservation_task_override')),
  reducers: {
    success: (state, { response: buildingReservationOverride, params: [tenantEventId] }) => ({
      ...state,
      tenantEvents: {
        ...state.tenantEvents,
        [tenantEventId]: {
          ...state.tenantEvents[tenantEventId],
          building_reservation_task_overrides: [
            ...(state.tenantEvents[tenantEventId].building_reservation_task_overrides || []),
            buildingReservationOverride,
          ],
        },
      },
    })
  }
};

export const {
  action,
  reducer,
  usePending: useCreateBuildingReservationOverridePending,
} = generate.redux(creator);

export const initialState = {};
