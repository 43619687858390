import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { SlideToggle, LoaderOverlay, EmptyContent } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { getBuildingFeed } from '../actions';
import { useProperty, useBuildingFeedPending } from '../actions/selectors';

import CSS from './styles/PropertyIntegrations.module.scss';

const availableIntegrations = [{
  setting: 'move_in_sync_enabled',
  label: 'Move In',
  value: 'move-in',
},{
  setting: 'move_out_sync_enabled',
  label: 'Move Out',
  value: 'move-out',
}];

export const PropertyIntegration = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const { property_id, activeIntegrationType } = useParams();
  const property = useProperty(property_id);
  const pending = useBuildingFeedPending();

  const integrations = availableIntegrations.filter(({ setting }) => get(property,`settings.${setting}`));

  //Redirect to the default/first integration if none is specified
  useEffect(() => {
    if(integrations.length === 0) return;
    if(!activeIntegrationType) history.replace(`${history.location.pathname}/${get(integrations,'[0].value')}`);
  },[activeIntegrationType]); // eslint-disable-line

  // load the feed top level data when property or integration selection changes
  useEffect(() => {
    if(activeIntegrationType) dispatch(getBuildingFeed(property_id, { move_step_type: activeIntegrationType }))
      .catch(error => notify.error(format.error(error)));
  },[property_id, activeIntegrationType, dispatch, notify]);

  if(!activeIntegrationType) {
    return integrations.length === 0 ? <EmptyContent /> : null;
  }

  return (
    <div className={CSS.integrations}>

      <div className={CSS.section_title}>
        <div className='labelL contentPrimary'>Overview</div>
        { integrations.length > 1 && (
          <SlideToggle
            name='integrationType'
            className={CSS.integration_toggle}
            onChange={({integrationType}) => history.push(history.location.pathname.split('/').slice(0,-1).concat([integrationType]).join('/'))}
            options={integrations}
            value={activeIntegrationType}
            isControlled
          />
        )}
      </div>

      { pending ? <LoaderOverlay /> : children }

    </div>
  );

};
