import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_MOVES_GET_BUILDING_SUMMARIES',
  request: (params={},cancelToken) => request.get(`/admins/buildings/summaries`, { params, cancelToken }).then(r => r?.data?.data?.buildings),
  selector: (state) => state?.buildings,
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        buildings: response,
      }
    }
  }
};

export const {
  action: getBuildings,
  reducer,
  useResource: useBuildings,
  usePending: useBuildingsPending,
} = generate.redux(creator);

export const initialState = {
  buildings: [],
};
