import React from 'react';
import { get, find } from 'lodash';

import { AtomSpinner, Icon } from '@moved/ui';
import { useModal } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../../common';
import { useOrientationContents, useOrientationContentsPending } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const PreviewChapter = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {

  // REDUX
  const chapters = useOrientationContents(id)
  const pending  = useOrientationContentsPending();
  const updatePending = false;

  const chapter = find(chapters, content => parseInt(content.id) === parseInt(get(dataCollection,'content_editor_content_ids[0]')));

  const modal = useModal();

  if(pending) return <AtomSpinner/>;

  return (
    <>

      <h3 className={CSS.title}><span>{progress}/{length}</span> Chapter preview</h3>

      <div className={CSS.content}>
        <AdminContentRenderer content={chapter.content} />
      </div>

      <div className={CSS.actions}>
        <div>
        </div>
        <div className={CSS.actions_right}>
          <label
            tabIndex="1"
            role="button"
            className={'btn-gray'}
            onClick={e => {
              e.preventDefault();
              if (updatePending) return;
              return modal.close();
            }}
          >
            Cancel
          </label>

          <label
            tabIndex="0"
            role="button"
            className={CSS.next}
            onClick={e => setProgress(progress+1)}
          >
            <span>Next</span>
            <Icon symbol={'Arrow-right'} library={'navigation'} color={'white'} />
          </label>
        </div>
      </div>

    </>
  );
};
