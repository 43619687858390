import React from 'react';
import Moment from 'react-moment';
import moment from 'moment'
import classNames from 'classnames';

import { CalendarSlot } from './CalendarSlot';
import { CalendarBlock } from './CalendarBlock';

import CSS from './styles/CalendarDay.module.scss';

const dayStrings = {
  lastDay : 'ddd',
  sameDay : '[Today]',
  nextDay : 'ddd',
  lastWeek : 'ddd',
  nextWeek : 'ddd',
  sameElse : 'ddd'
};

export const CalendarDay = ({
  date,
  slots,
  blocks,
  requests,
  appointments,
  isActive,
  calendar,
  building,
  refreshCalendar,
}) => {

  const isEventInSlot = (event, slot) => moment(event.start_time).isBetween(`${date}T${slot.start}`,`${date}T${slot.end}`, 'minute','[)');
  const isToday = moment(date).isSame(moment(), 'd');

  return (
    <div className={classNames(CSS.day_column, {[CSS.active_day]: isActive, [CSS.today]: isToday, [CSS.no_slots]: slots.length < 1})}>
      <div className={CSS.day_col_border} />
      <div className={CSS.day_header}>
        <div className={CSS.header_line} />
        <div className='flex-auto'>
          <p className={CSS.day_of_week}>
            <Moment date={date} calendar={dayStrings} />
          </p>
          <p className={CSS.day_of_month}>
            <Moment date={date} format="D" />
          </p>
        </div>
      </div>

      <div className={CSS.slot_holder}>
        { slots.map(slot => (
          <CalendarSlot
            key={`${slot.start} ${slot.end}`}
            slot={slot}
            date={date}
            requests={requests.filter(request => isEventInSlot(request, slot))}
            appointments={appointments.filter(appointment => isEventInSlot(appointment, slot))}
            calendar={calendar}
            building={building}
            refreshCalendar={refreshCalendar}
          />
        ))}
        { blocks.map(block => (
          <CalendarBlock
            key={block.id}
            block={block}
            refreshCalendar={refreshCalendar}
          />
        ))}
      </div>

    </div>
  );
};
