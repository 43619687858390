import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { Popover, MonthlyCalendar, Button } from '@moved/ui';
import { useModal, useUser } from '@moved/services';

import { useBuildingCalendarSummaries } from '../actions/getBuildingCalendarSummaries';

import { BlockTime } from './BlockTime';

import CSS from './styles/CalendarToolbar.module.scss';

export const CalendarToolbar = ({ activeDate, setActiveDate, refreshCalendar }) => {
  const modal = useModal();
  const { Can } = useUser();

  const { buildingId, buildingCalendarId } = useParams();

  const buildingCalendarSummaries = useBuildingCalendarSummaries(buildingId);
  const activeCalendar = buildingCalendarSummaries.find(buildingCalendar => buildingCalendar.id === parseInt(buildingCalendarId));

  const showBlockModal = () => modal.open(<BlockTime buildingCalendar={activeCalendar} refreshCalendar={refreshCalendar} />);

  const weekStart = moment(activeDate).startOf('week');
  const monthStart = weekStart.format('MMMM');
  const weekEnd = moment(activeDate).clone().endOf('week');
  const monthEnd = weekEnd.format('MMMM');
  const monthDisplay = monthStart === monthEnd ? `${monthStart} ${weekStart.format('YYYY')}` : `${monthStart} - ${monthEnd} ${weekEnd.format('YYYY')}`;

  return (
    <div className='stackHorizontal justify-between items-center gap-24'>
      <div className='stackHorizontal items-center justify-start gap-16'>
        <div className='stackHorizontal items-center justify-start gap-8'>
          <Button
            text='Today'
            size='small'
            color='secondary'
            onClick={() => setActiveDate(moment().startOf('week').format('YYYY-MM-DD'))}
          />
          <Button
            size='small'
            color='secondary'
            icon={{ symbol: 'Chevron-left', library: 'navigation' }}
            onClick={() => setActiveDate(weekStart.subtract(1,'weeks').format('YYYY-MM-DD'))}
          />
          <Button
            size='small'
            color='secondary'
            icon={{ symbol: 'Chevron-right', library: 'navigation' }}
            onClick={() => setActiveDate(weekStart.add(1,'weeks').format('YYYY-MM-DD'))}
          />
        </div>

        <Popover
          placement="bottom"
          trigger="click"
          offset={[0,8]}
        >
          <Popover.Trigger className={classNames('headingS contentPrimary',CSS.month)}>
            { monthDisplay }
          </Popover.Trigger>
          <Popover.Content showArrow={false}>
            <MonthlyCalendar
              selected={activeDate}
              onSelect={(newDate) => setActiveDate(newDate.startOf('week').format('YYYY-MM-DD'))}
              moment={moment(activeDate)}
              highlightWeek={true}
              highlightDay={false}
              hoverWeek={true}
            />
          </Popover.Content>
        </Popover>
      </div>

      <div className='stackHorizontal gap-12'>
        <Can I="CreateBuildingAppointments">
          <Button
            text='Block time'
            size='small'
            color='primary'
            onClick={showBlockModal}
            icon={{ symbol: 'Plus', library: 'navigation' }}
            iconPosition='right'
          />
        </Can>
      </div>
    </div>
  );
};
