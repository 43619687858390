import React from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

 import { updateNotificationPreferences } from '../actions/';

import CSS from './styles/NotificationOptions.module.scss';

export const NotificationOptions = ({notifications = [], buildingAdmin, admin}) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { hasAbilities } = useUser();

  const _submitSingleValue = (value, name) => {
    const { type, building_task_id } = notifications.find(notification => name === `${notification.type}_${notification.building_task_id}`);
    dispatch(updateNotificationPreferences(
      admin.id,
      buildingAdmin.id,
      [{
        type,
        building_task_id: building_task_id,
        enabled: value,
      },]
    )).catch(error => notify.error(format.error(error)));
  };

  // const _handleDeselectAll = event => {
  //   event.stopPropagation();
  //   dispatch(updateNotificationPreferences(
  //     admin.id,
  //     buildingAdmin.id,
  //     notifications.map(notification => ({
  //       type: notification.type,
  //       enabled: false,
  //     }))
  //   )).catch(error => notify.error(format.error(error)));
  // };

  const fields = notifications.map(notification => (
    {
      label: (
        <div className={CSS.notification}>
          <div className={CSS.display_name}>{notification.display_name}</div>
          {notification.building_task_title && (<div className={CSS.task_title}>{notification.building_task_title}</div>)}
        </div>
      ),
      type: 'checkbox',
      name: `${notification.type}_${notification.building_task_id}`,
      required: false,
      value: notification.enabled,
      className: CSS.option,
      onChange: _submitSingleValue,
      id: `${notification.type}_${buildingAdmin.id}}_${notification.building_task_id}`,
      disabled: !hasAbilities('UpdateBuildingAdminNotificationPreferences'),
    }
  ));


  return (
    <div className={CSS.notifications}>
      <div className={CSS.title}>
        <h3>Notifications</h3>
        {/* <span className={CSS.deselect} onClick={_handleDeselectAll}>Deselect all</span> */}
      </div>
      <DynamicForm
        id={`notification-form-${buildingAdmin.id}`}
        formStyle='underline'
        fields={fields}
        onSubmit={() => false}
        className={CSS.form}
      />
    </div>
  );
};
