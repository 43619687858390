import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_GET_TENANT_EVENTS',
  request: (id, params) => request.get(`/admins/building-calendars/${id}/tenant-events`, { params })
    .then(r => r?.data?.data?.tenant_events),
};

export const {
  action: getTenantEvents,
  reducer,
} = generate.redux(creator);
