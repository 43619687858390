import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DynamicForm, LoaderOverlay, Button, Uploader, ContentEditor } from '@moved/ui';
import { format, useNotify, useModal, s3, helpers } from '@moved/services';

import { ChapterPreviewModal } from '../../orientation/components/ChapterPreviewModal';

import {
  getHappycoInspectionInstructions as getInstructions,
  createHappycoInspectionInstructions as createInstructions,
  updateHappycoInspectionInstructions  as updateInstructions,
  getFileUploadUrl,
} from '../actions';
import { useHappycoInspectionInstructions } from '../actions/selectors';

import CSS from '../../../components/styles/DefaultConfiguration.module.scss';
import InstructionCSS from './styles/Instructions.module.scss';

const defaults = {
  title: 'Complete your move-in inspection',
  subtitle: '',
  cta_text: 'Continue',
};

export const Instructions = ({ task }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const modal = useModal();

  const { property_id } = useParams();
  const instructions = useHappycoInspectionInstructions(task.id);
  const [initialized, setInitialized] = useState(false);
  const [pending, setPending] = useState();

  const [formData, setFormData] = useState({
    title: instructions?.title,
    subtitle: instructions?.subtitle,
    cta_text: instructions?.cta_text,
  });
  const [content, setContent] = useState(instructions?.content);
  const [newSideImage, setNewSideImage] = useState();
  const newImagePreview = helpers.canvas.usePreview(newSideImage);

  useEffect(() => {
    dispatch(getInstructions(task.id))
      .catch(error => {
        // handle known 404 error if this task hasn't been previously configured
        if(error?.response?.status === 404) return dispatch(createInstructions(task.id,defaults));
        else notify.error(format.error(error));
      })
      .finally(() => setInitialized(true));
  },[task.id]); // eslint-disable-line

  const handleSubmit = async (data) => {
    if(pending) return;
    setPending(true);

    let sideImageUrlToSave = instructions?.side_column_image_url;

    // handle async side image saving before saving the screen data
    if(newSideImage) {
      const url = await dispatch(getFileUploadUrl(property_id,{
        filename: format.filename(newSideImage.name),
        http_content_type: newSideImage.type,
      }))
        .then(({ signed_request, url }) => s3.putFile(newSideImage,signed_request).then(() => url))
        .catch(error => { notify.error(format.error(error)) });
      if(!url) return;
      // persist the new url after upload success
      sideImageUrlToSave = url;
    } else if (newSideImage === false) {
      sideImageUrlToSave = null;
    }

    const instructionsContent = {
      ...formData,
      content,
      side_column_image_url: sideImageUrlToSave,
    };

    dispatch(updateInstructions(task.id, instructionsContent))
      .then(() => notify.default(`Instructions updated!`))
      .catch(error => notify.error(format.error(error)))
      .finally(() => setPending(false));
  };

  if(!initialized) return <LoaderOverlay />;

  const showPreviewModal = () => {
    const currentChapter = {
      ...formData,
      content,
      side_column_image_url: newSideImage !== false ? newImagePreview ?? instructions?.side_column_image_url: null,
    };
    return modal.open(
      <ChapterPreviewModal chapter={currentChapter} title={`${currentChapter.title} Preview`}/>,
    );
  };

  const handleSideImageUpdate = (files) => {
    setNewSideImage(files[0] ?? false);
  };

  const fields = [
    {
      label: 'Instruction screen details',
      type: 'title',
    },
    {
      label: 'Title',
      type: 'text',
      name: 'title',
      value: instructions?.title ?? '',
      required: 'required',
    },
    {
      label: 'Subtitle',
      type: 'text',
      name: 'subtitle',
      value: instructions?.subtitle ?? '',
    },
    {
      label: 'Primary button text',
      type: 'text',
      name: 'cta_text',
      value: instructions?.cta_text ?? '',
      required: 'required',
    },
  ];

  return (
    <>

      <div className={CSS.content_top}>
        <div className={CSS.form}>
          <DynamicForm
            id='instructions-form'
            formStyle='underline'
            fields={fields}
            onChange={setFormData}
            onSubmit={handleSubmit}
          />

          <div className='stackHorizontal gap-24 marginBottom-20 marginTop-20' style={{alignItems:'center'}}>
            <div style={{flex:'1 1 auto'}}>
              <div className='labelL contentPrimary marginBottom-4'>Body</div>
              <div className='labelS contentSecondary'>
                Edit the chapter content below.  You can see how the content will appear
                for users using the "Preview content" button. Legacy cannot be edited,
                however you may move or delete it.
              </div>
            </div>
            <Button
              color='tertiary'
              size='default'
              onClick={showPreviewModal}
              text='Preview content'
            />
          </div>
          <div className={InstructionCSS.contentEditor}>
            <ContentEditor
              id={'content-editor'}
              readOnly={false}
              disabled={null}
              data={instructions.content}
              onChange={setContent}
              minHeight={100}
              autofocus={false}
              logLevel={'ERROR'}
              customConfig={{ uploadUrl: `/admins/buildings/${property_id}/presigned-s3-urls/task-configuration/happyco-inspection` }}
            />
          </div>

          <div className='labelS contentSecondary marginBottom-12'>Side column image</div>
          <Uploader
            id='side_column_image_url'
            dzOptions={{
              accept: {
                'image/jpg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
                'image/gif': ['.gif']
              },
              multiple: false,
              maxFiles: 1,
            }}
            initialFiles={instructions?.side_column_image_url ? [{
              name: instructions?.side_column_image_url.split('/').pop(),
              src: instructions?.side_column_image_url,
            }]: []}
            onSelect={handleSideImageUpdate}
            icon={{size:'30px'}}
          />

        </div>
      </div>

      <div className={CSS.content_bottom}>
        <div className={CSS.buttons}>
          <Button
            text='Save'
            color='primary'
            form="instructions-form"
            disabled={pending}
            className={CSS.save}
          />
          <Button
            text='Discard changes'
            color='tertiary'
            disabled={pending}
            onClick={() => history.push({
              ...history.location,
              search: 'view=general',
            })}
          />
        </div>
      </div>

    </>
  );
};
