
import { Popover, Icon } from '@moved/ui';

import { RequestDetails } from '../RequestDetails';
import CSS from './styles/CalendarRequest.module.scss';

export const CalendarRequest = ({ request, building, calendar, refreshCalendar }) => {
  const showAppointmentTypeIcon = (calendar.appointment_types ?? []).filter(type => !type.is_blocking).length > 1;
  const appointmentType = calendar.appointment_types.find(type => type.id === request.appointment_type.id);

  return (
    <Popover placement='right'>
      <Popover.Trigger className={CSS.request}>
        <div className='stackHorizontal gap-4 items-center flexTruncateFix'>
          { showAppointmentTypeIcon && (
            <Icon {...appointmentType.icon} color={appointmentType.color} size='16px' />
          )}
          <span className='textTruncate'>{request.lastname}</span>
        </div>
        <span className={CSS.pending}>
          <Icon symbol={'Time-schedule'} library={'code'} size={'16px'} />
        </span>
        <span className={CSS.new}>NEW</span>
      </Popover.Trigger>
      <Popover.Content mountToBody={true} className='stackVertical gap-20'>
        <Popover.Title>Pending Request</Popover.Title>
        <RequestDetails
          request={request}
          building={building}
          refreshCalendar={refreshCalendar}
        />
      </Popover.Content>
    </Popover>
  );
};
