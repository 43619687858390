import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_GET_TENANT_BUILDING_RESERVATION_CALENDAR',
  request: (tenantEventId, buildingTaskId, calendarId) => request.get(`/admins/tenant-events/${tenantEventId}/building-tasks/${buildingTaskId}/building-reservation-calendars/${calendarId}`)
    .then(r => get(r, 'data.data.building_reservation_calendar_with_settings')),
  selector: (state, ...params) => { return get(state,`tenantBuildingReservationCalendars.${generate.uniqueKey(...params)}`); },
  reducers: {
    success: (state, { response, params }) => {
      return {
        ...state,
        tenantBuildingReservationCalendars: {
          ...state.tenantBuildingReservationCalendar,
          [generate.uniqueKey(...params)]: response,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useTenantBuildingReservationCalendar,
  usePending: useTenantBuildingReservationCalendarPending,
} = generate.redux(creator);

export const initialState = {
  tenantBuildingReservationCalendars: {},
};
