import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { useUser } from '@moved/services';
import { Popover, Icon } from '@moved/ui';

import { CreateAppointment } from './CreateAppointment';
import { CalendarAppointment } from './CalendarAppointment';
import { CalendarRequest } from './CalendarRequest';

import CSS from './styles/CalendarSlot.module.scss';

export const CalendarSlot = ({
  slot,
  date,
  requests,
  appointments,
  calendar,
  building,
  refreshCalendar,
}) => {

  // Times for placement (convert to UTC to avoid DST quirkiness)
  const midnight = moment.utc(`${date} 00:00:00`);
  const slotStart = moment.utc(`${date} ${slot.start}`);
  const slotEnd = moment.utc(`${date} ${slot.end}`);
  const slotDuration = slotEnd.diff(slotStart,'minutes');

  const { Can } = useUser();

  return (
    <div
      className={classNames(
        CSS.slot,
        {
          [CSS.slot_with_apps]: appointments.length > 0 || requests.length > 0,
          [CSS.slot_full]: appointments.length >= (slot.max_appointments ?? 1),
          [CSS.slot_small]: slotDuration < 31,
          [CSS.slot_unavailable]: !slot.is_available && appointments.length <= 0,
        },
      )}
      style={{ top: `${slotStart.diff(midnight,'minutes')-1}px`, height: `${slotDuration+1}px`,}}
    >
      <div className={CSS.slot_content}>

        <div className={CSS.slot_line} />

        <div className={CSS.slot_actions}>
          <div className={CSS.slot_top}>

            { appointments.length > 0 && (
              <div className={CSS.slot_appointments}>
                {appointments.map(appointment => (
                  <CalendarAppointment
                    key={appointment.id}
                    appointment={appointment}
                    calendar={calendar}
                    refreshCalendar={refreshCalendar}
                  />
                ))}
              </div>
            )}

            { requests.length > 0 && (
              <div className={CSS.slot_requests}>
                { requests.map(request => (
                  <CalendarRequest
                    key={request.id}
                    request={request}
                    building={building}
                    calendar={calendar}
                    refreshCalendar={refreshCalendar}
                  />
                ))}
              </div>
            )}

            <Can I='CreateBuildingAppointments'>
              { slot.is_available && (appointments.length < slot.max_appointments) && (
                <Popover
                  placement='right'
                >
                  <Popover.Trigger className={CSS.slot_adds}>
                    <div className={CSS.slot_add}>
                      <div className={CSS.slot_btn}>
                        <Icon symbol='Plus-small' library='navigation' size='20px' />
                      </div>
                    </div>
                  </Popover.Trigger>
                  <Popover.Content mountToBody={true} className='stackVertical gap-12'>
                    <Popover.Title className='stackHorizontal gap-12 justify-between items-center'>
                      <span>New appointment</span>
                      <span className='labelM contentSecondary'>
                        {[
                          slotStart.format('MMM D, YYYY | h:mm'),
                          slotEnd.format('h:mma'),
                        ].filter(Boolean).join(' - ')}
                      </span>
                    </Popover.Title>
                    <CreateAppointment calendar={calendar} slot={slot} date={date} refreshCalendar={refreshCalendar} />
                  </Popover.Content>
                </Popover>
              )}
            </Can>
          </div>

          <div className={CSS.slot_info}>
            {slot.is_available && (<span>{`${appointments.length}/${slot.max_appointments}`}</span>)}
            {!slot.is_available && appointments.length <= 0 && (<span>Unavailable</span>)}
          </div>
        </div>

      </div>
    </div>
  );
}
