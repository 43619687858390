import { BuildingSummaries } from './components/BuildingSummaries';
import { BuildingRedirect } from './components/BuildingRedirect';
import { Building } from './components/Building';
import { BuildingCalendarRedirect } from './components/BuildingCalendarRedirect';
import { BuildingCalendar } from './components/BuildingCalendar';

const routes = [
  {
    path: '/calendars',
    name: 'Calendars Section',
    component: BuildingSummaries,
    breadcrumb: {
      label: "Calendar",
      tooltip: "Calendar",
      icon: {
        symbol: 'Date-to',
        library: 'code',
      },
    },
    children: [
      {
        path: '/',
        name: 'BuildingRedirect',
        component: BuildingRedirect,
        exact: true,
      },
      {
        path: '/buildings/:buildingId(\\d+)',
        name: 'Building',
        component: Building,
        children: [
          {
            path: '/',
            name: 'BuildingCalendarRedirect',
            component: BuildingCalendarRedirect,
            exact: true,
          },
          {
            path: '/calendars/:buildingCalendarId(\\d+)',
            name: 'BuildingCalendar',
            component: BuildingCalendar,
            exact: true,
          },
        ],
      },
    ],
  },
];

export default routes;
