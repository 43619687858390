import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { useModal } from '@moved/services';

import { assetTypes } from '../assetTypes';

import CSS from './styles/ImageManager.module.scss';
import { UploadImageModal } from './modals/UploadImageModal';
import { PreviewModal } from './modals/PreviewModal';

export const ImageManager = ({ assetType, brandable, getUploadUrl, update, name, description, readonly }) => {

  const asset = {
    ...assetTypes[assetType],
    name,
    description,
  };
  const { originalKey, finalKey, allowCropping } = asset;

  // HOOKS
  const modal = useModal();

  const assetUrl = brandable?.[finalKey];
  const originalUrl = brandable?.[originalKey];

  const openCropModal = () => {
    modal.openLegacy(
      <UploadImageModal
        brandable={brandable}
        asset={asset}
        getUploadUrl={getUploadUrl}
        update={update}
        initialData={{
          original: { src: originalUrl, unchanged: true },
        }}
      />,
      { theme: 'grey', customStyles: { maxWidth:'900px' } }
    );
  };

  const openUploadModal = () => {
    modal.openLegacy(
      <UploadImageModal
        brandable={brandable}
        asset={asset}
        getUploadUrl={getUploadUrl}
        update={update}
      />,
      { theme: 'grey', customStyles: { maxWidth:'900px' } }
    );
  };

  const openPreviewModal = () => {
    modal.openLegacy(
      <PreviewModal brandable={brandable} asset={asset} />,
      { theme: 'grey', customStyles: { maxWidth:'900px' } }
    );
  };

  return (
    <div className={CSS.image_block}>
      <div className={CSS.header}>
        <div className={CSS.column}>
          <div className={CSS.title}>
            { name }
          </div>
          <p className={CSS.subtitle}>
            { description }
          </p>
        </div>
        <div className={classNames(CSS.column,CSS.actions)}>
          <button className='btn-gray btn--small' onClick={openPreviewModal}>
            <Icon library='general' symbol='Binocular' className='icon' size='22px'/>
            Preview
          </button>
        </div>
      </div>
      { assetUrl ? (
        <div className={CSS.editor}>
          <div className={CSS.thumbnail}>
            <img src={assetUrl} alt={'preview'} />
          </div>
          { !readonly && (
            <>
              { originalUrl && allowCropping && (
                <button className='btn-gray btn--small mr-10' onClick={openCropModal}>
                  <Icon library='design' symbol='Crop' color='black' className='icon' size='22px'/>
                  Crop
                </button>
              )}
              <button className='btn-ghost-red btn--small' onClick={openUploadModal}>
                Replace
              </button>
            </>
          )}
        </div>
      ) : (
        <div className={CSS.uploader}>
          <div className={CSS.thumbnail}>
            <Icon library='files' symbol='Uploaded-file'/>
          </div>
          { readonly ? (
            <span className='displayS contentPrimary'>None provided</span>
          ) : (
            <button className='btn-primary btn--small' onClick={openUploadModal}>
              Upload
            </button>
          )}
        </div>
      )}
    </div>
  );
};
