import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_CANCEL_RESERVATION',
  request: (type, id, calendar_id) => request.post(`/partners/${type}/${id}/cancel`,{}).then(r => r.data.data.appointment),
  reducers: {
    success: (state, { response, params: [type, id, calendar_id] }) => {
      return {
        ...state,
        buildingCalendarWeeks: {
          ...state.buildingCalendarWeeks,
          [calendar_id]: {
            ...state.buildingCalendarWeeks[calendar_id],
            appointments: (state.buildingCalendarWeeks[calendar_id]?.appointments ?? [])
              .filter(appointment => appointment.id !== id),
          },
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCancelReservationPending,
} = generate.redux(creator);

export const initialState = {};
