import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { get, isEmpty, cloneDeep } from 'lodash';

import { LoaderOverlay, Icon, DynamicForm } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../common';

import { getTaskDetails } from '../../../actions';
import { useTaskDetailsPending } from '../../../actions/selectors';

import { getFileRequirements, updateFileRequirement, getS3UploadUrl } from '../actions';
import { useFileRequirements, useFileRequirementsPending, useUpdateFileRequirementPending } from '../actions/selectors';

import CSS from '../../../components/styles/TaskDetails.module.scss';

const _handleReturn = (state, history, property_id, task_id) => {
  const from = get(state,'from');
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push(`/admin/properties/${property_id}/tasks/${task_id}?view=documents`);
};

export const FileRequirementDetails = () => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();

 // Get move id from URL
  const { documentID, task_id, property_id } = useParams();

  // MOVE DETAILS STATE
  const fileRequirement = useFileRequirements(documentID);
  const pending = useTaskDetailsPending();
  const contentsPending = useFileRequirementsPending();
  const updatePending = useUpdateFileRequirementPending();

  useEffect(() => {
    Promise.all([
      dispatch(getTaskDetails(task_id)),
      dispatch(getFileRequirements(documentID)),
    ])
      .catch(error => notify.error(format.error(error)));
  },[task_id, documentID, notify, dispatch]);

  const handleSubmit = (data) => {
    const formatData = cloneDeep(data);
    formatData.icon = formatData.icon.symbol;
    return dispatch(updateFileRequirement(documentID, formatData))
      .then(() => {
        notify.default(`Document requirements updated!`);
        _handleReturn(location.state, history, property_id, task_id);
      })
      .catch(error => notify.error(format.error(error)));
  };

  if(isEmpty(fileRequirement)) return (
    <>
      <Helmet>
        <title>{`Admin Abode Tasks : Moved`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  const fields = [
    {
      label: 'Document requirement details',
      type: 'title',
    },
    {
      label: 'Name',
      type: 'text',
      name: 'display_name',
      value: get(fileRequirement,'display_name'),
      required: true,
    },
    {
      label: 'Task icon',
      type: 'icon',
      name: 'icon',
      value: { symbol: get(fileRequirement,'icon'), },
      required: true,
    },
    {
      label: 'Upload template file',
      type: 'uploader',
      name: 'template_url',
      value: get(fileRequirement,'template_url'),
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(property_id,fileData)),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: false,
        multiple: false,
        maxFiles: 1,
      }
    },
    {
      label: 'Instructions',
      flavor: 'Edit the instruction content below.  You can see how the content will appear for users using the "Preview content" button. Legacy cannot be edited, however you may move or delete it.',
      type: 'editor',
      name: 'instructions',
      value: get(fileRequirement,'instructions'),
      required: true,
      renderer: (value) => (<AdminContentRenderer content={value} />)
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`${get(fileRequirement,'display_name')} Admin Abode Tasks : Moved`}</title>
      </Helmet>

      {(pending || contentsPending) && (<LoaderOverlay />)}

      <div className={CSS.content_top}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            <div className={CSS.back_btn} onClick={e => _handleReturn(location.state, history, property_id, task_id)}>
              <Icon symbol='Arrow-left' library='navigation' size='32px' />
            </div>
            <h1>{get(fileRequirement,'display_name')}</h1>
          </div>
        </div>

        <div className={CSS.form}>
          <div className={CSS.attributes}>
            <DynamicForm
              id='chapter-form'
              formStyle='underline'
              fields={fields}
              onSubmit={handleSubmit}
            />
          </div>
        </div>

      </div>

      <div className={CSS.content_bottom}>
        <div className={CSS.buttons}>
          <label
            id='chapter_form_button'
            htmlFor="chapter-form-submit"
            tabIndex="0"
            role="button"
            className={classNames('btn-primary', CSS.save, {'loading': updatePending})}
            disabled={updatePending}
          >
            Save
          </label>

          <label
            tabIndex="1"
            role="button"
            className={classNames('btn-ghost')}
            disabled={updatePending}
            onClick={e => {
              e.preventDefault();
              if (updatePending) return;
              return _handleReturn(location.state, history, property_id, task_id);
            }}
          >
            Discard changes
          </label>
        </div>
      </div>

    </>
  );
};
