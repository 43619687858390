import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { getTaskDetails, } from '../actions';
import { useTaskDetails, useTaskDetailsPending } from '../actions/selectors';

import CSS from './styles/Task.module.scss';

export const Task = ({ children }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { task_id } = useParams();

  const task = useTaskDetails(task_id);
  const pending = useTaskDetailsPending();

  useEffect(() => {
    dispatch(getTaskDetails(task_id))
      .catch(err => notify.error(format.error(err)));
  },[task_id, dispatch, notify]);

  return (
    <>
      <Helmet>
        <title>{`${task && get(task,'title')} Admin Abode Tasks : Moved`}</title>
      </Helmet>

      { pending && <LoaderOverlay /> }

      { task && (
        <div className={CSS.content}>{ children }</div>
      )}

    </>
  );
};
