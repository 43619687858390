import { merge } from 'lodash';

import * as createVendor from './createVendor';
import * as getSpecialItems from './getSpecialItems';
import * as getVendor from './getVendor';
import * as inviteVendor from './inviteVendor';
import * as updateVendor from './updateVendor';
import * as vendorSearch from './vendorSearch';

export const initialState = merge(
  createVendor.initialState,
  getSpecialItems.initialState,
  getVendor.initialState,
  inviteVendor.initialState,
  updateVendor.initialState,
  vendorSearch.initialState,
);

export const reducers = [
  createVendor.reducer,
  getSpecialItems.reducer,
  getVendor.reducer,
  inviteVendor.reducer,
  updateVendor.reducer,
  vendorSearch.reducer,
];
