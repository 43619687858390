import { merge } from 'lodash';

import * as cancelAppointment from './cancelAppointment';
import * as createAppointment from './createAppointment';
import * as rescheduleAppointment from './rescheduleAppointment';
import * as getBuildingPendingRequests from './getBuildingPendingRequests';
import * as getBuildingRepliedRequests from './getBuildingRepliedRequests';
import * as getBuildingCalendarSummaries from './getBuildingCalendarSummaries';
import * as getBuildingCalendarWeek from './getBuildingCalendarWeek';
import * as getTenantEvents from './getTenantEvents';
import * as updateAppointmentRequest from './updateAppointmentRequest';

export const initialState = merge(
  cancelAppointment.initialState,
  createAppointment.initialState,
  rescheduleAppointment.initialState,
  getBuildingPendingRequests.initialState,
  getBuildingRepliedRequests.initialState,
  getBuildingCalendarSummaries.initialState,
  getBuildingCalendarWeek.initialState,
  updateAppointmentRequest.initialState,
);

export const reducers = [
  cancelAppointment.reducer,
  createAppointment.reducer,
  rescheduleAppointment.reducer,
  getBuildingPendingRequests.reducer,
  getBuildingRepliedRequests.reducer,
  getBuildingCalendarSummaries.reducer,
  getBuildingCalendarWeek.reducer,
  getTenantEvents.reducer,
  updateAppointmentRequest.reducer,
];
