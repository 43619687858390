import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, AtomSpinner } from '@moved/ui';

import { getMultiplePartnerCoupons } from '../../actions';
import { useMultiplePartnerCoupons, useMultiplePartnerCouponsPending } from '../../actions/selectors';

import CSS from '../styles/AddCouponModal.module.scss';

const validation = Yup.object().shape({
  coupon_id: Yup.number().integer()
    .required('Must choose a coupon from the list'),
});

export const AddCouponModal = ({ move, addHandler, addPendingSelector }) => {
  const partners = get(move,'move_steps',[]).map(step => get(step,'building.partner')).filter(v=>v);
  const [partnerIds] = useState(partners.map(partner => get(partner,'id'))); // set and never recalculate to avoid rerenders

  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const coupons = useMultiplePartnerCoupons(partnerIds);
  const pending = useMultiplePartnerCouponsPending();
  const addPending = addPendingSelector();

  const getPartnerName = (id) => get(partners.find(partner => partner.id === id),'name');

  useEffect(() => {
    dispatch(getMultiplePartnerCoupons(partnerIds))
      .catch(error => notify.error(format.error(error)));
  },[partnerIds]); // eslint-disable-line

  if(pending) return <AtomSpinner/>;

  const fields = [{
    type: 'select',
    name: 'coupon_id',
    placeholder: 'Choose Coupon',
    value: '',
    options: coupons.map(coupon => ({
      label: `${coupon.type === 'cents' ? format.currency(coupon.cents) : format.percent(coupon.percent)} Off — ${getPartnerName(coupon.partner_id)}`,
      value: coupon.id
    })),
  }];

  const handleSubmit = (data) => {
    addHandler(data) // allows parent to pass in action (add to order or add to move)
      .then(() => {
        notify.default(`Coupon Added!`);
        modal.close();
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Add Coupon</h3>
    { coupons.length > 0 ? (
      <>
        <section className={classNames(CSS.section)}>
          <DynamicForm
            id='coupon-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
            validation={validation}
          />
        </section>
        <section className={classNames(CSS.actions)}>
          <button className={classNames('btn-primary',{loading:addPending})} type='submit' form='coupon-form'>Add</button>
        </section>
      </>
    ) : (
      <section className={classNames(CSS.section,CSS.none)}>
        This move's partners do not support any coupons yet.<br/>
        Call JoeBot.
      </section>
    )}
  </>);

}
