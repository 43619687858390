import React from 'react';

import { useUser } from '@moved/services';
import { Icon, PopoverMenu } from '@moved/ui';

import CSS from './styles/ProfileLink.module.scss';

export const ProfileLink = ({ signOut, }) => {
  const { user } = useUser();

  return (
    <div className={CSS.wrapper}>
      <PopoverMenu placement='top'>
        <PopoverMenu.Trigger className={CSS.profile_link} activeClassName={CSS.profile_active}>
          <div className={CSS.content}>
            <span className={CSS.name}>{ user.fullname }</span>
            <span className={CSS.label}>Admin</span>
            <span className={CSS.arrow}>
              <Icon className={CSS.icon} symbol='Chevron-down' library='navigation' alt='Profile' role='img'/>
            </span>
          </div>
        </PopoverMenu.Trigger>
        <PopoverMenu.Content
          className={CSS.user_popover}
          large
        >
          <a href={`${process.env.REACT_APP_CONSUMER_APP_URL}/profile`}>
            <PopoverMenu.Item
              label='Profile'
              icon={{ symbol: 'User', library: 'general' }}
            />
          </a>
          <PopoverMenu.Item
            label='Sign out'
            icon={{ symbol: 'Sign-out', library: 'navigation' }}
            onClick={signOut}
          />
        </PopoverMenu.Content>
      </PopoverMenu>
    </div>
  );
};
