import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, LoaderOverlay } from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';
import { AdminContentRenderer } from '../../../../../../common';

import { getBuildingCalendar, updateBuildingCalendar, getBuildingCalendarOptions } from '../actions';
import { useBuildingCalendar, useBuildingCalendarPending } from '../actions/getBuildingCalendar';

import CSS from './styles/BuildingCalendar.module.scss';

const _handleReturn = (state, history, property_id, task_id) => {
    const from = get(state,'from');
    // If user came from search, it will be stored in state.from
    if(from) history.push(from);
    // else go to the task details general tab
    else history.push(`/admin/properties/${property_id}/tasks/${task_id}`);
  };

export const BuildingCalendar = ({ calendar_id }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const { hasAbilities, Can } = useUser();
  const [savePending, setSavePending] = useState();

 // Get id from URL
  const { task_id, property_id } = useParams();

  // REDUX
  const calendar = useBuildingCalendar(calendar_id)
  const pending = useBuildingCalendarPending();

  useEffect(() => {
    dispatch(getBuildingCalendar(calendar_id));
  }, [dispatch, calendar_id]);

  const handleSubmit = (data) => {
    // Remove address from payload else empty string throws error in the backend
    if (data.address === '') delete data.address;

    setSavePending(true);
    dispatch(updateBuildingCalendar(calendar.id, data))
      .then(() => { notify.default(`Calendar updated!`);})
      .then(() => dispatch(getBuildingCalendarOptions(property_id))) // Need to call this to update the Building Calendar names in the tab slider
      .catch(error => notify.error(format.error(error)))
      .finally(() => setSavePending(false));
  };

  if(pending || !calendar) return (<LoaderOverlay />);

  const isReadOnly = !hasAbilities('UpdateBuildingCalendars');

  let fields = [
    {
      label: (get(calendar, 'name') || 'Calendar') + ' details',
      type: 'title',
    },
    {
      label: 'Name',
      type: 'text',
      name: 'name',
      value: get(calendar, 'name'),
      readOnly: isReadOnly,
    },
    {
      label: 'Booking Type',
      type: 'select',
      name: 'booking_type',
      value: get(calendar, 'booking_type'),
      readOnly: isReadOnly,
      options: [
        {
          label: 'All Day',
          value: 'all-day'
        },
        {
          label: 'Appointment Slot',
          value: 'appointment-slot'
        }
      ]
    },
    {
      label: 'Location',
      type: 'googlePlace',
      name: 'address',
      value: get(calendar, 'address'),
    },
    {
      label: 'Additional instructions',
      type: 'editor',
      name: 'instructions',
      value: get(calendar, 'instructions'),
      renderer: (value) => (<AdminContentRenderer content={value} />)
    },
  ].filter(v=>v);

  return (
    <>

      <div className={CSS.content_top}>
        <div className={CSS.attributes}>
          <DynamicForm
            id='task-form'
            formStyle='underline'
            fields={fields}
            onSubmit={handleSubmit}
          />
        </div>
      </div>

      <div className={CSS.content_bottom}>
        <Can I="UpdateBuildingCalendars">
          <div className={CSS.buttons}>
            <label
              id='task_form_button'
              htmlFor="task-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', CSS.save, {'loading': savePending})}
              disabled={savePending}
            >
              Save
            </label>

            <label
              tabIndex="1"
              role="button"
              className={classNames('btn-ghost')}
              disabled={savePending}
              onClick={e => {
                e.preventDefault();
                if (savePending) return;
                return _handleReturn(location.state, history, property_id, task_id);
              }}
            >
              Discard changes
            </label>
          </div>
        </Can>
      </div>

    </>
  );
};
