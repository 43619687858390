import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'ADMIN_UPDATE_HAPPYCO_INSPECTION_INSTRUCTIONS',
  request: (taskId,data={}) => request.patch(`/admins/building-tasks/${taskId}/happyco-inspection-instructions`,data).then(r => get(r, 'data.data.instruction_content')),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      happycoInspectionInstructions: {
        ...state.happycoInspectionInstructions,
        [id]: response,
      }
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateHappycoInspectionInstructionsPending,
} = generate.redux(creator);

export const initialState = {};
