import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_CREATE_KEY_PICKUP',
  request: (data={}) => request.post(`/partners/key-pickup-appointments`, data)
    .then(r => r?.data?.data?.appointment),
  reducers: {
    success: (state, { response: appointment, params: [{ building_calendar_id }] }) => {
      return {
        ...state,
        buildingCalendarWeeks: {
          ...state.buildingCalendarWeeks,
          [building_calendar_id]: {
            ...state.buildingCalendarWeeks[building_calendar_id],
            appointments: [
              ...(state.buildingCalendarWeeks[building_calendar_id].appointments ?? []),
              appointment,
            ],
          },
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useCreateKeyPickupPending,
} = generate.redux(creator);

export const initialState = {};
