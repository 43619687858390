import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { get, noop } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Form, FormSelect, FormTextarea, AtomSpinner } from '@moved/ui';

import {
  getTenantBuildingReservationOptions,
  createBuildingReservation,
  createBuildingReservationOverride,
} from '../actions';
import { useTenantBuildingReservationOptions } from '../actions/selectors';

import { ReservationSchedule } from './ReservationSchedule';
import { ReservationList } from './ReservationList';

const OPT_OUT_VALUE = 'override';

export const CompletionForm = ({ task, tenantEvent, submitting, setSubmitting, onSuccess=noop }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  // state management
  const [pending, setPending] = useState(true);
  const [selectedCalendarId, setSelectedCalendarId] = useState();

  // configuration data
  const {
    building_calendars: calendars=[],
    roommate_reservations: roommateReservations=[],
  } = useTenantBuildingReservationOptions(tenantEvent.id,task.building_task_id) || {};

  const reservationOptions = [
    ...calendars.map(({name, id}) => ({
      label: name || 'Make a reservation',
      value: id,
    })),
    {
      label: 'Decline to make a reservation',
      value: OPT_OUT_VALUE,
    },
  ];

  // fetch configuration data
  useEffect(() => {
    dispatch(getTenantBuildingReservationOptions(tenantEvent.id,task.building_task_id))
      .then(({ building_calendars=[] }) => {
        if(building_calendars.length > 0) setSelectedCalendarId(building_calendars[0].id);
        setPending(false);
      })
      .catch(err => notify.error(format.error(err)));
  },[tenantEvent,task,dispatch,notify]);

  // submission handler
  const submit = async ({ reservation_date, reservation_time, building_calendar_id, notes }) => {
    if(submitting) return;
    setSubmitting(true);
    const submissionRequest = (building_calendar_id === OPT_OUT_VALUE) ? (
      dispatch(createBuildingReservationOverride(tenantEvent.id, task.building_task_id, { notes }))
    ) : (
      dispatch(createBuildingReservation(tenantEvent.id, task.building_task_id, {
        building_calendar_id,
        start_time: `${reservation_date}T${reservation_time || '00:00:00'}`,
      }))
    );
    submissionRequest.then(onSuccess)
      .catch(err => {
        setSubmitting(false);
        notify.error(format.error(err));
      });
  };

  const validation = Yup.object({
    building_calendar_id: Yup.mixed().required('You must select a reservation option').nullable(),
    reservation_date: Yup.string().required('You must select a reservation date'),
    reservation_time: Yup.string().when('building_calendar_id', (id, schema) => {
      const calendar = calendars.find(calendar => calendar.id === id);
      return get(calendar,'booking_type') === 'appointment-slot' ?
        schema.required('You must select a reservation time').nullable() :
        schema.optional().nullable();
    }),
  });

  if(pending) return <AtomSpinner/>;

  return (
    <Form
      name='artifact-form'
      validation={validation}
      onSubmit={submit}
      className='stackVertical gap-40'
    >
      <section className='stackVertical gap-12'>
        <div className='labelL contentPrimary'>
          Reservation details
        </div>
        <FormSelect
          name='building_calendar_id'
          label='Elevator / Loading Dock / Moving Option'
          options={reservationOptions}
          value={selectedCalendarId}
          readOnly={reservationOptions.length === 1}
          onChange={({ building_calendar_id }) => setSelectedCalendarId(building_calendar_id)}
        />
        { selectedCalendarId && selectedCalendarId !== OPT_OUT_VALUE && (
          <ReservationSchedule
            calendarId={selectedCalendarId}
            tenantEvent={tenantEvent}
            task={task}
          />
        )}
        { selectedCalendarId && selectedCalendarId === OPT_OUT_VALUE && (
          <FormTextarea
            name='notes'
            value=''
            label='Notes'
          />
        )}
      </section>

      { roommateReservations.length > 0 && (
        <section className='stackVertical gap-12'>
          <div className='labelM contentPrimary'>
            Existing lease reservations
          </div>
          <ReservationList reservations={roommateReservations} />
        </section>
      )}
    </Form>
  );
};
