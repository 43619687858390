import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { get, cloneDeep, max, noop } from 'lodash';

import { LoaderOverlay, Icon, DragDropList, ConfirmationModal, PopOver } from '@moved/ui';
import { format, useNotify, useModal, useUser } from '@moved/services';

import { useTaskDetails } from '../../../actions/selectors';
import { getOrientationContents, reorderOrientationContents, removeOrientationChapter } from '../actions';
import { useOrientationContents, useOrientationContentsPending, useReorderOrientationContentsPending, useRemoveOrientationChapterPending } from '../actions/selectors';

import { TaskHeader } from '../../../components';

import { OrientationNavigation } from './OrientationNavigation';
import { CreateChapterModal } from './createChapter/CreateChapterModal';
import { CloneChaptersModal } from './cloneChapter/CloneChaptersModal';

import CSS from './styles/ChapterList.module.scss';

export const ChapterList = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { task_id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const modal = useModal();
  const notify = useNotify();
  const { Can, hasAbilities } = useUser();

  // REDUX
  const task = useTaskDetails(task_id);
  const chapters = useOrientationContents(task_id);
  const contentsPending  = useOrientationContentsPending();
  const reorderPending = useReorderOrientationContentsPending();
  const removeChapterPending = useRemoveOrientationChapterPending();
  const pending = contentsPending || reorderPending || removeChapterPending;

  // STATE
  const [currentChapters, setCurrentChapters] = useState(cloneDeep(chapters));

  useEffect(() => {
    dispatch(getOrientationContents(task_id));
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
    setCurrentChapters(cloneDeep(chapters));
  // eslint-disable-next-line
  },[chapters]);


  if(!currentChapters) return (<LoaderOverlay />);

  // Update state of chapters after drag
  const updateChapterOrder = result => {
    if(!result) return null;
    return setCurrentChapters(get(result,'chapters.list'));
  };

  const saveChapterOrder = () => {
    return dispatch(reorderOrientationContents(
      task_id,
      { content_editor_content_ids: currentChapters.map(chapter => chapter.id) }
    )).then(() => notify.default('Chapter order has been saved.'));
  };

  const openCreateChapterModal = () => {
    const maxOrder = currentChapters.length > 0 ? max(currentChapters.map(i => i.order))+1 : 1;
    return modal.open(<CreateChapterModal taskId={task_id} order={maxOrder} />);
  };

  const openCloneChaptersModal = () =>  modal.openLegacy(
    <CloneChaptersModal id={task_id} />,
    { hideCloseButton: false, theme: 'grey' }
  );

  return (
    <div className={CSS.wrapper}>
      {pending && (<LoaderOverlay />)}

      <TaskHeader task={task} />

      <OrientationNavigation />

      <Can I={'ReadContentEditorContents'}>
        <div className={CSS.chapters}>
          <DragDropList
            lists={{
              chapters: {
                title: (
                  <div className={CSS.section_title}>
                    <h3>Chapters</h3>
                    <div className={CSS.chapter_actions}>
                      <Can I={'CloneContentEditorContents'}>
                        <div className={CSS.clone} onClick={openCloneChaptersModal}>
                          <Icon symbol='Duplicate' library='general' size="16px" />
                          <span>Clone chapters</span>
                        </div>
                      </Can>
                      <div className={CSS.new_chapter} onClick={openCreateChapterModal}>
                        <Icon symbol='Plus' library='navigation' size="16px" />
                        <span>Add chapter</span>
                      </div>
                    </div>
                  </div>
                ),
                list: currentChapters,
                contentLeft: chapter => (
                  <div
                    className={CSS.title}
                    onClick={
                      hasAbilities('UpdateContentEditorContents')
                      ? e => { e.preventDefault(); history.push(`${location.pathname}/${chapter.id}`); }
                      : noop
                    }
                  >
                    <h4>{chapter.title}</h4>
                  </div>
                ),
                contentRight: chapter => (
                  <div className={CSS.icons}>
                    <ChapterActionsMenu chapter={chapter} task={task} />
                  </div>
                ),
              },
            }}
            onChange={updateChapterOrder}
          />
        </div>
        <Can I={'UpdateContentEditorContents'}>
          <div className={CSS.buttons}>
            <label
              role="button"
              onClick={saveChapterOrder}
              className={classNames('btn-primary', CSS.save, {'loading': reorderPending})}
              disabled={reorderPending}
            >
              Save
            </label>
          </div>
        </Can>
      </Can>
    </div>
  );
};

const ChapterActionsMenu = ({ chapter, task }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const modal = useModal();
  const notify = useNotify();
  const { Can } = useUser();

  // Remove chapter handler
  const deleteChapter = chapter => dispatch(removeOrientationChapter(chapter.id, task.id))
    .then(() => notify.default(`${chapter.title} chapter has been removed.`))
    .catch(error => notify.error(format.error(error)));

  const openRemoveChapterModal = chapter => modal.open(
    <ConfirmationModal
      title={`Remove chapter`}
      copy={`Are you sure you wish to remove the "${chapter.title}" chapter? This action cannot be undone.`}
      confirmText='Confirm'
      cancelText='Never mind'
      onConfirm={e => deleteChapter(chapter)}
    />
  );

  const openCloneChaptersModal = () =>  modal.openLegacy(
    <CloneChaptersModal
      id={task.id}
      single={true}
      initialData={{ content_editor_content_ids: [chapter.id] }}
    />,
    { hideCloseButton: false, theme: 'grey' }
  );

  return (
    <PopOver
      id={`more_${chapter.id}`}
      customClass={CSS.property_popover}
      className={classNames(CSS.icon,CSS.icon_more)}
      hideArrow="true"
      placement="bottom-end"
      trigger="click"
      delayHide={400}
      tooltip={(
        <div id={'popover_more_options'}>
          <Can I={'UpdateContentEditorContents'}>
            <div
              className={CSS.popover_action}
              onClick={e => { e.preventDefault(); history.push(`${location.pathname}/${chapter.id}`); }}
            >
              <Icon symbol={'Edit'} library={'design'} size={'20px'} className={CSS.popover_icon} />
              <span className={CSS.popover_link}>Edit</span>
            </div>
          </Can>
          <Can I={'CloneContentEditorContents'}>
            <div
              className={CSS.popover_action}
              onClick={() => openCloneChaptersModal()}
            >
              <Icon symbol={'Duplicate'} library={'general'} size={'20px'} className={CSS.popover_icon} />
              <span className={CSS.popover_link}>Clone</span>
            </div>
          </Can>
          <Can I={'DeleteContentEditorContents'}>
            <div className={classNames(CSS.popover_action, CSS.delete)} onClick={e => openRemoveChapterModal(chapter)}>
              <Icon symbol={'Trash'} library={'general'} size={'20px'} className={CSS.popover_icon} />
              <span className={CSS.popover_link}>Delete</span>
            </div>
          </Can>
        </div>
      )}
      stopPropagation
    >
      <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
    </PopOver>
  );
};
