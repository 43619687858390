import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_GET_BUILDING_REPLIED_REQUESTS',
  request: (buildingId) => request.get(`/admins/buildings/${buildingId}/replied-calendar-requests`)
    .then(r => r.data.data.building_calendar_requests),
  selector: (state, buildingId) => state?.buildingRepliedRequests?.[buildingId] ?? [],
  reducers: {
    success: (state, { response, params: [buildingId] }) => ({
      ...state,
      buildingRepliedRequests: {
        ...state.buildingRepliedRequests,
        [buildingId]: response,
      },
    }),
  }
};

export const {
  action: getBuildingRepliedRequests,
  reducer,
  useResource: useBuildingRepliedRequests,
  usePending: useBuildingRepliedRequestsPending,
} = generate.redux(creator);

export const initialState = {
  buildingRepliedRequests: {},
};
