import React from 'react';
import { useModal } from '@moved/services';

import { Action } from './Action';
import { AddPaymentModal } from '../modals/AddPaymentModal';
import { canAddPayment } from '../../helpers';

export const AddPayment = ({ order, ...props }) => {
  const modal = useModal();
  // early exit
  if(!canAddPayment(order)) return null;
  // create the action button
  const openModal = () => modal.openLegacy(<AddPaymentModal order={order} />);
  const icon = {
    library: 'shopping',
    symbol: 'Credit-card',
  };
  return <Action onClick={openModal} icon={icon} {...props}>Add Payment</Action>;
};
