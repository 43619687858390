import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import { Drawer, Button, LoaderOverlay } from '@moved/ui';

import { getTenantEvent } from '../actions';
import { taskTypes } from '../../../configuration/tasks/types';

export const AdminTaskCompletionDrawer = ({ task, tenantEvent }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const [submitting, setSubmitting] = useState();

  const { CompletionForm } = taskTypes[task.task_type] || {};

  const onSuccess = async () => {
    notify.default(`"${task.title}" task marked as complete`);
    await dispatch(getTenantEvent(tenantEvent.id))
      .catch(err => notify.error(format.error(err)));
    drawer.close(true);
  };

  return (
    <Drawer>
      { submitting && <LoaderOverlay /> }
      <Drawer.Header title={task.title} description='Complete this task on behalf of the tenant' />
      <Drawer.Content>
        { CompletionForm ? (
          <CompletionForm
            task={task}
            tenantEvent={tenantEvent}
            submitting={submitting}
            setSubmitting={setSubmitting}
            onSuccess={onSuccess}
          />
        ) : (
          <div className='contentPrimary labelL'>This task does not yet support this feature.</div>
        )}
      </Drawer.Content>
      <Drawer.Actions>
        <Button text='Cancel' color='secondary' onClick={() => drawer.close()} />
        { CompletionForm && (
          <Button text='Submit' color='primary' form='artifact-form' type='submit' disabled={submitting} />
        )}
      </Drawer.Actions>
    </Drawer>
  );
};
