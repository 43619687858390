import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useNotify, format, useUser } from '@moved/services';
import { LoaderOverlay, AtomSpinner } from '@moved/ui';

import { useClient } from '../../../../common/actions/selectors';
import { useBuildings } from '../../moves/actions/getBuildings';
import { getBuildingCalendarWeek, useBuildingCalendarWeek } from '../actions/getBuildingCalendarWeek';
import { useBuildingCalendarSummaries } from '../actions/getBuildingCalendarSummaries';

import { BuildingCalendarNavigation } from './BuildingCalendarNavigation';
import { CalendarToolbar } from './CalendarToolbar';
import { CalendarWeek } from './CalendarWeek/CalendarWeek';

import CSS from './styles/BuildingCalendar.module.scss';

export const BuildingCalendar = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { getDomainId } = useUser();
  const { buildingId, buildingCalendarId } = useParams();

  const client = useClient(getDomainId('client'));

  const buildings = useBuildings();
  const activeBuilding = buildings.find(building => building.id === parseInt(buildingId));

  const calendarList = useBuildingCalendarSummaries(activeBuilding.id);
  const activeCalendar = calendarList.find(calendar => calendar.id === parseInt(buildingCalendarId));

  const [activeDate, setActiveDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  const weeklyCalendar = useBuildingCalendarWeek(buildingCalendarId, activeDate);

  const [loading, setLoading] = useState(true);

  const refreshCalendar = useCallback(() => {
    if(!buildingCalendarId) return;
    setLoading(true);
    dispatch(getBuildingCalendarWeek(buildingCalendarId, activeDate))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setLoading(false));
  },[activeDate, buildingCalendarId, dispatch, notify]);

  useEffect(() => {
    refreshCalendar();
  },[refreshCalendar]);

  // Render spinner until necessary data is loaded
  if(!activeBuilding) return <LoaderOverlay />;

  return (
    <div
      className='stackVertical gap-20 padding-32-80'
      style={{height: 'calc(100% - 40px)', overflow: 'hidden'}} /* full-height minus 40 */
    >

      <Helmet>
        <title>{`${activeBuilding?.name ?? client.name} Calendar : Moved`}</title>
      </Helmet>

      <div className='stackVertical gap-8'>
        <BuildingCalendarNavigation
          activeBuilding={activeBuilding}
          buildingCalendarId={parseInt(buildingCalendarId)}
        />
        <div className='dividerTertiary' />
      </div>

      <div className='stackVertical gap-12 height-full'>

        <CalendarToolbar activeDate={activeDate} setActiveDate={setActiveDate} refreshCalendar={refreshCalendar} />

        <div className={CSS.calendar}>
          { !weeklyCalendar ? (
            <div className='stackVertical justify-center height-full'>
              <AtomSpinner size={60}/>
            </div>
          ) : (
            <CalendarWeek
              building={activeBuilding}
              activeCalendar={activeCalendar}
              activeDate={activeDate}
              weeklyCalendar={weeklyCalendar}
              weeklyCalendarPending={loading}
              refreshCalendar={refreshCalendar}
            />
          )}
        </div>

      </div>

    </div>
  );
};
