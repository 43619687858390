import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay } from '@moved/ui';
import { useNotify, format, useUser } from '@moved/services';

import { DefaultConfiguration } from '../../../components/DefaultConfiguration';
import { getUtilitySetup, updateUtilitySetup, getUtilityCategories } from '../actions';
import { useUtilitySetupDetails, useUpdateUtilitySetupPending, useUtilityCategories } from '../actions/selectors';

export const Configuration = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { task_id } = useParams();
  const notify = useNotify();

  // REDUX
  const utilityRequirements = useUtilitySetupDetails(task_id);
  const categories = useUtilityCategories();
  const updatePending = useUpdateUtilitySetupPending();
  const { hasAbilities } = useUser();

  // STATE
  const [activeUtilityCategoryId, setActiveUtilityCategoryId] = useState();
  const [pending, setPending] = useState(true);

  const electricCategoryId = (utilityRequirements && categories) && categories.find(category => category.name.toLowerCase() === 'electric')?.id;
  const showGridRewards = (electricCategoryId === activeUtilityCategoryId);

  useEffect(() => {
    const fetchData = async () => {
      const [utilityRequirements] = await Promise.all([
        dispatch(getUtilitySetup(task_id)),
        dispatch(getUtilityCategories()),
      ]);
      setActiveUtilityCategoryId(get(utilityRequirements,'utility_category.id'));
      setPending(false);
    };

    fetchData();
  },[task_id, dispatch]);

  const _handleSubmit = data => {
    // if this form is readonly because of permissions, this function should do nothing
    if(!(hasAbilities('ReadBuildingUtilityProviderRequirements') && hasAbilities('UpdateBuildingUtilityProviderRequirements'))) return false;
    // if form is invalid, this function should return a rejected promise with the error to display to the parent form promise chain handler
    if(!(data.account_number_required || data.confirmation_number_required || data.upload_required))
      return Promise.reject(new Error('Must require at least one of "Account number", "Confirmation number", or "Upload proof"'));

    const cleanData = {
      ...data,
      gridrewards_enabled: showGridRewards ? data.gridrewards_enabled : false,
    };

    return dispatch(updateUtilitySetup(task_id, cleanData))
      .catch(error => notify.error(format.error(error)));
  };

  if(!utilityRequirements || !categories || pending) return (<LoaderOverlay />);

  const fields = hasAbilities('ReadBuildingUtilityProviderRequirements') ? [
    hasAbilities('ReadUtilityCategories') && {
      name: 'utility_category_id',
      label: 'Utility category',
      type: 'select',
      required: true,
      options: categories.map(category => ({label: category.name, value: category.id})),
      value: activeUtilityCategoryId,
      readOnly: !hasAbilities('UpdateBuildingUtilityProviderRequirements'),
      onChange: (option) => {
        setActiveUtilityCategoryId(option.value);
      }
    },
    {
      name: 'account_number_required',
      label: 'Account number required',
      type: 'toggle',
      value: get(utilityRequirements, 'account_number_required') || false,
      disabled: !hasAbilities('UpdateBuildingUtilityProviderRequirements'),
    },
    {
      name: 'confirmation_number_required',
      label: 'Confirmation number required',
      type: 'toggle',
      value: get(utilityRequirements, 'confirmation_number_required') || false,
      disabled: !hasAbilities('UpdateBuildingUtilityProviderRequirements'),
    },
    {
      name: 'upload_required',
      label: 'Upload proof required',
      type: 'toggle',
      value: get(utilityRequirements, 'upload_required') || false,
      disabled: !hasAbilities('UpdateBuildingUtilityProviderRequirements'),
    },
    {
      name: 'gridrewards_enabled',
      label: 'Enable GridRewards',
      type: 'toggle',
      value: get(utilityRequirements, 'gridrewards_enabled') || false,
      disabled: !hasAbilities('UpdateBuildingUtilityProviderRequirements'),
      hidden: !showGridRewards,
    },
  ].filter(v=>v) : [];

  return (
    <>
      {pending && (<LoaderOverlay />)}
      <DefaultConfiguration
        task={task}
        typeSubmit={_handleSubmit}
        typePending={updatePending}
        typeFields={fields}
      />
    </>
  );
};
