import React, { useState, createContext, useContext } from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import CSS from './Popover.module.scss';

const PopoverContext = createContext();
export const usePopover = () => useContext(PopoverContext);

/**
 * A popover component that wraps a trigger and content component
 * https://www.npmjs.com/package/react-popper-tooltip
 */

export const Popover = ({
  children,
  placement='top',
  initialOpen,
  // Advanced properties
  closeOnOutsideClick=true,
  offset=[0,18],
  trigger='click',
  delayShow=0,
  delayHide=0,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen === true);
  const hide = () => setIsOpen(false);
  const config = {
    visible: isOpen,
    defaultVisible: initialOpen,
    closeOnOutsideClick,
    trigger,
    placement,
    offset,
    delayShow,
    delayHide,
    interactive: true,
    onVisibleChange: (isVisible) => {
      if(isOpen !== isVisible) setIsOpen(isVisible);
    },
  };
  const parent = usePopover(); // in case this popover is nested in another popover, expose parent api nested in this context
  const popoverAPI = usePopperTooltip(config);

  return (
    <PopoverContext.Provider value={{ ...popoverAPI, hide, parent }}>
      { children }
    </PopoverContext.Provider>
  );
};
Popover.propTypes = {
  /** the context wrapper for the popover */
  children: PropTypes.node.isRequired,
  /** override the default placement direction of the popover content */
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end'
  ]),
  /** boolean value to load the popover in the open state */
  initialOpen: PropTypes.bool,
  /** (advanced) override whether clicking outside the popover closes the content */
  closeOnOutsideClick: PropTypes.bool,
  /** (advanced) override the positional offset from the trigger [xOffset,yOffset] */
  offset: PropTypes.arrayOf(PropTypes.number),
  /** (advanced) override the event type that opens the tooltip */
  trigger: PropTypes.oneOf(['click','hover','focus']),
  /** (advanced) override delay (in ms) of showing the tooltip */
  delayShow: PropTypes.number,
  /** (advanced) override delay (in ms) of hiding the tooltip */
  delayHide: PropTypes.number,
};

const Trigger = ({ children, className, onClick, activeClassName }) => {
  const { setTriggerRef, visible } = usePopover();
  return (
    <div
      ref={setTriggerRef}
      className={classNames(
        CSS.trigger,
        className,
        {[activeClassName]: activeClassName && visible}
      )}
      onClick={onClick}
    >
      { children }
    </div>
  );
};

Trigger.propTypes = {
  /** the content to be treated as the trigger for the popover */
  children: PropTypes.node.isRequired,
  /** add additional handlers to the click event on the trigger */
  onClick: PropTypes.func,
  /** className to add to the trigger element wrapper */
  className: PropTypes.string,
  /** activeClassName to add to the trigger element wrapper when visible */
  activeClassName: PropTypes.string,
};

const Content = ({ children, className, mountToBody, showArrow=true, onClick }) => {
  const {
    getArrowProps,
    setTooltipRef,
    getTooltipProps,
    visible,
  } = usePopover();
  const tooltipProps = getTooltipProps({
    className: classNames(CSS.content, {[CSS.withArrow]: showArrow}, className),
  });
  const arrowProps = getArrowProps({
    className: CSS.arrow,
  });

  const contentNode = (
    <CSSTransition
      in={visible}
      timeout={parseInt(CSS.transitionDuration)}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div onClick={onClick}>
        <div ref={setTooltipRef} {...tooltipProps}>
          { showArrow && <div {...arrowProps} /> }
          { children }
        </div>
      </div>
    </CSSTransition>
  );

  return mountToBody ? ReactDOM.createPortal(contentNode, document.body) : contentNode;
};
Content.propTypes = {
  /** the content to be rendered inside the popover */
  children: PropTypes.node.isRequired,
  /** className to add to the content wrapper */
  className: PropTypes.string,
  /** (advanced) use portal to put the popover content on the body element instead of sibling of trigger */
  mountToBody: PropTypes.bool,
};

const Title = ({ children, className }) => (
  <div className={classNames(CSS.title, className)}>{ children }</div>
);
Title.propTypes = {
  /** the content to be rendered inside the title */
  children: PropTypes.node.isRequired,
  /** className to add to the title wrapper */
  className: PropTypes.string,
};

/* Append the subcomponents as properties of the main component for convenience */
Popover.Trigger = Trigger;
Popover.Content = Content;
Popover.Title = Title;
